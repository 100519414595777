import './Dashboard.scss';
import Bar from './Bar';
import Snapshot from './Snapshot';
import { useHistory } from 'react-router-dom';
import Overview from './Overview';
import { useState, useEffect, useRef } from 'react';
import Tanker from './Tanker';
import axios from 'axios';
import Company from './Company';

export default function Dashboard(props) {
    let history = useHistory();
    const [tankers, setTankers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [selected, setSelected] = useState();
    const inputSearch = useRef();
    const [search, setSearch] = useState({
        show: false,
        tankers: [],
        companies: []
    });

    //Get tankers and companies
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_DS}/Hosted/All_Vessels/MapServer/0/query?where=rules_broken+LIKE+%27%251006i%25%27+AND+vessel_type_code+BETWEEN+80+AND+89&outFields=*&f=json`)
        .then((response) => {
            console.log(response);
            const mmsiMap = new Map();

            response.data.features.filter((tanker) => tanker.attributes.imo !== null && tanker.attributes.imo > 0).forEach((tanker) => {
                if(!mmsiMap.has(tanker.attributes.mmsi)) 
                    mmsiMap.set(tanker.attributes.mmsi, tanker.attributes)
            });

            const imos = response.data.features.map((tanker) => tanker.attributes.imo).filter((imo) => imo !== null && imo > 0 && imo.toString().length === 7);
            // console.log(imos);

            axios.post(`${process.env.REACT_APP_API}/tankers/attributes`, {
                    imos: imos
                }).then((response) => {
                    if(response.data.length === 0) return;

                    response.data.forEach((tanker) => {
                        if(mmsiMap.has(parseInt(tanker.mmsi_no)))
                            mmsiMap.set(parseInt(tanker.mmsi_no), {
                                ...mmsiMap.get(parseInt(tanker.mmsi_no)),
                                ...tanker
                        })
                    });
                    setTankers(mmsiMap);
                    const tankers = Array.from(mmsiMap.values());

                    const companiesMap = new Map();

                    const owners = tankers.map((tanker) => {
                        return {
                            name: tanker.registered_owner,
                            score: Math.ceil((tanker.score/41) * 100),
                            code: tanker.registered_owner_code,
                            tanker: {name: tanker.ship_name, mmsi: tanker.mmsi_no, imo: tanker.imo, value: tanker.asset_value, relation: 'Registered Owner', score: Math.ceil((tanker.score/41) * 100)},
                            asset_value: tanker.asset_value ? tanker.asset_value : 5,
                            company_association: 'Registered Owner'
                        }
                    }).filter((owner) => typeof(owner.name) !== 'undefined');

                    const groupOwners = tankers.map((tanker) => {
                        return {
                            name: tanker.group_ben_owner,
                            score: Math.ceil((tanker.score/41) * 100),
                            code: tanker.group_ben_ownercode,
                            tanker: {name: tanker.ship_name, mmsi: tanker.mmsi_no, imo: tanker.imo, value: tanker.asset_value, relation: 'Group Owner', score: Math.ceil((tanker.score/41) * 100)},
                            asset_value: tanker.asset_value ? tanker.asset_value : 5,
                            company_association: 'Group Owner'
                        }
                    }).filter((owner) => typeof(owner.name) !== 'undefined');

                    const operators = tankers.map((tanker) => {
                        return {
                            name: tanker.operator,
                            score: Math.ceil((tanker.score/41) * 100),
                            code: tanker.operator_comp_code,
                            tanker: {name: tanker.ship_name, mmsi: tanker.mmsi_no, imo: tanker.imo, value: tanker.asset_value, relation: 'Operator', score: Math.ceil((tanker.score/41) * 100)},
                            asset_value: tanker.asset_value ? tanker.asset_value : 5,
                            company_association: 'Operator'
                        }
                    }).filter((operator) => typeof(operator.name) !== 'undefined');

                    const pandiClubs = tankers.map((tanker) => {
                        return {
                            name: tanker.pandi_club,
                            score: Math.ceil((tanker.score/41) * 100),
                            code: tanker.pandi_clubcode,
                            tanker: {name: tanker.ship_name, mmsi: tanker.mmsi_no, imo: tanker.imo, value: tanker.asset_value, relation: 'P&I Club', score: Math.ceil((tanker.score/41) * 100)},
                            asset_value: tanker.asset_value ? tanker.asset_value : 5,
                            company_association: 'P&I Club'
                        }
                    }).filter((club) => typeof(club.name) !== 'undefined');

                    const shipManagers = tankers.map((tanker) => {
                        return {
                            name: tanker.ship_manager,
                            score: Math.ceil((tanker.score/41) * 100),
                            code: tanker.ship_manager_companycode,
                            tanker: {name: tanker.ship_name, mmsi: tanker.mmsi_no, imo: tanker.imo, value: tanker.asset_value, relation: 'Fleet Manager', score: Math.ceil((tanker.score/41) * 100)},
                            asset_value: tanker.asset_value ? tanker.asset_value : 5,
                            company_association: 'Fleet Manager'
                        }
                    }).filter((manager) => typeof(manager.name) !== 'undefined');

                    const technicalManagers = tankers.map((tanker) => {
                        return {
                            name: tanker.technical_mgr,
                            score: Math.ceil((tanker.score/41) * 100),
                            code: tanker.tech_mgr_code,
                            tanker: {name: tanker.ship_name, mmsi: tanker.mmsi_no, imo: tanker.imo, value: tanker.asset_value, relation: 'Technical Manager', score: Math.ceil((tanker.score/41) * 100)},
                            asset_value: tanker.asset_value ? tanker.asset_value : 5,
                            company_association: 'Technical Manager'
                        }
                    }).filter((manager) => typeof(manager.name) !== 'undefined');

                    let companies = [...owners, ...groupOwners, ...operators, ...pandiClubs, ...shipManagers, ...technicalManagers];

                    companies.filter((company) => !company.name.toLowerCase().includes('unknown')).forEach((company) => {
                        if(!companiesMap.has(company.name)) {
                            companiesMap.set(company.name, {
                                name: company.name,
                                score: company.score,
                                code: company.code,
                                value: company.tanker.value ? company.tanker.value : 5,
                                tankers: [company.tanker],
                                associations: [company.tanker]
                            });
                        } else {
                            const tankers = [...companiesMap.get((company.name)).tankers, company.tanker];
                            let value = 0;

                            const map = new Map();
                            tankers.forEach((tanker) => {
                                if(!map.has(tanker.imo)) {
                                    map.set(tanker.imo, tanker);
                                    value += tanker.value;
                                }
                            })

                            companiesMap.set(company.name, {
                                name: company.name,
                                score: companiesMap.get(company.name).score + company.score,
                                code: company.code,
                                value: value,
                                tankers: Array.from(map.values()),
                                associations: [...companiesMap.get((company.name)).associations, company.tanker]
                            })
                        }
                    });

                    companies = Array.from(companiesMap.values()).sort((x, y) => y.score - x.score).map((company, index) => {
                                    return {
                                        id: index,
                                        name: company.name,
                                        score: company.score,
                                        code: company.code,
                                        value: company.value ? company.value : 5,
                                        tankers: company.tankers,
                                        associations: company.associations
                                    }
                                });

                    setCompanies(companies);
                })
                .catch((err) => {
                    console.log(err);
                })
        }).catch((err) => {
            console.log(err)
        });

    }, []);

    const selectedToggle = (selected) => {
        switch(selected.type) {
            case 'tanker': return <>
                    <Tanker tanker={selected.tanker} companies={companies} setSelected={setSelected}/>
                    <div className='landing-tanker-recommended'>
                        <div className='landing-tanker-recommended-title'>Recommended Tankers</div>
                        <div className='landing-tanker-recommended-list'>
                            {Array.from(tankers.values()).length > 0 &&
                                Array.from(tankers.values()).filter((current) => current.score > 24 && current.imo !== selected.tanker.imo).sort((x, y) => y.score - x.score).map((tanker, index) => (
                                    <div key={index} className='mapDash-panel-tabs-search-results-list-single' style={{borderBottom: '1px solid gray', borderRadius: '0px'}}>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left'>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-title'>
                                                <div className='mapDash-panel-tabs-search-results-list-single-left-title-name' onClick={() => {
                                                    setSelected({type: 'tanker', tanker: tanker})
                                                }}>{tanker.vessel_name.length > 15 ? `${tanker.vessel_name.substring(0, 14)}...` : tanker.vessel_name}</div>
                                                <div className='mapDash-panel-tabs-search-results-list-single-left-title-score' style={!tanker.score ? {backgroundColor: 'rgba(255, 255, 255, 0.3)', border: '1px solid white'} : tanker.score > 10 ? {backgroundColor: 'rgba(186, 0, 13, 0.3)', border: '1px solid #BA000D'} : {backgroundColor: 'rgba(236, 202, 0, 0.3)', border: '1px solid #ecca00'}}>{tanker.score ? `${Math.ceil((tanker.score/41) * 100)}%` : '?'}</div>
                                            </div>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-attributes'>
                                                <div>MMSI: {tanker.mmsi}</div>
                                                <div>IMO: {tanker.imo ? tanker.imo : 'Unknown'}</div>
                                            </div>
                                        </div>
                                        <div className='mapDash-panel-tabs-search-results-list-single-right'>
                                            <div className="landing-left-snap-tab-table-evidence" onClick={() => setSelected({type: 'tanker', tanker: tanker})}>Evidence</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </>
            case 'company': return <>
                <Company company={selected.company} tankers={tankers} setSelected={setSelected} companies={companies}/>
                <div className='landing-tanker-recommended'>
                        <div className='landing-tanker-recommended-title'>Recommended Companies</div>
                        <div className='landing-tanker-recommended-list'>
                            {
                                companies.filter((company) => !company.name.includes('Unknown') && company.name !== selected.company.name).map((company, index) => (
                                    <div key={index} className='mapDash-panel-tabs-search-results-list-single' style={{padding: '0px'}}>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left'>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-title'>
                                                <div className='mapDash-panel-tabs-search-results-list-single-left-title-name' onClick={() => {
                                                    setSelected({type: 'company', company: company})
                                                }}>{company.name.length > 21 ? `${company.name.substring(0, 20)}...` : company.name}</div>
                                                <div className='mapDash-panel-tabs-search-results-list-single-left-title-score' style={!company.score ? {backgroundColor: 'rgba(255, 255, 255, 0.3)', border: '1px solid white'} : Math.ceil(company.score/company.associations.length) > 60 ? {backgroundColor: 'rgba(186, 0, 13, 0.3)', border: '1px solid #BA000D'} : {backgroundColor: 'rgba(236, 202, 0, 0.3)', border: '1px solid #ecca00'}}>{company.score ? `${Math.ceil(company.score/company.associations.length)}%` : '?'}</div>
                                            </div>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-attributes'>
                                                <div>Tanker associations: {company.associations.length}</div>
                                            </div>
                                        </div>
                                        <div className='mapDash-panel-tabs-search-results-list-single-right' style={{justifyContent: 'flex-end', overflow: 'hidden', paddingRight: '0.6rem'}}>
                                            <div className='mapDash-panel-tabs-search-results-list-single-right-view' onClick={() => {
                                                setSelected({type: 'company', company: company})
                                            }}/>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </>
            default: return <></>
        }
    }

    const searchEntity = (e) => {
        if(!e.target.value || e.target.value === ' ') {
            setSearch({
                show: false,
                tankers: [],
                companies: []
            })
            return;
        };

        const input = e.target.value.toUpperCase();
        const vessels = Array.from(tankers.values());
        setSearch({
            show: true,
            tankers: vessels.filter((tanker) => tanker.imo.toString().includes(input) || tanker.mmsi.toString().includes(input) || tanker.vessel_name.toString().includes(input)),
            companies: companies.filter((company) => company.name.toUpperCase().includes(input))
        })
    }

    return (
        <div className='landing'>
            {search.show &&
                <div className='landing-top-search-suggestions'>
                    {search.tankers.length > 0 &&
                        <>
                            <div className='landing-top-search-suggestions-heading'>Tankers</div>
                            {search.tankers.sort((x, y) => y.score - x.score).map((tanker, index) => (
                                <div key={index} className='mapDash-panel-tabs-search-results-list-single landing-top-search-suggestions-hover' onClick={() => {
                                    setSearch({...search, show: false});
                                    inputSearch.current.value = '';
                                    setSelected({type: 'tanker', tanker: tanker});
                                }}>
                                    <div className='mapDash-panel-tabs-search-results-list-single-left'>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left-title'>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-title-name' style={{color: 'black'}}>{tanker.vessel_name && tanker.vessel_name.length > 15 ? `${tanker.vessel_name.substring(0, 14)}...` : tanker.vessel_name}</div>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-title-score' style={!tanker.score ? {backgroundColor: 'rgba(255, 255, 255, 0.7)', border: '1px solid white', color: 'black'} : tanker.score > 10 ? {backgroundColor: 'rgba(186, 0, 13, 0.7)', border: '1px solid #BA000D'} : {backgroundColor: 'rgba(236, 202, 0, 0.7)', border: '1px solid #ecca00'}}>{tanker.score ? `${Math.ceil((tanker.score/41) * 100)}%` : '?'}</div>
                                        </div>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left-attributes' style={{color: 'black'}}>
                                            <div>MMSI: {tanker.mmsi}</div>
                                            <div>IMO: {tanker.imo ? tanker.imo : 'Unknown'}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    }
                    {search.companies.length > 0 &&
                        <>
                            <div className='landing-top-search-suggestions-heading'>Companies</div>
                            {search.companies.filter((company) => !company.name.includes('Unknown')).map((company, index) => (
                                <div key={index} className='mapDash-panel-tabs-search-results-list-single landing-top-search-suggestions-hover' style={{padding: '0px'}} onClick={() => {
                                    setSearch({...search, show: false});
                                    inputSearch.current.value = '';
                                    setSelected({type: 'company', company: company});
                                }}>
                                    <div className='mapDash-panel-tabs-search-results-list-single-left'>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left-title'>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-title-name' style={{color: 'black'}}>{company.name.length > 31 ? `${company.name.substring(0, 30)}...` : company.name}</div>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-title-score' style={!company.score ? {backgroundColor: 'rgba(255, 255, 255, 0.7)', border: '1px solid white'} : Math.ceil(company.score/company.associations.length) > 60 ? {backgroundColor: 'rgba(186, 0, 13, 0.7)', border: '1px solid #BA000D'} : {backgroundColor: 'rgba(236, 202, 0, 0.7)', border: '1px solid #ecca00'}}>{company.score ? `${Math.ceil(company.score/company.associations.length)}%` : '?'}</div>
                                        </div>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left-attributes' style={{color: 'black'}}>
                                            <div>Tanker associations: {company.tankers.length}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    }
                    {(search.tankers.length === 0 && search.companies.length === 0) &&
                        <div className='landing-top-search-suggestions-empty'>No results found</div>
                    }
                </div>
            }
            {selected &&
                <div className='landing-tanker'>
                    <div className='landing-tanker-close' onClick={() => setSelected()}/>
                    {selectedToggle(selected)}
                </div>
            }
            <div className='landing-top'>
                <div className='landing-top-title'>Black Sea</div>
                <div className='landing-top-search'>
                    <input ref={inputSearch} className='landing-top-search-field' placeholder='Search by entity MMSI/IMO/Name' onChange={searchEntity}/>
                </div>
                <div className='landing-top-button' onClick={() => history.push('/insight')} style={{minWidth: '191px', textAlign: 'center'}}>Case Studies</div>
            </div>
            <div className='landing-body'>
                <div className='landing-left'>
                    <Snapshot tankers={tankers} setSelected={setSelected}/>
                    <div className='landing-left-values'><Bar tankers={tankers} setSelected={setSelected} companies={companies}/></div>
                </div>
                <div className='landing-right'>
                    <div className='landing-right-map'>
                        <div className='landing-top-button' onClick={() => history.push('/map')}>View Live Map</div>
                    </div>
                    <Overview companies={companies} setSelected={setSelected}/>
                </div>
            </div>
        </div>
    );
}
 