export const marks = [
    {
        value: 0,
        label: 'May 15, 2022'
    },
    {
        value: 10,
        label: 'May 16, 2022'
    },
    {
        value: 20,
        label: 'May 17, 2022'
    },
    {
        value: 30,
        label: 'May 18, 2022'
    },
    {
        value: 40,
        label: 'May 19, 2022'
    },
    {
        value: 50,
        label: 'May 20, 2022'
    },
    {
        value: 60,
        label: 'May 21, 2022'
    },
    {
        value: 70,
        label: 'May 22, 2022'
    },
];

export const images = [
    {
        url: 'https://hosting.seadserver.com/arcgis/rest/services/Sensors/Black_Sea_20220515/ImageServer',
        date: new Date(2022, 5, 15),
        value: 0,
        center: [37.75, 44.5],
        zoom: 10
    },
    {
        url: 'https://hosting.seadserver.com/arcgis/rest/services/Sensors/Black_Sea_20220516/ImageServer',
        date: new Date(2022, 5, 16),
        value: 10,
        center: [36.5, 45.15],
        zoom: 10
    },
    {
        url: 'https://hosting.seadserver.com/arcgis/rest/services/Sensors/Black_Sea_20220517/ImageServer',
        date: new Date(2022, 5, 17),
        value: 20,
        center: [37.85, 44.65],
        zoom: 11
    },
    {
        url: 'https://hosting.seadserver.com/arcgis/rest/services/Sensors/Black_Sea_20220518/ImageServer',
        date: new Date(2022, 5, 18),
        value: 30,
        center: [36.55, 45.15],
        zoom: 11
    },
    {
        url: 'https://hosting.seadserver.com/arcgis/rest/services/Sensors/Black_Sea_20220519/ImageServer',
        date: new Date(2022, 5, 19),
        value: 40,
        center: [37.8, 44.55],
        zoom: 11
    },
    {
        url: 'https://hosting.seadserver.com/arcgis/rest/services/Sensors/Black_Sea_20220520/ImageServer',
        date: new Date(2022, 5, 20),
        value: 50,
        center: [36.55, 45.15],
        zoom: 11
    },
    {
        url: 'https://hosting.seadserver.com/arcgis/rest/services/Sensors/Black_Sea_20220521/ImageServer',
        date: new Date(2022, 5, 21),
        value: 60,
        center: [37.8, 44.55],
        zoom: 11
    },
    {
        url: 'https://hosting.seadserver.com/arcgis/rest/services/Sensors/Black_Sea_20220522/ImageServer',
        date: new Date(2022, 5, 22),
        value: 70,
        center: [36.55, 45.15],
        zoom: 11
    }
]