export default function Ownership({ tanker, setSelected, companies }) {

    const selectCompany = (e) => {
        console.log(companies);
        console.log(e.target.innerText);
        if(!e.target.innerText.includes("Unknown")) {
            if(companies.find((company) => company.name.includes(e.target.innerText)))
                setSelected({type: 'company', company: companies.find((company) => company.name.includes(e.target.innerText))});
        }
    }

    return (
        <div className='tanker-ownership'>
            <div className='tanker-ownership-header'>Ownership Tree</div>
            <div className='tanker-ownership-tree'>
                <div className="tanker-ownership-tree-tree">
                    <div className="tanker-ownership-tree-tree-row">
                        <div className="tanker-ownership-tree-tree-row-single">
                            <div className="tanker-ownership-tree-tree-row-name">Group Owner</div>
                            <div className="tanker-ownership-tree-tree-row-value" style={tanker.attributes.group_ben_owner === '  Unknown' || tanker.attributes.group_ben_owner === 'Unknown' || !tanker.attributes.group_ben_owner ? {color: '#d65151'} : {}} onClick={selectCompany}>
                                {tanker.attributes.group_ben_owner ? tanker.attributes.group_ben_owner : 'Unknown'}
                            </div>
                            <div className="tanker-ownership-tree-tree-row-next"/>
                        </div>
                    </div>
                    <div className="tanker-ownership-tree-tree-line"/>
                    <div className="tanker-ownership-tree-tree-row">
                        <div className="tanker-ownership-tree-tree-row-single">
                            <div className="tanker-ownership-tree-tree-row-next"/>
                            <div className="tanker-ownership-tree-tree-row-name">Registered Owner</div>
                            <div className="tanker-ownership-tree-tree-row-value" style={tanker.attributes.registered_owner === '  Unknown' || tanker.attributes.registered_owner === 'Unknown' || !tanker.attributes.registered_owner ? {color: '#d65151'} : {}} onClick={selectCompany}>{tanker.attributes.registered_owner ? tanker.attributes.registered_owner : 'Unknown'}</div>
                            <div className="tanker-ownership-tree-tree-row-next"/>
                        </div>
                        <div className="tanker-ownership-tree-tree-row-single">
                            <div className="tanker-ownership-tree-tree-row-next"/>
                            <div className="tanker-ownership-tree-tree-row-name">Ship Manager</div>
                            <div className="tanker-ownership-tree-tree-row-value" style={tanker.attributes.ship_manager === '  Unknown' || tanker.attributes.ship_manager === 'Unknown' || !tanker.attributes.ship_manager ? {color: '#d65151'} : {}} onClick={selectCompany}>{tanker.attributes.ship_manager ? tanker.attributes.ship_manager : 'Unknown'}</div>
                        </div>
                        <div className="tanker-ownership-tree-tree-row-single">
                            <div className="tanker-ownership-tree-tree-row-next"/>
                            <div className="tanker-ownership-tree-tree-row-name">Charterer</div>
                            <div className="tanker-ownership-tree-tree-row-value" style={tanker.attributes.charterer === '  Unknown' || tanker.attributes.charterer === 'Unknown' || !tanker.attributes.charterer ? {color: '#d65151'} : {}} onClick={selectCompany}>{tanker.attributes.charterer ? tanker.attributes.charterer : 'Unknown'}</div>
                        </div>
                    </div>
                    <div className="tanker-ownership-tree-tree-row">
                        <div className="tanker-ownership-tree-tree-row-single" style={{flexDirection: 'row', alignItems: 'flex-start'}}>
                        </div>
                        <div className="tanker-ownership-tree-tree-row-single">
                            <div className="tanker-ownership-tree-tree-row-next"/>
                        </div>
                        <div className="tanker-ownership-tree-tree-row-single"/>
                    </div>
                    <div className="tanker-ownership-tree-tree-row">
                        <div className="tanker-ownership-tree-tree-row-double" style={{marginLeft: '-1rem'}}>
                            <div className="tanker-ownership-tree-tree-row-double-left">
                                <div className="tanker-ownership-tree-tree-row-single" >
                                    <div className="tanker-ownership-tree-tree-row-name">P&I Club</div>
                                    <div className="tanker-ownership-tree-tree-row-value" style={tanker.attributes.pandi_club === '  Unknown' || tanker.attributes.pandi_club === 'Unknown' || !tanker.attributes.pandi_club ? {color: '#d65151'} : {}} onClick={selectCompany}>{tanker.attributes.pandi_club ? tanker.attributes.pandi_club : 'Unknown'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="tanker-ownership-tree-tree-row-single" style={{minWidth: 'auto'}}>
                            <div className="tanker-ownership-tree-tree-row-name">Registered Operator</div>
                            <div className="tanker-ownership-tree-tree-row-value" style={tanker.attributes.operator === '  Unknown' || tanker.attributes.operator === 'Unknown' || !tanker.attributes.operator ? {color: '#d65151'} : {}} onClick={selectCompany}>{tanker.attributes.operator ? tanker.attributes.operator : 'Unknown'}</div>
                        </div>
                        <div className="tanker-ownership-tree-tree-row-single"></div>
                    </div>
                    <div className="tanker-ownership-tree-tree-row">
                        <div className="tanker-ownership-tree-tree-row-single">
                            <div className="tanker-ownership-tree-tree-row-next"/>
                        </div>
                    </div>
                    <div className="tanker-ownership-tree-tree-row">
                        <div className="tanker-ownership-tree-tree-row-single" style={{minWidth: 'auto'}}>
                            <div className="tanker-ownership-tree-tree-row-name">Technical Manager</div>
                            <div className="tanker-ownership-tree-tree-row-value" style={tanker.attributes.technical_mgr === '  Unknown' || tanker.attributes.technical_mgr === 'Unknown' || !tanker.attributes.technical_mgr ? {color: '#d65151'} : {}} onClick={selectCompany}>{tanker.attributes.technical_mgr ? tanker.attributes.technical_mgr : 'Unknown'}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 