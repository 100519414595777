import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import axios from "axios";
import { Box } from "@mui/system";
import { LinearProgress } from "@mui/material";

export default function STS({ tanker }) {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const date = new Date();
        date.setDate(date.getDate() - 90);
        const today = new Date();
        const range = [`${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()} ${date.toString().substring(16, 24)}`, 
        `${today.getUTCFullYear()}-${today.getUTCMonth() + 1}-${today.getUTCDate()} ${today.toString().substring(16, 24)}`];

        axios.post(`${process.env.REACT_APP_SS_API}/products/rendezvous`, {
            timestamp_utc_start: range[0],
            timestamp_utc_end: range[1],
            mmsi: tanker.attributes.mmsi
        }).then((response) => {
            setRows(response.data.results.map((point, index) => {
                return {
                    id: index,
                    other: point.other_vessel_name,
                    other_mmsi: point.other_mmsi,
                    lat: point.latitude,
                    long: point.longitude,
                    timestamp: point.timestamp_utc
                }
            }))

            setLoading(false);
        }).catch((err) => console.log(err));

    }, []);

    //Loading Bar
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 95) {
                    return 95;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 95);
            });
            }, 500);
        
            return () => {
                clearInterval(timer);
            };
    }, []);

    return (
        <div className='tanker-sts'>
            <div className='tanker-sts-header'>Ship to Ship transfers</div>
            {loading ?
                <div className='map-loading-container' style={loading ? {display: 'flex'} : {display: 'none'}}>
                    <div className='map-loading-container-logo'/>
                    <Box sx={{ width: '150px' }}>
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
                </div>
                :
                <div className='tanker-sts-table'>
                <DataGrid
                    rows={rows}
                    rowSelection={'single'}
                    columns={[
                        { field: 'other', headerName: 'Other Tanker', flex: 1, renderCell: (params) => (
                            <div style={params.value === 'Unknown' ? {color: '#d65151'} : {}}>
                                {params.value}
                            </div>
                        )},
                        { field: 'other_mmsi', headerName:  'Other MMSI', flex: 1, renderCell: (params) => (
                            <div style={params.value === 'Unknown' ? {color: '#d65151'} : {}}>
                                {params.value}
                            </div>
                        )},
                        { field: 'lat', headerName: 'Latitude', flex: 1, renderCell: (params) => (
                            <div style={params.value === 'Unknown' ? {color: '#d65151'} : {}}>
                                {params.value.toFixed(4)}
                            </div>
                        )},
                        { field: 'long', headerName: 'Longitude', flex: 1, renderCell: (params) => {
                            <div style={params.value === 'Unknown' ? {color: '#d65151'} : {}}>
                                {params.value.toFixed(4)}
                            </div>
                        }},
                        { field: 'timestamp', headerName: 'Timestamp', flex: 1, renderCell: (params) => {
                            <div style={params.value === 'Unknown' ? {color: '#d65151'} : {}}>
                                {params.value}
                            </div>                            
                        }}
                    ]}
                    rowsPerPageOptions={[10, 50, 100]}
                    sx={{
                        boxShadow: 'none',
                        fontFamily: "Avenir Next Regular",
                        border: 'none',
                        color: 'white',
                        backgroundColor: '#111723',
                        borderColor: 'white',
                        '& .MuiDataGrid-columnHeaderCheckbox' : {
                            display: 'none'
                        },
                        '& .MuiCheckbox-root' : {
                            color: 'white',
                            filter: 'invert(100%)'
                        },
                        '& .MuiDataGrid-overlay' : {
                            backgroundColor: '#111723'
                        },
                        '& .MuiDataGrid-columnHeaders' : {
                            backgroundColor: '#1f2a3f',
                            borderRadius: '0rem'
                        },
                        '& .MuiDataGrid-footerContainer' : {
                            backgroundColor: '#1f2a3f'
                        },
                        '& .MuiTablePagination-displayedRows' : {
                            color: 'white'
                        },
                        '& .MuiTablePagination-root' : {
                            color: 'white'
                        },
                        '& .MuiSvgIcon-root' : {
                            filter: 'invert(100%)'
                        },
                        '& .Mui-disabled' : {
                            filter: 'invert(100%)'
                        },
                        '& .MuiTablePagination-actions' : {
                            filter: 'invert(100%)'
                        },
                        '& .MuiDataGrid-columnSeparator': {
                            filter: 'invert(100%)'
                        },
                        '& .MuiDataGrid-row': {
                            backgroundColor: '#111723'
                        },
                        '& .MuiDataGrid-row:hover': {
                            color: 'white',
                            backgroundColor: '#27272f'
                        },
                        '& .MuiDataGrid-selectedRowCount': {
                            color: '#283040',
                            width: '50px'
                        },
                        '& .MuiTablePagination-selectLabel': {
                            paddingTop: '15px',
                        },
                        '& .MuiTablePagination-displayedRows': {
                            paddingTop: '15px',
                        }
                    }}
                />
            </div>
            }
        </div>
    );
}
 