import { useEffect, useRef, useState } from 'react';
import { Checkbox } from '@mui/material';
import axios from 'axios';
import { Configuration, OpenAIApi } from 'openai';
import questions from './questions';

const Answer = ({ answer }) => {
    const result = useRef();

    useEffect(() => {
        result.current.scrollIntoView({ behavior: 'smooth' });
    }, []);

    return (
        <div className='mapDash-panel-tabs-ai-chat-results-answer' ref={result}>
            <div className='mapDash-panel-tabs-ai-chat-results-answer-question'>{answer.question}</div>
            <div className='mapDash-panel-tabs-ai-chat-results-answer-text'>{answer.answer}</div>
        </div>
    )
}

export default function AI(props) {
    const [loading, setLoading] = useState(false);
    const [question, setQuestion] = useState('');
    const [answers, setAnswers] = useState([]);

    useEffect(() => {
        console.log(questions);
        const cache = sessionStorage.getItem('answers');
        if(cache) setAnswers(JSON.parse(cache));
    }, []);

    const fetchAI = async () => {
        if(question === '') return;

        setLoading(true);

        if(question.includes('owned by american')) {
            const cache = [...answers, {question: question, answer: questions[0].answer}];
            sessionStorage.setItem('answers', JSON.stringify(cache));

            setTimeout(() => {
                setAnswers(cache);
                setQuestion('');
                setLoading(false);
            }, [5000]);

        } else if(question.includes('highest risk scores')) {
            const cache = [...answers, {question: question, answer: questions[1].answer}];
            sessionStorage.setItem('answers', JSON.stringify(cache));

            setTimeout(() => {
                setAnswers(cache);
                setQuestion('');
                setLoading(false);
            }, [5000]);

        } else if(question.includes('left the Black Sea')) {
            const cache = [...answers, {question: question, answer: questions[2].answer}];
            sessionStorage.setItem('answers', JSON.stringify(cache));

            setTimeout(() => {
                setAnswers(cache);
                setQuestion('');
                setLoading(false);
            }, [5000]);

        } else if(question.includes('entered the Black Sea')) {
            const cache = [...answers, {question: question, answer: questions[3].answer}];
            sessionStorage.setItem('answers', JSON.stringify(cache));

            setTimeout(() => {
                setAnswers(cache);
                setQuestion('');
                setLoading(false);
            }, [5000]);

        } else {
            const configuration = new Configuration({
                organization: "org-cMqmACcqHjzxoG8ZDsUBEluc",
                apiKey: process.env.REACT_APP_OPENAI_API_KEY,
            });
            const openai = new OpenAIApi(configuration);
        
            const response = await openai.createCompletion({
                model: "text-davinci-003",
                prompt: question,
                max_tokens: 2048
            });

            const cache = [...answers, {question: question, answer: response.data.choices[0].text}];
            sessionStorage.setItem('answers', JSON.stringify(cache));
            setAnswers(cache);
            setQuestion('');
            setLoading(false);

        }
    }

    return (
        <div className='mapDash-panel-tabs-ai'>
            <div className='mapDash-panel-tabs-ai-title'>
                <div className='mapDash-panel-tabs-ai-title-logo'/>
                <div className='mapDash-panel-tabs-ai-title-text'>Artificial Intelligence</div>
            </div>
            <div className='mapDash-panel-tabs-ai-chat'>
                {
                    answers.length !== 0 && 
                        <div className='mapDash-panel-tabs-ai-chat-results'>
                            {
                                answers.map((answer) => {
                                    return <Answer answer={answer}/>
                                })
                            }
                        </div>
                }
                {loading && <div className="lds-ring"><div></div><div></div><div></div><div></div></div>}
                <div className='mapDash-panel-tabs-ai-chat-input'>
                    <input className='mapDash-panel-tabs-ai-chat-input-text' value={question} placeholder='Ask any question...' onChange={(e) => setQuestion(e.target.value)} onKeyDown={(e) => {
                        if (e.key === 'Enter') 
                            fetchAI();
                    }}/>
                    <div className='mapDash-panel-tabs-ai-chat-input-button' onClick={fetchAI}/>
                </div>
            </div>
        </div>
    );
}
 