import './Insight.scss';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { westbound } from './csv/blackSeaWestbound';
import { eastbound } from './csv/blackSeaEastBound';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
 
export default function InsightContent({ tanker, setExpand, goToTanker, showTrackHistory }) {
    const [rows, setRows] = useState([]);
    const [table, setTable] = useState(0);

    useEffect(() => {
        switch(table) {
            case 0: 
                setRows(
                    westbound.map((tanker, index) => {
                        return {
                            id: index,
                            mmsi: tanker.mmsi,
                            imo: tanker.imo,
                            name: tanker.vessel_name,
                            type: tanker.vessel_type,
                            flag: tanker.vessel_flag
                        }
                    })
                );
                break;
            case 1:
                setRows(
                    eastbound.map((tanker, index) => {
                        return {
                            id: index,
                            mmsi: tanker.mmsi,
                            imo: tanker.imo,
                            name: tanker.vessel_name,
                            type: tanker.vessel_type,
                            flag: tanker.vessel_flag
                        }
                    })
                );
                break;
            default: break;
        }
    }, [table])

    return (
        <div className='insight'>
            <div className='insight-tables'>
                <div className='insight-tables-select'>
                    <FormControl fullWidth>
                        <Select
                        value={table}
                        onChange={(e) => setTable(e.target.value)}
                        sx={{backgroundColor: 'white'}}
                        >
                        <MenuItem value={0}>Tankers exiting the Dardanelles Straits</MenuItem>
                        <MenuItem value={1}>Tankers exiting the Bosphorus straits</MenuItem>
                        <MenuItem value={2}>Repeat operators (frequent flyers) in the Black Sea</MenuItem>
                        <MenuItem value={3}>Tankers in the Black Sea with significant AIS Gaps more than 8 hours</MenuItem>
                        <MenuItem value={4}>Tankers in the Black sea identified with STS operations</MenuItem>
                        <MenuItem value={5}>Tankers with high Smart Scores, greater than 40% on the risk index</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className='insight-tables-table'>
                    <DataGrid
                        rows={rows}
                        rowSelection={'single'}
                        columns={[
                            { field: 'mmsi', headerName: 'MMSI', width: 100, renderCell: (params) => (
                                <div style={params.value === 'Unknown' ? {color: '#d65151'} : {}}>
                                    {params.value}
                                </div>
                            )},
                            { field: 'imo', headerName:  'IMO', width: 100, renderCell: (params) => (
                                <div style={params.value === 'Unknown' ? {color: '#d65151'} : {}}>
                                    {params.value}
                                </div>
                            )},
                            { field: 'name', headerName: 'Name', width: 200, renderCell: (params) => (
                                <div style={params.value === 'Unknown' ? {color: '#d65151'} : {}}>
                                    {params.value}
                                </div>
                            )},
                            { field: 'type', headerName: 'Type', width: 350, renderCell: (params) => {
                                <div style={params.value === 'Unknown' ? {color: '#d65151'} : {}}>
                                    {params.value}
                                </div>
                            }},
                            { field: 'flag', headerName: 'Flag', flex: 1, renderCell: (params) => {
                                <div style={params.value === 'Unknown' ? {color: '#d65151'} : {}}>
                                    {params.value}
                                </div>
                            }}
                        ]}
                        rowsPerPageOptions={[10, 50, 100]}
                        sx={{
                            boxShadow: 'none',
                            fontFamily: "Avenir Next Regular",
                            border: 'none',
                            color: 'white',
                            backgroundColor: '#111723',
                            borderColor: 'white',
                            '& .MuiDataGrid-columnHeaderCheckbox' : {
                                display: 'none'
                            },
                            '& .MuiCheckbox-root' : {
                                color: 'white',
                                filter: 'invert(100%)'
                            },
                            '& .MuiDataGrid-overlay' : {
                                backgroundColor: '#111723'
                            },
                            '& .MuiDataGrid-columnHeaders' : {
                                backgroundColor: '#1f2a3f',
                                borderRadius: '0rem'
                            },
                            '& .MuiDataGrid-footerContainer' : {
                                backgroundColor: '#1f2a3f'
                            },
                            '& .MuiTablePagination-displayedRows' : {
                                color: 'white'
                            },
                            '& .MuiTablePagination-root' : {
                                color: 'white'
                            },
                            '& .MuiSvgIcon-root' : {
                                filter: 'invert(100%)'
                            },
                            '& .Mui-disabled' : {
                                filter: 'invert(100%)'
                            },
                            '& .MuiTablePagination-actions' : {
                                filter: 'invert(100%)'
                            },
                            '& .MuiDataGrid-columnSeparator': {
                                filter: 'invert(100%)'
                            },
                            '& .MuiDataGrid-row': {
                                backgroundColor: '#111723'
                            },
                            '& .MuiDataGrid-row:hover': {
                                color: 'white',
                                backgroundColor: '#27272f'
                            },
                            '& .MuiDataGrid-selectedRowCount': {
                                color: '#283040',
                                width: '50px'
                            },
                            '& .MuiTablePagination-selectLabel': {
                                paddingTop: '15px',
                            },
                            '& .MuiTablePagination-displayedRows': {
                                paddingTop: '15px',
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
 