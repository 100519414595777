 export default function General({ tanker, setExpand, goToTanker, showTrackHistory }) {
    return (
        <div className='tanker-general'>
            <div className='tanker-general-column'>
                <div className='tanker-general-section'>
                    <div className='tanker-general-section-title'>
                        Track History 
                        <div className='tanker-general-section-title-button' onClick={() => {
                            goToTanker(tanker.attributes.mmsi);
                            setExpand(false);
                            const date = new Date();
                            date.setDate(date.getDate() - 7);
                            showTrackHistory([date.getTime(), new Date().getTime()]);
                        }}>Show on map</div>
                    </div>
                    <div className='tanker-general-section-track'>
                        <div className='tanker-general-section-list' style={{marginBottom: '0rem'}}>
                        <div className='tanker-general-section-list-row'>
                            <div className='tanker-general-section-list-row-field'>Last seen</div>
                            <div className='tanker-general-section-list-row-value'>{new Date(tanker.attributes.timestamp).toString().substring(4, 24)}</div>
                        </div>
                    </div>
                    </div> 
                </div>
                <div className='tanker-general-section' style={{marginBottom: '0rem'}}>
                    <div className='tanker-general-section-title'>Current Trip Details</div>
                    <div className='tanker-general-section-list'>
                        <div className='tanker-general-section-list-row'>
                            <div className='tanker-general-section-list-row-field'>Destination</div>
                            <div className='tanker-general-section-list-row-value'>{tanker.attributes.destination ? tanker.attributes.destination : 'Unknown'}</div>
                        </div>
                    </div>
                    <div className='tanker-general-section-list'>
                        <div className='tanker-general-section-list-row'>
                            <div className='tanker-general-section-list-row-field'>Navigation Status</div>
                            <div className='tanker-general-section-list-row-value'>{tanker.attributes.navigation_status ? tanker.attributes.navigation_status : 'Unknown'}</div>
                        </div>
                    </div>
                    <div className='tanker-general-section-list' style={{marginBottom: '0rem'}}>
                        <div className='tanker-general-section-list-row'>
                            <div className='tanker-general-section-list-row-field'>Speed</div>
                            <div className='tanker-general-section-list-row-value'>{(tanker.attributes.speed_over_ground || tanker.attributes.speed_over_ground === 0) ? tanker.attributes.speed_over_ground : 'Unknown'}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tanker-general-rules'>
                <div className='tanker-general-rules-title'>Rules Broken</div>
                <div className='tanker-general-rules-list' style={{marginBottom: '-1rem'}}>
                    {typeof(tanker.attributes.rules_broken) !== 'string' ?
                        tanker.attributes.rules_broken.map((rule, index) => (
                            <div key={index} className='tanker-general-rules-list-single'>
                                <div className='tanker-general-rules-list-single-score' title='Rule weight' style={rule.rule_score > 10 ? {backgroundColor: 'rgba(186, 0, 13, 0.3)', border: '1px solid #BA000D'} : {backgroundColor: 'rgba(236, 202, 0, 0.3)', border: '1px solid #ecca00'}}>{Math.round((rule.rule_score/41) * 100)}%</div>
                                <div className='tanker-general-rules-list-single-info'>
                                    <div className='tanker-general-rules-list-single-info-rule'>{rule.rule_title}</div>
                                </div>
                            </div>
                        ))
                        :
                        <div className='tanker-general-rules-list-empty'>No rules broken found</div>
                    }
                </div>
            </div>
        </div>
    );
}
 