import { useHistory } from "react-router-dom";

 export default function General({ tanker }) {
    let history = useHistory();

    return (
        <div className='tanker-general'>
            <div className='tanker-general-column'>
                <div className='tanker-general-section' style={{ backgroundColor: '#111723' }}>
                    <div className='tanker-general-section-title'>
                        Track History 
                        <div className='tanker-general-section-title-button' onClick={() => history.push(`/map/${tanker.mmsi}`)}>View live map</div>
                    </div>
                    <div className='tanker-general-section-track'>
                        <div className='tanker-general-section-list' style={{marginBottom: '0rem'}}>
                        <div className='tanker-general-section-list-row'>
                            <div className='tanker-general-section-list-row-field'>Last seen</div>
                            <div className='tanker-general-section-list-row-value'>{new Date(tanker.timestamp).toString().substring(4, 24)}</div>
                        </div>
                    </div>
                    </div> 
                </div>
                <div className='tanker-general-section' style={{marginBottom: '0rem', backgroundColor: '#111723' }}>
                    <div className='tanker-general-section-title'>Current Trip Details</div>
                    <div className='tanker-general-section-list'>
                        <div className='tanker-general-section-list-row'>
                            <div className='tanker-general-section-list-row-field'>Destination</div>
                            <div className='tanker-general-section-list-row-value'>{tanker.destination ? tanker.destination : 'Unknown'}</div>
                        </div>
                    </div>
                    <div className='tanker-general-section-list'>
                        <div className='tanker-general-section-list-row'>
                            <div className='tanker-general-section-list-row-field'>Navigation Status</div>
                            <div className='tanker-general-section-list-row-value'>{tanker.navigation_status ? tanker.navigation_status : 'Unknown'}</div>
                        </div>
                    </div>
                    <div className='tanker-general-section-list' style={{marginBottom: '0rem', backgroundColor: '#111723' }}>
                        <div className='tanker-general-section-list-row'>
                            <div className='tanker-general-section-list-row-field'>Speed</div>
                            <div className='tanker-general-section-list-row-value'>{(tanker.speed_over_ground || tanker.speed_over_ground === 0) ? tanker.speed_over_ground : 'Unknown'}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tanker-general-rules' style={{ backgroundColor: '#111723' }}>
                <div className='tanker-general-rules-title'>Rules Broken</div>
                <div className='tanker-general-rules-list' style={{marginBottom: '-1rem'}}>
                    {typeof(tanker.rules_broken) !== 'string' ?
                        tanker.rules_broken.map((rule, index) => (
                            <div key={index} className='tanker-general-rules-list-single'>
                                <div className='tanker-general-rules-list-single-score' title='Rule weight' style={rule.rule_score > 10 ? {backgroundColor: 'rgba(186, 0, 13, 0.3)', border: '1px solid #BA000D'} : {backgroundColor: 'rgba(236, 202, 0, 0.3)', border: '1px solid #ecca00'}}>{Math.round((rule.rule_score/41) * 100)}%</div>
                                <div className='tanker-general-rules-list-single-info'>
                                    <div className='tanker-general-rules-list-single-info-rule'>{rule.rule_title}</div>
                                </div>
                            </div>
                        ))
                        :
                        <div className='tanker-general-rules-list-empty'>No rules broken found</div>
                    }
                </div>
            </div>
        </div>
    );
}
 