import React, { useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Link } from 'react-router-dom';
import Pool from '../../UserPool';

export default function ForgotPassword() {

    const [stage, setStage] = useState(1); //1 = first form, 2 = verification code, 3 = success screen
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');

    const getUser = () => {
        return new CognitoUser({
            Username: email.toLowerCase(),
            Pool
        });
    };

    const sendCode = () => {
        getUser().forgotPassword({
            onSuccess: data => {
                console.log('onSuccess:', data);
            },
            onFailure: err => {
                console.log('onFailure:', err);
            },
            inputVerificationCode: data => {
                console.log('Input Code:', data);
                setStage(2);
            }
        });
    };

    const resetPassword = () => {
        if(password !== confirmedPassword) {
            document.getElementById('error-label').innerHTML = 'Passwords do not match';
            return;
        }
        
        getUser().confirmPassword(code, password, {
            onSuccess: data => {
                document.getElementById('error-label').innerHTML = '';
                console.log('onSuccess:', data);
                setStage(3);
            },
            onFailure: err => {
                document.getElementById('error-label').innerHTML = 'Error sending code';
                console.error('onFailure:', err);
            }
        });
    };

    return (
        <div className='page-container'>
            <div className='inputs' style={{height: 'fit-content', padding: '1rem 0rem'}}>
                <div style={{color: 'white', fontSize: '1.5rem', marginBottom: '1rem'}}>Reset Password</div>
                <label id='error-label' className='error-label'></label>
                {stage === 1 && 
                    <>
                        <input className='input' id='email' onChange={(e)=> setEmail(e.target.value)} label='email' required placeholder='Email'/>
                        <button className='button' variant='contained' onClick={()=>sendCode()}>Send verification code</button>
                    </>
                }
                {stage === 2 && 
                    <>
                        <input className='input' id='code' onChange={(e)=> setCode(e.target.value)} label='code' required placeholder='Verification Code'/>
                        <input className='input' type='password' id='password' onChange={(e)=> setPassword(e.target.value)} label='password' required placeholder='New Password'/>
                        <input className='input' type='password' id='confirmedPassword' onChange={(e)=> setConfirmedPassword(e.target.value)} label='confirmedPassword' required placeholder='Confirm New Password'/>
                        <button className='button' variant='contained' onClick={()=>resetPassword()}>Reset password</button>
                    </>
                }
                {stage === 3 && <h2 className='message-text'>Password Reset!</h2>}
                <div className='inputs-text'>Return to <Link to='/'>Login</Link></div>
            </div>
        </div>
    )


}