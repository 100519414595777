export const eastbound = [
    {
      "mmsi": 273357860,
      "imo": 9645009,
      "timestamp": "5/11/2022 23:45",
      "vessel_name": "VF TANKER-11",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 273333670,
      "imo": 9645114,
      "timestamp": "5/14/2022 11:37",
      "vessel_name": "VF TANKER-22",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 66,
      "vessel_type": "84 - Tanker, Hazardous category D",
      "vessel_type_code": 84
    },
    {
      "mmsi": 273350660,
      "imo": 9640554,
      "timestamp": "6/21/2022 14:01",
      "vessel_name": "VF TANKER-7",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 66,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 273385070,
      "imo": 9749130,
      "timestamp": "7/24/2022 15:15",
      "vessel_name": "LADA",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 66,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 273344230,
      "imo": 9133393,
      "timestamp": "8/29/2022 7:45",
      "vessel_name": "UNICOM ALPHA",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 273318520,
      "imo": 9354636,
      "timestamp": "5/14/2022 19:34",
      "vessel_name": "FLURA",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 273393650,
      "imo": 9829071,
      "timestamp": "5/14/2022 22:37",
      "vessel_name": "BALT FLOT 17",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 67,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 273351430,
      "imo": 9630975,
      "timestamp": "5/16/2022 4:33",
      "vessel_name": "MEKHANIK KHARITONOV",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 273454720,
      "imo": 8883252,
      "timestamp": "5/20/2022 22:48",
      "vessel_name": "KOYSUG",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 273382620,
      "imo": 9784893,
      "timestamp": "5/23/2022 22:01",
      "vessel_name": "LADY RANIA",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 67,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 273353180,
      "imo": 9645047,
      "timestamp": "5/29/2022 13:23",
      "vessel_name": "VF TANKER-15",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 273355340,
      "imo": 9286463,
      "timestamp": "7/8/2022 4:27",
      "vessel_name": "GAZPROMNEFTZUID-WEST",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 67,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 273358390,
      "imo": 9645097,
      "timestamp": "5/13/2022 23:20",
      "vessel_name": "VF TANKER-20",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 68,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 273392370,
      "imo": 9804215,
      "timestamp": "6/7/2022 15:42",
      "vessel_name": "BALT FLOT 14",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 68,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 273437420,
      "imo": 8862935,
      "timestamp": "8/1/2022 11:52",
      "vessel_name": "ROSCHEM-2",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 72,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 423488100,
      "imo": 9821469,
      "timestamp": "5/15/2022 14:24",
      "vessel_name": "LACHIN",
      "vessel_flag": "AZERBAIJAN",
      "course_over_ground": 68,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 311000232,
      "imo": 9723007,
      "timestamp": "5/18/2022 1:25",
      "vessel_name": "ALDEBARAN",
      "vessel_flag": "BAHAMAS",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 311000903,
      "imo": 9878905,
      "timestamp": "7/25/2022 14:24",
      "vessel_name": "ARTEMIS VOYAGER",
      "vessel_flag": "BAHAMAS",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 311000222,
      "imo": 9422005,
      "timestamp": "8/22/2022 10:56",
      "vessel_name": "EMERALD SPIRIT",
      "vessel_flag": "BAHAMAS",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 309401000,
      "imo": 9297890,
      "timestamp": "8/28/2022 12:14",
      "vessel_name": "SEACROSS",
      "vessel_flag": "BAHAMAS",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 311041700,
      "imo": 9263928,
      "timestamp": "8/28/2022 15:48",
      "vessel_name": "TRANS ADRIATIC",
      "vessel_flag": "BAHAMAS",
      "course_over_ground": 66,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 311000576,
      "imo": 9808326,
      "timestamp": "5/21/2022 21:18",
      "vessel_name": "DIADEMA",
      "vessel_flag": "BAHAMAS",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 309832000,
      "imo": 9335123,
      "timestamp": "7/14/2022 18:58",
      "vessel_name": "MILOU",
      "vessel_flag": "BAHAMAS",
      "course_over_ground": 68,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 319084600,
      "imo": 9761451,
      "timestamp": "8/10/2022 19:36",
      "vessel_name": "MEDALTA ADVENTURER",
      "vessel_flag": "CAYMAN ISLANDS",
      "course_over_ground": 66,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 319150000,
      "imo": 9594054,
      "timestamp": "6/13/2022 21:12",
      "vessel_name": "ORIENTAL LOTUS",
      "vessel_flag": "CAYMAN ISLANDS",
      "course_over_ground": 67,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 212097000,
      "imo": 9384435,
      "timestamp": "6/13/2022 1:10",
      "vessel_name": "RN ARKHANGELSK",
      "vessel_flag": "CYPRUS",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 209745000,
      "imo": 9118159,
      "timestamp": "6/2/2022 15:47",
      "vessel_name": "LEFTERIS",
      "vessel_flag": "CYPRUS",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 219314000,
      "imo": 9682980,
      "timestamp": "8/19/2022 16:46",
      "vessel_name": "ELISABETH MAERSK",
      "vessel_flag": "DENMARK",
      "course_over_ground": 42,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 219124000,
      "imo": 9299434,
      "timestamp": "8/5/2022 23:15",
      "vessel_name": "MAERSK BRISTOL",
      "vessel_flag": "DENMARK",
      "course_over_ground": 66,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 626064000,
      "imo": 9140451,
      "timestamp": "5/19/2022 22:14",
      "vessel_name": "TRENCY TAIPEI",
      "vessel_flag": "GABON",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 241797000,
      "imo": 9934890,
      "timestamp": "7/6/2022 20:30",
      "vessel_name": "IRINI",
      "vessel_flag": "GREECE",
      "course_over_ground": 65,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 241212000,
      "imo": 9592290,
      "timestamp": "7/25/2022 18:16",
      "vessel_name": "NISSOS PAROS",
      "vessel_flag": "GREECE",
      "course_over_ground": 65,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 240729000,
      "imo": 9298507,
      "timestamp": "8/12/2022 19:27",
      "vessel_name": "MINERVA ANNA",
      "vessel_flag": "GREECE",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 241132000,
      "imo": 9592252,
      "timestamp": "8/28/2022 15:46",
      "vessel_name": "MINERVA KYTHNOS",
      "vessel_flag": "GREECE",
      "course_over_ground": 65,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 240957000,
      "imo": 9418157,
      "timestamp": "8/29/2022 14:38",
      "vessel_name": "DELTA COMMANDER",
      "vessel_flag": "GREECE",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 241359000,
      "imo": 9694658,
      "timestamp": "5/28/2022 6:20",
      "vessel_name": "NISSOS CHRISTIANA",
      "vessel_flag": "GREECE",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 241491000,
      "imo": 9779381,
      "timestamp": "7/9/2022 19:08",
      "vessel_name": "MARAN HELEN",
      "vessel_flag": "GREECE",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 240176000,
      "imo": 9255684,
      "timestamp": "8/18/2022 9:50",
      "vessel_name": "MINERVA ZOE",
      "vessel_flag": "GREECE",
      "course_over_ground": 67,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 241689000,
      "imo": 9853008,
      "timestamp": "8/18/2022 10:28",
      "vessel_name": "MINERVA KALLISTO",
      "vessel_flag": "GREECE",
      "course_over_ground": 67,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 240609000,
      "imo": 9297541,
      "timestamp": "6/7/2022 11:24",
      "vessel_name": "BONITA",
      "vessel_flag": "GREECE",
      "course_over_ground": 68,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 241322000,
      "imo": 9270737,
      "timestamp": "7/8/2022 23:51",
      "vessel_name": "KRITI",
      "vessel_flag": "GREECE",
      "course_over_ground": 68,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 240995000,
      "imo": 9429297,
      "timestamp": "7/18/2022 17:39",
      "vessel_name": "NICOLAOS",
      "vessel_flag": "GREECE",
      "course_over_ground": 68,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 241445000,
      "imo": 9745237,
      "timestamp": "7/25/2022 16:37",
      "vessel_name": "AEGEAN UNITY",
      "vessel_flag": "GREECE",
      "course_over_ground": 68,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 241279000,
      "imo": 9458016,
      "timestamp": "6/23/2022 10:32",
      "vessel_name": "DELTA STAR",
      "vessel_flag": "GREECE",
      "course_over_ground": 69,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 477049200,
      "imo": 9401025,
      "timestamp": "8/22/2022 17:41",
      "vessel_name": "CANOPUS",
      "vessel_flag": "HONG KONG",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 477735600,
      "imo": 9858785,
      "timestamp": "5/11/2022 8:45",
      "vessel_name": "GOLDEN DAHLIA",
      "vessel_flag": "HONG KONG",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 477057300,
      "imo": 9742211,
      "timestamp": "7/6/2022 18:06",
      "vessel_name": "LR2 APHRODITE",
      "vessel_flag": "HONG KONG",
      "course_over_ground": 68,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 477185900,
      "imo": 9380532,
      "timestamp": "7/11/2022 21:05",
      "vessel_name": "FORRES PARK",
      "vessel_flag": "HONG KONG",
      "course_over_ground": 71,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 419001709,
      "imo": 9173068,
      "timestamp": "7/9/2022 9:19",
      "vessel_name": "SANMAR REGENT",
      "vessel_flag": "INDIA",
      "course_over_ground": 68,
      "vessel_type": "84 - Tanker, Hazardous category D",
      "vessel_type_code": 84
    },
    {
      "mmsi": 247393700,
      "imo": 9314856,
      "timestamp": "5/7/2022 19:47",
      "vessel_name": "SAN SEBASTIAN",
      "vessel_flag": "ITALY",
      "course_over_ground": 65,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 247325600,
      "imo": 9346914,
      "timestamp": "5/16/2022 15:09",
      "vessel_name": "SYN TURAIS",
      "vessel_flag": "ITALY",
      "course_over_ground": 65,
      "vessel_type": "83 - Tanker, Hazardous category C",
      "vessel_type_code": 83
    },
    {
      "mmsi": 247013600,
      "imo": 9217890,
      "timestamp": "8/12/2022 19:27",
      "vessel_name": "FALESIA",
      "vessel_flag": "ITALY",
      "course_over_ground": 65,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 247351000,
      "imo": 9160310,
      "timestamp": "6/17/2022 3:32",
      "vessel_name": "IEVOLI SPEED",
      "vessel_flag": "ITALY",
      "course_over_ground": 66,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 247182500,
      "imo": 9201885,
      "timestamp": "6/23/2022 3:05",
      "vessel_name": "MARIA M",
      "vessel_flag": "ITALY",
      "course_over_ground": 66,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 247253200,
      "imo": 9147746,
      "timestamp": "7/26/2022 19:31",
      "vessel_name": "MIMMO IEVOLI",
      "vessel_flag": "ITALY",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 247758000,
      "imo": 9125279,
      "timestamp": "8/31/2022 2:33",
      "vessel_name": "NORMANNA",
      "vessel_flag": "ITALY",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 247158200,
      "imo": 9293961,
      "timestamp": "7/2/2022 4:45",
      "vessel_name": "FUTURA",
      "vessel_flag": "ITALY",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 247163500,
      "imo": 9327346,
      "timestamp": "8/30/2022 4:35",
      "vessel_name": "ETRUSCO",
      "vessel_flag": "ITALY",
      "course_over_ground": 67,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 247079700,
      "imo": 9251755,
      "timestamp": "5/15/2022 18:46",
      "vessel_name": "COSMO",
      "vessel_flag": "ITALY",
      "course_over_ground": 69,
      "vessel_type": "83 - Tanker, Hazardous category C",
      "vessel_type_code": 83
    },
    {
      "mmsi": 247384900,
      "imo": 9323792,
      "timestamp": "6/15/2022 4:31",
      "vessel_name": "ALICE",
      "vessel_flag": "ITALY",
      "course_over_ground": 69,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 247221100,
      "imo": 9334325,
      "timestamp": "5/19/2022 16:54",
      "vessel_name": "SARACENA",
      "vessel_flag": "ITALY",
      "course_over_ground": 348,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636013354,
      "imo": 9403542,
      "timestamp": "8/29/2022 13:21",
      "vessel_name": "LEADER",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 61,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636017417,
      "imo": 9761164,
      "timestamp": "5/28/2022 9:47",
      "vessel_name": "NAVIGATOR LUGA",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 62,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636019055,
      "imo": 9843572,
      "timestamp": "5/21/2022 17:36",
      "vessel_name": "SAMUEL PROSPECT",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 63,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 636018612,
      "imo": 9853230,
      "timestamp": "8/24/2022 21:19",
      "vessel_name": "NAVIG8 GLADIATOR",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 63,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636017739,
      "imo": 9329409,
      "timestamp": "6/11/2022 15:23",
      "vessel_name": "KRITI SAMARIA",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 64,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636021258,
      "imo": 9306641,
      "timestamp": "8/27/2022 22:27",
      "vessel_name": "OLYMPIC VISION",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 64,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636021677,
      "imo": 9171735,
      "timestamp": "8/31/2022 14:25",
      "vessel_name": "PRISCO ALFA",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 64,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636093012,
      "imo": 9352195,
      "timestamp": "6/14/2022 4:52",
      "vessel_name": "LUGANO",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636021420,
      "imo": 9410002,
      "timestamp": "6/25/2022 5:39",
      "vessel_name": "AVENCA",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636012856,
      "imo": 9312896,
      "timestamp": "6/30/2022 10:07",
      "vessel_name": "NS CREATION",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 65,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 636014044,
      "imo": 9407847,
      "timestamp": "7/5/2022 12:22",
      "vessel_name": "MAISTROS",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636019956,
      "imo": 9244374,
      "timestamp": "7/24/2022 23:14",
      "vessel_name": "KANAZAWA",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636014743,
      "imo": 9573660,
      "timestamp": "7/29/2022 19:48",
      "vessel_name": "PACIFIC DIAMOND",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636021637,
      "imo": 9231171,
      "timestamp": "5/9/2022 22:46",
      "vessel_name": "ZAGARA",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636021554,
      "imo": 9268394,
      "timestamp": "5/27/2022 12:05",
      "vessel_name": "IVI",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 66,
      "vessel_type": "84 - Tanker, Hazardous category D",
      "vessel_type_code": 84
    },
    {
      "mmsi": 636021149,
      "imo": 9430179,
      "timestamp": "6/2/2022 20:32",
      "vessel_name": "MARLEN",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636017658,
      "imo": 9354313,
      "timestamp": "6/7/2022 15:06",
      "vessel_name": "ZALIV AMURSKIY",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 66,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 636014337,
      "imo": 9288772,
      "timestamp": "6/7/2022 21:12",
      "vessel_name": "DIDIMON",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636014185,
      "imo": 9410181,
      "timestamp": "7/26/2022 18:27",
      "vessel_name": "DELTA SKY",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 66,
      "vessel_type": "81 - Tanker, Hazardous category A",
      "vessel_type_code": 81
    },
    {
      "mmsi": 636015015,
      "imo": 9493779,
      "timestamp": "7/31/2022 11:36",
      "vessel_name": "SMYRNI",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636014902,
      "imo": 9315800,
      "timestamp": "8/17/2022 22:06",
      "vessel_name": "AEGEAS",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 66,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 636021882,
      "imo": 9585895,
      "timestamp": "8/28/2022 15:47",
      "vessel_name": "DELTA SUPREME",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636017874,
      "imo": 9391153,
      "timestamp": "8/29/2022 6:34",
      "vessel_name": "SEA MAJESTIC",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 66,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 636093062,
      "imo": 9575292,
      "timestamp": "5/21/2022 22:18",
      "vessel_name": "MUBAPIZ IRAHIMOV",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 67,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 636093020,
      "imo": 9228801,
      "timestamp": "5/30/2022 1:48",
      "vessel_name": "BALTIC SKY I",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 67,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 636093106,
      "imo": 9315745,
      "timestamp": "7/4/2022 2:37",
      "vessel_name": "CHEMTRANS LEO",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636021850,
      "imo": 9391385,
      "timestamp": "7/20/2022 18:42",
      "vessel_name": "LADYBUG",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636019107,
      "imo": 9288899,
      "timestamp": "7/25/2022 14:06",
      "vessel_name": "EUROVOYAGER",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636019402,
      "imo": 9112117,
      "timestamp": "7/26/2022 22:06",
      "vessel_name": "CHAMPION TIDE",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 67,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 636010915,
      "imo": 9149524,
      "timestamp": "8/18/2022 5:09",
      "vessel_name": "STOLT SPAN",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 67,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 636013277,
      "imo": 9329667,
      "timestamp": "5/16/2022 19:47",
      "vessel_name": "NS PARADE",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 68,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636021183,
      "imo": 9252448,
      "timestamp": "6/13/2022 3:42",
      "vessel_name": "BOURDA",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 68,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636019667,
      "imo": 9258014,
      "timestamp": "6/24/2022 5:49",
      "vessel_name": "NOSTOS",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 68,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636019212,
      "imo": 9800790,
      "timestamp": "7/10/2022 7:52",
      "vessel_name": "HISTRIA ATLAS",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 68,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636021437,
      "imo": 9313462,
      "timestamp": "8/28/2022 22:13",
      "vessel_name": "LILA RHINE",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 68,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636014252,
      "imo": 9509011,
      "timestamp": "5/11/2022 8:14",
      "vessel_name": "MOUNT FUJI",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 69,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 636020407,
      "imo": 9736690,
      "timestamp": "8/23/2022 17:58",
      "vessel_name": "MUSTAFA NECATI",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 69,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 636020929,
      "imo": 9396488,
      "timestamp": "7/21/2022 16:39",
      "vessel_name": "PERICLES",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 70,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636017654,
      "imo": 9397559,
      "timestamp": "6/8/2022 19:10",
      "vessel_name": "SCF ANGARA",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 73,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636011305,
      "imo": 917636900,
      "timestamp": "5/24/2022 9:22",
      "vessel_name": "DJANET",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 80,
      "vessel_type": "84 - Tanker, Hazardous category D",
      "vessel_type_code": 84
    },
    {
      "mmsi": 636020741,
      "imo": 9336490,
      "timestamp": "5/27/2022 7:36",
      "vessel_name": "ARISTOS",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 88,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 229546000,
      "imo": 9596777,
      "timestamp": "5/27/2022 23:40",
      "vessel_name": "SEYMA",
      "vessel_flag": "MALTA",
      "course_over_ground": 64,
      "vessel_type": "83 - Tanker, Hazardous category C",
      "vessel_type_code": 83
    },
    {
      "mmsi": 248759000,
      "imo": 9464285,
      "timestamp": "5/11/2022 4:21",
      "vessel_name": "MRC MINA",
      "vessel_flag": "MALTA",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 215910000,
      "imo": 9448310,
      "timestamp": "5/14/2022 17:54",
      "vessel_name": "CLOTILDE",
      "vessel_flag": "MALTA",
      "course_over_ground": 65,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 215872000,
      "imo": 9221786,
      "timestamp": "5/15/2022 2:35",
      "vessel_name": "SELIN D",
      "vessel_flag": "MALTA",
      "course_over_ground": 65,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 229881000,
      "imo": 9327396,
      "timestamp": "6/11/2022 21:37",
      "vessel_name": "BALTIC FREEDOM",
      "vessel_flag": "MALTA",
      "course_over_ground": 65,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 249811000,
      "imo": 9776731,
      "timestamp": "7/9/2022 17:01",
      "vessel_name": "MALIBU",
      "vessel_flag": "MALTA",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 256247000,
      "imo": 9369928,
      "timestamp": "7/27/2022 23:46",
      "vessel_name": "BOYNE",
      "vessel_flag": "MALTA",
      "course_over_ground": 65,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 215594000,
      "imo": 9811127,
      "timestamp": "5/17/2022 20:34",
      "vessel_name": "PREVEZE 1",
      "vessel_flag": "MALTA",
      "course_over_ground": 66,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 249855000,
      "imo": 9777931,
      "timestamp": "5/20/2022 7:00",
      "vessel_name": "RYMAN",
      "vessel_flag": "MALTA",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 215515000,
      "imo": 9843209,
      "timestamp": "6/13/2022 10:14",
      "vessel_name": "SEAFAITH",
      "vessel_flag": "MALTA",
      "course_over_ground": 66,
      "vessel_type": "81 - Tanker, Hazardous category A",
      "vessel_type_code": 81
    },
    {
      "mmsi": 229171000,
      "imo": 9405784,
      "timestamp": "6/14/2022 21:05",
      "vessel_name": "M/T ZUGA",
      "vessel_flag": "MALTA",
      "course_over_ground": 66,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 215086000,
      "imo": 9835032,
      "timestamp": "6/20/2022 2:15",
      "vessel_name": "MINA DENIZ",
      "vessel_flag": "MALTA",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 215233000,
      "imo": 9798375,
      "timestamp": "6/23/2022 1:10",
      "vessel_name": "AGIOI FANENDES",
      "vessel_flag": "MALTA",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 248551000,
      "imo": 9531442,
      "timestamp": "6/23/2022 23:30",
      "vessel_name": "ADELE",
      "vessel_flag": "MALTA",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 229176000,
      "imo": 9489584,
      "timestamp": "7/27/2022 18:55",
      "vessel_name": "REKON",
      "vessel_flag": "MALTA",
      "course_over_ground": 66,
      "vessel_type": "85 - Tanker, Reserved for future use",
      "vessel_type_code": 85
    },
    {
      "mmsi": 229160000,
      "imo": 9388807,
      "timestamp": "5/14/2022 15:04",
      "vessel_name": "SEAJEWEL",
      "vessel_flag": "MALTA",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 249379000,
      "imo": 9380415,
      "timestamp": "5/19/2022 9:05",
      "vessel_name": "DUZGIT INTEGRITY",
      "vessel_flag": "MALTA",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 248132000,
      "imo": 9464352,
      "timestamp": "5/23/2022 18:43",
      "vessel_name": "LUCKY SAILOR",
      "vessel_flag": "MALTA",
      "course_over_ground": 67,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 215743000,
      "imo": 9388364,
      "timestamp": "6/1/2022 15:09",
      "vessel_name": "MERBABU",
      "vessel_flag": "MALTA",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 249711000,
      "imo": 9800374,
      "timestamp": "6/2/2022 21:32",
      "vessel_name": "GRETA K",
      "vessel_flag": "MALTA",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 249446000,
      "imo": 9431056,
      "timestamp": "7/5/2022 10:42",
      "vessel_name": "MAINLAND",
      "vessel_flag": "MALTA",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 256721000,
      "imo": 9473066,
      "timestamp": "8/13/2022 16:04",
      "vessel_name": "SEALEO",
      "vessel_flag": "MALTA",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 215867000,
      "imo": 9879088,
      "timestamp": "8/24/2022 22:09",
      "vessel_name": "IVYAN",
      "vessel_flag": "MALTA",
      "course_over_ground": 67,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 215498000,
      "imo": 9544358,
      "timestamp": "8/29/2022 4:48",
      "vessel_name": "MED CANARY",
      "vessel_flag": "MALTA",
      "course_over_ground": 67,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 249028000,
      "imo": 9418793,
      "timestamp": "8/29/2022 7:13",
      "vessel_name": "MRC SEMIRAMIS",
      "vessel_flag": "MALTA",
      "course_over_ground": 67,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 229084000,
      "imo": 9380063,
      "timestamp": "5/21/2022 21:07",
      "vessel_name": "MINERVA EMILY",
      "vessel_flag": "MALTA",
      "course_over_ground": 68,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 256445000,
      "imo": 9260500,
      "timestamp": "5/24/2022 12:34",
      "vessel_name": "SANTA LUCIA",
      "vessel_flag": "MALTA",
      "course_over_ground": 68,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 248963000,
      "imo": 9856634,
      "timestamp": "6/30/2022 8:38",
      "vessel_name": "HAFNIA BEIJING",
      "vessel_flag": "MALTA",
      "course_over_ground": 68,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 215013000,
      "imo": 9452268,
      "timestamp": "7/11/2022 21:47",
      "vessel_name": "DELTA SEA",
      "vessel_flag": "MALTA",
      "course_over_ground": 68,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 248372000,
      "imo": 9787912,
      "timestamp": "7/19/2022 16:04",
      "vessel_name": "FLAVIN",
      "vessel_flag": "MALTA",
      "course_over_ground": 68,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 248475000,
      "imo": 9438248,
      "timestamp": "6/30/2022 20:39",
      "vessel_name": "GURONI",
      "vessel_flag": "MALTA",
      "course_over_ground": 69,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 215330000,
      "imo": 9143439,
      "timestamp": "7/21/2022 22:48",
      "vessel_name": "SANTA MARTINA",
      "vessel_flag": "MALTA",
      "course_over_ground": 94,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 229357000,
      "imo": 9299496,
      "timestamp": "7/13/2022 19:15",
      "vessel_name": "PHOENIX AN",
      "vessel_flag": "MALTA",
      "course_over_ground": 99,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 538002594,
      "imo": 9337327,
      "timestamp": "7/13/2022 7:14",
      "vessel_name": "T REX",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 4,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538006202,
      "imo": 9419448,
      "timestamp": "5/22/2022 15:43",
      "vessel_name": "AMAX ARROW",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 63,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538008798,
      "imo": 9308950,
      "timestamp": "5/12/2022 17:27",
      "vessel_name": "SEA SENOR",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 64,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538008328,
      "imo": 9578658,
      "timestamp": "6/5/2022 18:37",
      "vessel_name": "PSERIMOS",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 64,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538008428,
      "imo": 9353084,
      "timestamp": "5/17/2022 23:46",
      "vessel_name": "DALE",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538004118,
      "imo": 9409259,
      "timestamp": "5/23/2022 13:24",
      "vessel_name": "FREE SPIRIT",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538009704,
      "imo": 9494216,
      "timestamp": "6/10/2022 22:06",
      "vessel_name": "BONDI",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538003440,
      "imo": 9369887,
      "timestamp": "8/1/2022 5:20",
      "vessel_name": "SIRTAKI",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538009717,
      "imo": 9308144,
      "timestamp": "8/3/2022 0:59",
      "vessel_name": "CLEAN IMPERIAL",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538010123,
      "imo": 9248796,
      "timestamp": "8/22/2022 3:20",
      "vessel_name": "SEAEXPLORER",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538006581,
      "imo": 9282364,
      "timestamp": "5/11/2022 19:55",
      "vessel_name": "TATLISU",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538005894,
      "imo": 9725598,
      "timestamp": "5/15/2022 21:03",
      "vessel_name": "ECO REVOLUTION",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538009511,
      "imo": 9914292,
      "timestamp": "5/22/2022 21:38",
      "vessel_name": "FAIRCHEM FYNBOS",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538004120,
      "imo": 9447809,
      "timestamp": "6/19/2022 16:32",
      "vessel_name": "GAS MANTA",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 66,
      "vessel_type": "81 - Tanker, Hazardous category A",
      "vessel_type_code": 81
    },
    {
      "mmsi": 538008320,
      "imo": 9439670,
      "timestamp": "8/22/2022 12:11",
      "vessel_name": "CAPTAIN A.STELLATOS",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538002648,
      "imo": 9252955,
      "timestamp": "8/23/2022 13:18",
      "vessel_name": "ARIEL",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538009736,
      "imo": 9823558,
      "timestamp": "5/24/2022 10:29",
      "vessel_name": "MARLIN LORETO",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538006773,
      "imo": 9732242,
      "timestamp": "7/6/2022 19:39",
      "vessel_name": "PACIFIC TREASURES",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538004518,
      "imo": 9416551,
      "timestamp": "7/7/2022 16:49",
      "vessel_name": "ALATAU",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538001964,
      "imo": 9264283,
      "timestamp": "8/11/2022 22:15",
      "vessel_name": "CAPE BACTON",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 67,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 538003870,
      "imo": 9354222,
      "timestamp": "8/15/2022 21:02",
      "vessel_name": "GAS FLAWLESS",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538008330,
      "imo": 9440382,
      "timestamp": "8/27/2022 15:44",
      "vessel_name": "MIKELA P.",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 67,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 538009048,
      "imo": 9820283,
      "timestamp": "5/7/2022 22:00",
      "vessel_name": "AGIOS NIKOLAOS",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 68,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538008097,
      "imo": 9307982,
      "timestamp": "5/14/2022 21:18",
      "vessel_name": "IDI",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 68,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 538007204,
      "imo": 9407067,
      "timestamp": "6/28/2022 4:30",
      "vessel_name": "GWEN",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 68,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538007627,
      "imo": 9290505,
      "timestamp": "7/28/2022 19:13",
      "vessel_name": "BASILIS L",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 68,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538003816,
      "imo": 9290828,
      "timestamp": "7/7/2022 17:44",
      "vessel_name": "BYRON",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 69,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538008327,
      "imo": 9389966,
      "timestamp": "8/8/2022 10:55",
      "vessel_name": "ALEXIA",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 69,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538009985,
      "imo": 9923994,
      "timestamp": "8/26/2022 10:19",
      "vessel_name": "DINO",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 69,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538005786,
      "imo": 9727584,
      "timestamp": "5/23/2022 21:05",
      "vessel_name": "HAFNIA ACHROITE",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 70,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538005808,
      "imo": 9721918,
      "timestamp": "6/30/2022 1:41",
      "vessel_name": "MARLIN AVENTURINE",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 74,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538003852,
      "imo": 9489106,
      "timestamp": "7/27/2022 22:49",
      "vessel_name": "NAVIG8 UNIVERSE",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 85,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 257885000,
      "imo": 9592692,
      "timestamp": "8/3/2022 17:12",
      "vessel_name": "JO PROVEL",
      "vessel_flag": "NORWAY",
      "course_over_ground": 64,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 257033170,
      "imo": 9272199,
      "timestamp": "7/2/2022 21:03",
      "vessel_name": "CHAMPION CONTEST",
      "vessel_flag": "NORWAY",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 257224000,
      "imo": 9255282,
      "timestamp": "8/7/2022 0:44",
      "vessel_name": "FUTURA",
      "vessel_flag": "NORWAY",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 257722000,
      "imo": 9428994,
      "timestamp": "8/28/2022 14:00",
      "vessel_name": "SKS DEE",
      "vessel_flag": "NORWAY",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 257077000,
      "imo": 9587192,
      "timestamp": "5/31/2022 15:28",
      "vessel_name": "MT DOLVIKEN",
      "vessel_flag": "NORWAY",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 257084660,
      "imo": 9288760,
      "timestamp": "8/5/2022 18:48",
      "vessel_name": "SUNNY VICTORY",
      "vessel_flag": "NORWAY",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 371458000,
      "imo": 9370836,
      "timestamp": "5/13/2022 14:37",
      "vessel_name": "NEW AMORGOS",
      "vessel_flag": "PANAMA",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 374100000,
      "imo": 9185530,
      "timestamp": "5/20/2022 8:56",
      "vessel_name": "SPAR",
      "vessel_flag": "PANAMA",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 374851000,
      "imo": 8920282,
      "timestamp": "8/4/2022 14:11",
      "vessel_name": "MELA ROSSA",
      "vessel_flag": "PANAMA",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 374024000,
      "imo": 9234501,
      "timestamp": "6/4/2022 23:18",
      "vessel_name": "SEALION I",
      "vessel_flag": "PANAMA",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 352001760,
      "imo": 9284647,
      "timestamp": "8/17/2022 19:32",
      "vessel_name": "MAVKA",
      "vessel_flag": "PANAMA",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 356522000,
      "imo": 9511650,
      "timestamp": "8/21/2022 6:00",
      "vessel_name": "GAZ UNITED",
      "vessel_flag": "PANAMA",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 357642000,
      "imo": 9435325,
      "timestamp": "5/9/2022 21:51",
      "vessel_name": "AGVANIS",
      "vessel_flag": "PANAMA",
      "course_over_ground": 67,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 356159000,
      "imo": 9499486,
      "timestamp": "7/22/2022 2:12",
      "vessel_name": "OPEC VICTORY",
      "vessel_flag": "PANAMA",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 352001463,
      "imo": 9316593,
      "timestamp": "7/28/2022 23:01",
      "vessel_name": "PS PELICAN",
      "vessel_flag": "PANAMA",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 352001453,
      "imo": 9258870,
      "timestamp": "8/26/2022 12:38",
      "vessel_name": "EMILIA",
      "vessel_flag": "PANAMA",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 352388000,
      "imo": 9400394,
      "timestamp": "5/8/2022 17:35",
      "vessel_name": "SKARVEN",
      "vessel_flag": "PANAMA",
      "course_over_ground": 68,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 355306000,
      "imo": 9521643,
      "timestamp": "6/4/2022 19:40",
      "vessel_name": "SUNPOWER",
      "vessel_flag": "PANAMA",
      "course_over_ground": 68,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 352001538,
      "imo": 9133109,
      "timestamp": "7/15/2022 23:46",
      "vessel_name": "ROMEO GAS",
      "vessel_flag": "PANAMA",
      "course_over_ground": 68,
      "vessel_type": "83 - Tanker, Hazardous category C",
      "vessel_type_code": 83
    },
    {
      "mmsi": 373859000,
      "imo": 9014420,
      "timestamp": "8/18/2022 2:21",
      "vessel_name": "HAMBURG DW",
      "vessel_flag": "PANAMA",
      "course_over_ground": 68,
      "vessel_type": "81 - Tanker, Hazardous category A",
      "vessel_type_code": 81
    },
    {
      "mmsi": 351084000,
      "imo": 9382085,
      "timestamp": "5/10/2022 21:39",
      "vessel_name": "BITU ATLANTIC",
      "vessel_flag": "PANAMA",
      "course_over_ground": 69,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 372288000,
      "imo": 9358319,
      "timestamp": "8/15/2022 13:40",
      "vessel_name": "PS QUEEN",
      "vessel_flag": "PANAMA",
      "course_over_ground": 69,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 353069000,
      "imo": 9758090,
      "timestamp": "6/5/2022 10:56",
      "vessel_name": "FAIRCHEM SUCCESS",
      "vessel_flag": "PANAMA",
      "course_over_ground": 73,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 352001672,
      "imo": 9251676,
      "timestamp": "7/29/2022 22:21",
      "vessel_name": "CENTAR",
      "vessel_flag": "PANAMA",
      "course_over_ground": 73,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 548651000,
      "imo": 9865532,
      "timestamp": "6/20/2022 21:05",
      "vessel_name": "EVA USUKI",
      "vessel_flag": "PHILIPPINES",
      "course_over_ground": 64,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 255806523,
      "imo": 9234628,
      "timestamp": "7/5/2022 21:02",
      "vessel_name": "APOLLO",
      "vessel_flag": "PORTUGAL",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 255803540,
      "imo": 9300489,
      "timestamp": "7/29/2022 8:28",
      "vessel_name": "COVADONGA",
      "vessel_flag": "PORTUGAL",
      "course_over_ground": 67,
      "vessel_type": "81 - Tanker, Hazardous category A",
      "vessel_type_code": 81
    },
    {
      "mmsi": 255805370,
      "imo": 9314777,
      "timestamp": "5/31/2022 12:08",
      "vessel_name": "AMALIE ESSBERGER",
      "vessel_flag": "PORTUGAL",
      "course_over_ground": 68,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 341523000,
      "imo": 9248801,
      "timestamp": "7/3/2022 13:30",
      "vessel_name": "ATACAMA",
      "vessel_flag": "SAINT KITTS AND NEVIS",
      "course_over_ground": 62,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 564335000,
      "imo": 9291456,
      "timestamp": "5/14/2022 13:05",
      "vessel_name": "MTM ANTWERP",
      "vessel_flag": "SINGAPORE",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 565814000,
      "imo": 9256298,
      "timestamp": "5/21/2022 21:31",
      "vessel_name": "MAERSK KALEA",
      "vessel_flag": "SINGAPORE",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 566739000,
      "imo": 9629706,
      "timestamp": "8/5/2022 20:51",
      "vessel_name": "NORD STEADY",
      "vessel_flag": "SINGAPORE",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 565961000,
      "imo": 9394208,
      "timestamp": "6/25/2022 21:12",
      "vessel_name": "NASHWAN",
      "vessel_flag": "SINGAPORE",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 565873000,
      "imo": 9712606,
      "timestamp": "8/27/2022 22:54",
      "vessel_name": "MTM SANTOS",
      "vessel_flag": "SINGAPORE",
      "course_over_ground": 66,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 566722000,
      "imo": 9652741,
      "timestamp": "5/6/2022 19:16",
      "vessel_name": "KINGSTON",
      "vessel_flag": "SINGAPORE",
      "course_over_ground": 67,
      "vessel_type": "81 - Tanker, Hazardous category A",
      "vessel_type_code": 81
    },
    {
      "mmsi": 563054800,
      "imo": 9791169,
      "timestamp": "6/18/2022 2:19",
      "vessel_name": "NAEBA GALAXY",
      "vessel_flag": "SINGAPORE",
      "course_over_ground": 67,
      "vessel_type": "83 - Tanker, Hazardous category C",
      "vessel_type_code": 83
    },
    {
      "mmsi": 563101100,
      "imo": 9833199,
      "timestamp": "6/30/2022 8:17",
      "vessel_name": "CRYSTAL ANGEL",
      "vessel_flag": "SINGAPORE",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 566976000,
      "imo": 9379844,
      "timestamp": "7/2/2022 23:01",
      "vessel_name": "MTM HAMBURG",
      "vessel_flag": "SINGAPORE",
      "course_over_ground": 70,
      "vessel_type": "81 - Tanker, Hazardous category A",
      "vessel_type_code": 81
    },
    {
      "mmsi": 671307100,
      "imo": 9005493,
      "timestamp": "7/17/2022 19:22",
      "vessel_name": "MARINA",
      "vessel_flag": "TOGO",
      "course_over_ground": 69,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271045975,
      "imo": 8920579,
      "timestamp": "7/27/2022 23:51",
      "vessel_name": "EVRENYE",
      "vessel_flag": "TURKEY",
      "course_over_ground": 64,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 271044414,
      "imo": 9241803,
      "timestamp": "5/7/2022 22:29",
      "vessel_name": "BAKI AKAR",
      "vessel_flag": "TURKEY",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271002726,
      "imo": 9499838,
      "timestamp": "7/7/2022 19:38",
      "vessel_name": "M\\T T.GONUL",
      "vessel_flag": "TURKEY",
      "course_over_ground": 65,
      "vessel_type": "81 - Tanker, Hazardous category A",
      "vessel_type_code": 81
    },
    {
      "mmsi": 271050280,
      "imo": 9402811,
      "timestamp": "7/29/2022 5:46",
      "vessel_name": "CHEM PREMIER",
      "vessel_flag": "TURKEY",
      "course_over_ground": 65,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 271002613,
      "imo": 9404950,
      "timestamp": "5/14/2022 13:20",
      "vessel_name": "OTTOMAN EQUITY",
      "vessel_flag": "TURKEY",
      "course_over_ground": 66,
      "vessel_type": "81 - Tanker, Hazardous category A",
      "vessel_type_code": 81
    },
    {
      "mmsi": 271045665,
      "imo": 9260550,
      "timestamp": "5/18/2022 0:58",
      "vessel_name": "LIDYA",
      "vessel_flag": "TURKEY",
      "course_over_ground": 66,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 271049308,
      "imo": 9220354,
      "timestamp": "7/4/2022 2:27",
      "vessel_name": "KARLICA",
      "vessel_flag": "TURKEY",
      "course_over_ground": 66,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 271049994,
      "imo": 9312925,
      "timestamp": "7/17/2022 21:51",
      "vessel_name": "CAN KA",
      "vessel_flag": "TURKEY",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271040601,
      "imo": 9547570,
      "timestamp": "7/24/2022 10:38",
      "vessel_name": "EMIN REIS",
      "vessel_flag": "TURKEY",
      "course_over_ground": 66,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 271043255,
      "imo": 9652911,
      "timestamp": "5/10/2022 18:56",
      "vessel_name": "T.SUNA",
      "vessel_flag": "TURKEY",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271046486,
      "imo": 9830305,
      "timestamp": "5/13/2022 22:26",
      "vessel_name": "T.ADALYN",
      "vessel_flag": "TURKEY",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271046574,
      "imo": 9125267,
      "timestamp": "5/14/2022 1:32",
      "vessel_name": "FERICEK",
      "vessel_flag": "TURKEY",
      "course_over_ground": 67,
      "vessel_type": "83 - Tanker, Hazardous category C",
      "vessel_type_code": 83
    },
    {
      "mmsi": 271046350,
      "imo": 9140841,
      "timestamp": "6/7/2022 8:37",
      "vessel_name": "GUNECE",
      "vessel_flag": "TURKEY",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271049800,
      "imo": 9427469,
      "timestamp": "6/9/2022 5:08",
      "vessel_name": "GLOBAL LAKE",
      "vessel_flag": "TURKEY",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271050193,
      "imo": 9402835,
      "timestamp": "7/21/2022 10:36",
      "vessel_name": "CHEM LEADER",
      "vessel_flag": "TURKEY",
      "course_over_ground": 67,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271050245,
      "imo": 9311751,
      "timestamp": "7/27/2022 18:19",
      "vessel_name": "ESEN KA",
      "vessel_flag": "TURKEY",
      "course_over_ground": 67,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 271002668,
      "imo": 9493377,
      "timestamp": "5/14/2022 0:26",
      "vessel_name": "KEMAL KA",
      "vessel_flag": "TURKEY",
      "course_over_ground": 70,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 235070283,
      "imo": 9439814,
      "timestamp": "6/27/2022 5:43",
      "vessel_name": "ERIN SCHULTE",
      "vessel_flag": "UNITED KINGDOM",
      "course_over_ground": 65,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 232042070,
      "imo": 9291602,
      "timestamp": "7/17/2022 2:59",
      "vessel_name": "FS CLARA",
      "vessel_flag": "UNITED KINGDOM",
      "course_over_ground": 66,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 232026670,
      "imo": 9339636,
      "timestamp": "5/23/2022 15:46",
      "vessel_name": "VS SPIRIT",
      "vessel_flag": "UNITED KINGDOM",
      "course_over_ground": 67,
      "vessel_type": "84 - Tanker, Hazardous category D",
      "vessel_type_code": 84
    },
    {
      "mmsi": 232012788,
      "imo": 9817614,
      "timestamp": "6/1/2022 15:43",
      "vessel_name": "ENERGY TRIUMPH",
      "vessel_flag": "UNITED KINGDOM",
      "course_over_ground": 69,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 574322000,
      "imo": 9255830,
      "timestamp": "7/21/2022 3:00",
      "vessel_name": "SEA MARINE 1",
      "vessel_flag": "VIETNAM",
      "course_over_ground": 65,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    }
  ]