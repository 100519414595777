import './auth.scss';
import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from 'react-router-dom';
import { AccountContext }   from "./Accounts";

export default function Login(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    let history = useHistory();
    const { authenticate } = useContext(AccountContext);
    
    //If a logged in user goes to the root path it will redirect them to the landing page
    useEffect(() => {
        if(props.auth.isAuthenticated) {
            history.push('/home');
        }
    }, [props.auth, history]);

    const logInUser = async () => {
        try {
            if(username === '' || password === '') {
                document.getElementById('error-label').innerHTML = `Please provide credentials`;
                console.log('No credentials passed');
            }
            else {
                authenticate(username, password)
                    .then(data => {
                        console.log('Logged in!', data);
                        props.auth.setAuthState(true);
                        history.push('/home');
                    })
                    .catch(err => {
                        document.getElementById('error-label').innerHTML = `Invalid credentials!`;
                        console.log('Failed to log in!', err);
                    });
            }
        } catch (err) {
            console.log(err)
            document.getElementById('error-label').innerHTML = `${err}`;
        }
    };

    return (
        <div className='page-container'>
            <div className='inputs'>
                <div className='page-header-logo'/>
                <label id='error-label' className='error-label'></label>
                <input className='input' id='username' onChange={(e)=> setUsername(e.target.value)} label='Username' required placeholder='Email or Username'/>
                <input className='input' id='password' type='password' onChange={(e)=> setPassword(e.target.value)} label='Password' required placeholder='Password'/>
                <button className='button' variant='contained' onClick={()=>logInUser()}>Log in</button>
                <div className='inputs-text'>Forgot Password? Click <Link to='/forgotPass'>Here</Link></div>
                <div className='inputs-text' style={{width: '90%', textAlign: 'center', marginTop: '1rem'}}>Channel Logistics LLC is a US based company and only conducts business with US allies.</div>
                {/* <div className='inputs-text'>Don't have an account? <Link to='/register'>Register</Link> now</div> */}
            </div>
        </div>
    );
}