/*******************************************************
 * Copyright (C) 2022 Space-Eyes LLC
 * 
 * This file is part of Data-Lake-Web-App.
 * 
 * SMART Sanctions can not be copied and/or distributed without the express
 * permission of Space-Eyes LLC
 * 
 *******************************************************/

import './App.scss';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Account } from './pages/auth/Accounts';
import Login from './pages/auth/Login';
import ForgotPassword from './pages/auth/ForgotPassword';
import Pool from './UserPool';
import Sidebar from './components/Sidebar/Sidebar';
import Cases from './pages/Cases/Cases';
import MapDashboard from './pages/Map/Map';
import Dashboard from './pages/Landing/Dashboard';

export default function App() {
  const[isAuthenticated, setAuthState] = useState(false);
  const user = Pool.getCurrentUser();

  const authProps = {
    isAuthenticated,
    setAuthState,
    user
  };

  useEffect(() => {
      if(user) {
        setAuthState(true);  
      }
      else {
        setAuthState(false);
      }
  }, [user]);

  return (
    <Account>
      <BrowserRouter>
        <div style={{display: 'flex'}}>
          {isAuthenticated && <Sidebar auth={authProps}/>}
          <Route exact path='/' render={(props)=> <Login {...props} auth={authProps}/>}></Route>
          <Route exact path='/forgotPass' render={(props)=><ForgotPassword {...props}/>}></Route>
          {isAuthenticated &&
              <>
                <Route exact path='/home' render={(props) => <Dashboard {...props} auth={authProps} user={user}/>}/>
                <Route exact path='/map' render={(props) => <MapDashboard {...props} auth={authProps} user={user}/>}/>
                <Route path='/map/:id' render={(props) => <MapDashboard {...props} auth={authProps} user={user}/>}/>
                <Route exact path='/insight' render={(props) => <Cases {...props} auth={authProps}/>}/>
              </>
          }
        </div>
      </BrowserRouter>
    </Account>
  );
}
