export default function Ownership({ tanker, companies, setSelected }) {

    const selectCompany = (e) => {
        if(!e.target.innerText.includes("Unknown")) {
            if(companies.find((company) => company.name.includes(e.target.innerText)))
                setSelected({type: 'company', company: companies.find((company) => company.name.includes(e.target.innerText))});
        }
    }

    return (
        <div className='tanker-ownership' style={{ backgroundColor: '#111723', minHeight: '325px', maxHeight: '325px', overflowY: 'scroll' }}>
            <div className='tanker-ownership-header'>Ownership Tree</div>
            <div className='tanker-ownership-tree'>
                <div className="tanker-ownership-tree-tree">
                    <div className="tanker-ownership-tree-tree-row">
                        <div className="tanker-ownership-tree-tree-row-single">
                            <div className="tanker-ownership-tree-tree-row-name">Group Owner</div>
                            <div className="tanker-ownership-tree-tree-row-value" style={tanker.group_ben_owner === '  Unknown' || tanker.group_ben_owner === 'Unknown' || !tanker.group_ben_owner ? {color: '#d65151'} : {}} onClick={selectCompany}>
                                {tanker.group_ben_owner ? tanker.group_ben_owner : 'Unknown'}
                            </div>
                            <div className="tanker-ownership-tree-tree-row-next"/>
                        </div>
                    </div>
                    <div className="tanker-ownership-tree-tree-line"/>
                    <div className="tanker-ownership-tree-tree-row">
                        <div className="tanker-ownership-tree-tree-row-single">
                            <div className="tanker-ownership-tree-tree-row-next"/>
                            <div className="tanker-ownership-tree-tree-row-name">Registered Owner</div>
                            <div className="tanker-ownership-tree-tree-row-value" style={tanker.registered_owner === '  Unknown' || tanker.registered_owner === 'Unknown' || !tanker.registered_owner ? {color: '#d65151'} : {}} onClick={selectCompany}>{tanker.registered_owner ? tanker.registered_owner : 'Unknown'}</div>
                            <div className="tanker-ownership-tree-tree-row-next"/>
                        </div>
                        <div className="tanker-ownership-tree-tree-row-single">
                            <div className="tanker-ownership-tree-tree-row-next"/>
                            <div className="tanker-ownership-tree-tree-row-name">Ship Manager</div>
                            <div className="tanker-ownership-tree-tree-row-value" style={tanker.ship_manager === '  Unknown' || tanker.ship_manager === 'Unknown' || !tanker.ship_manager ? {color: '#d65151'} : {}} onClick={selectCompany}>{tanker.ship_manager ? tanker.ship_manager : 'Unknown'}</div>
                        </div>
                        <div className="tanker-ownership-tree-tree-row-single">
                            <div className="tanker-ownership-tree-tree-row-next"/>
                            <div className="tanker-ownership-tree-tree-row-name">Charterer</div>
                            <div className="tanker-ownership-tree-tree-row-value" style={tanker.charterer === '  Unknown' || tanker.charterer === 'Unknown' || !tanker.charterer ? {color: '#d65151'} : {}} onClick={selectCompany}>{tanker.charterer ? tanker.charterer : 'Unknown'}</div>
                        </div>
                    </div>
                    <div className="tanker-ownership-tree-tree-row">
                        <div className="tanker-ownership-tree-tree-row-single" style={{flexDirection: 'row', alignItems: 'flex-start'}}>
                        </div>
                        <div className="tanker-ownership-tree-tree-row-single">
                            <div className="tanker-ownership-tree-tree-row-next"/>
                        </div>
                        <div className="tanker-ownership-tree-tree-row-single"/>
                    </div>
                    <div className="tanker-ownership-tree-tree-row">
                        <div className="tanker-ownership-tree-tree-row-double" style={{marginLeft: '-1rem'}}>
                            <div className="tanker-ownership-tree-tree-row-double-left">
                                <div className="tanker-ownership-tree-tree-row-single" >
                                    <div className="tanker-ownership-tree-tree-row-name">P&I Club</div>
                                    <div className="tanker-ownership-tree-tree-row-value" style={tanker.pandi_club === '  Unknown' || tanker.pandi_club === 'Unknown' || !tanker.pandi_club ? {color: '#d65151'} : {}} onClick={selectCompany}>{tanker.pandi_club ? tanker.pandi_club : 'Unknown'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="tanker-ownership-tree-tree-row-single" style={{minWidth: 'auto'}}>
                            <div className="tanker-ownership-tree-tree-row-name">Registered Operator</div>
                            <div className="tanker-ownership-tree-tree-row-value" style={tanker.operator === '  Unknown' || tanker.operator === 'Unknown' || !tanker.operator ? {color: '#d65151'} : {}} onClick={selectCompany}>{tanker.operator ? tanker.operator : 'Unknown'}</div>
                        </div>
                        <div className="tanker-ownership-tree-tree-row-single"></div>
                    </div>
                    <div className="tanker-ownership-tree-tree-row">
                        <div className="tanker-ownership-tree-tree-row-single">
                            <div className="tanker-ownership-tree-tree-row-next"/>
                        </div>
                    </div>
                    <div className="tanker-ownership-tree-tree-row">
                        <div className="tanker-ownership-tree-tree-row-single" style={{minWidth: 'auto'}}>
                            <div className="tanker-ownership-tree-tree-row-name">Technical Manager</div>
                            <div className="tanker-ownership-tree-tree-row-value" style={tanker.technical_mgr === '  Unknown' || tanker.technical_mgr === 'Unknown' || !tanker.technical_mgr ? {color: '#d65151'} : {}} onClick={selectCompany}>{tanker.technical_mgr ? tanker.technical_mgr : 'Unknown'}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 