import { useEffect, useState } from "react";
import { XYPlot, YAxis, XAxis, VerticalGridLines, HorizontalGridLines, VerticalBarSeries, makeVisFlexible, LabelSeries, RadialChart } from 'react-vis/dist';
import { Box } from "@mui/system";
import { LinearProgress } from "@mui/material";

export default function Bar(props) {
    const AssetValues = makeVisFlexible(XYPlot);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if(props.companies.length > 0) {
            
            setData(
                props.companies.sort((x,y) => y.value - x.value).map((company) => (
                    {x: company.name, y: company.value ? company.value : 0, color: company.value ? company.value : 0}
                )).splice(0,4)
            );
    
            setLoading(false);
        }
    }, [props.companies]);

    //Loading Bar
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 95) {
                    return 95;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 95);
            });
            }, 500);
        
            return () => {
                clearInterval(timer);
            };
    }, []);

    return (
        <>
            {
                loading ?
                        <div className='map-loading-container' style={loading ? {display: 'flex'} : {display: 'none'}}>
                            <div className='map-loading-container-logo'/>
                            <Box sx={{ width: '150px' }}>
                                <LinearProgress variant="determinate" value={progress} />
                            </Box>
                        </div>
                        :
                        <>
                            <div className='landing-left-values-assets'>
                                <div className='landing-left-values-title'>Asset Value of Most Tainted Entities (Millions)</div>
                                <div className='landing-left-values-bar'>
                                    <AssetValues xType="ordinal" height={300} width={400} sx={{padding: '1rem'}}>
                                        <YAxis 
                                            style={{text: {fill: 'white', marginLeft: '1rem'}}}
                                        />
                                        <XAxis 
                                            style={{text: {fill: 'white'}}} 
                                            tickFormat={d => {
                                                return d.length > 8 ? `${d.substring(0, 8)}...` : d; 
                                            }}
                                        />
                                        <HorizontalGridLines style={{stroke: '#b3b3b325', strokeWidth: 1}}/>
                                            <VerticalGridLines style={{stroke: '#b3b3b325', strokeWidth: 1}}/>
                                        <VerticalBarSeries
                                            stroke={'white'}
                                            opacity={0.6}
                                            style={{border: '2px solid white'}}
                                            //bo={'white'}
                                            colorRange={['#ff7d31', '#ff0000']} 
                                            data={data}
                                            onValueClick={(datapoint, event)=>{
                                                props.setSelected({type: 'company', company: props.companies.find((company) => company.name === datapoint.x)})
                                            }}
                                        />
                                        <LabelSeries data={
                                            data.map((company) => ({
                                                x: company.value,
                                                y: company.name
                                            }))
                                        } 
                                        getLabel={d => d.score} />
                                    </AssetValues> 
                                </div>
                            </div>
                            <div className='landing-left-values-trackers'>
                                <div className='landing-left-values-trackers-single'>
                                    <RadialChart
                                        height={150}
                                        width={150}
                                        radius={70}
                                        innerRadius={65}
                                        data={[{color: '#26BC70', angle: .40}, {color: 'gray', angle: .60}]}
                                        animation={true}
                                        colorType='literal'
                                    />
                                    <div className='landing-left-values-trackers-single-text'>
                                        <div className='landing-left-values-trackers-single-value'>9.9 M</div>
                                        <div className='landing-left-values-trackers-single-title'>Sanctioned Oil</div>
                                        <div className='landing-left-values-trackers-single-title'>(Barrels)</div>
                                    </div>
                                </div>
                                <div className='landing-left-values-trackers-single'>
                                    <RadialChart
                                        height={150}
                                        width={150}
                                        radius={70}
                                        innerRadius={65}
                                        data={[{color: '#dce739', angle: (Array.from(props.tankers.values()).filter((tanker) => tanker.score > 24).length/Array.from(props.tankers.values()).length)}, {color: 'gray', angle: 1}]}
                                        animation={true}
                                        colorType='literal'
                                    />
                                    <div className='landing-left-values-trackers-single-text'>
                                        <div className='landing-left-values-trackers-single-value'>{Array.from(props.tankers.values()).filter((tanker) => tanker.score > 24).length}</div>
                                        <div className='landing-left-values-trackers-single-title'>Tainted Tankers</div>
                                    </div>
                                </div>
                                <div className='landing-left-values-trackers-single'>
                                    <RadialChart
                                        height={150}
                                        width={150}
                                        radius={70}
                                        innerRadius={65}
                                        data={[{color: '#ff0000', angle: .40}, {color: 'gray', angle: .60}]}
                                        animation={true}
                                        colorType='literal'
                                    />
                                    <div className='landing-left-values-trackers-single-text'>
                                        <div className='landing-left-values-trackers-single-value'>149</div>
                                        <div className='landing-left-values-trackers-single-title'>Dark Activity</div>
                                        <div className='landing-left-values-trackers-single-title'>(Tankers)</div>
                                    </div>
                                </div>
                                <div className='landing-left-values-trackers-single'>
                                    <RadialChart
                                        height={150}
                                        width={150}
                                        radius={70}
                                        innerRadius={65}
                                        data={[{color: '#9939e7', angle: .9}, {color: 'gray', angle: .60}]}
                                        animation={true}
                                        colorType='literal'
                                    />
                                    <div className='landing-left-values-trackers-single-text'>
                                        <div className='landing-left-values-trackers-single-value'>107</div>
                                        <div className='landing-left-values-trackers-single-title' style={{textAlign: 'center'}}>Satellite Image Detections</div>
                                    </div>
                                </div>
                            </div>
                        </>
            } 
        </>              
    );
}
 