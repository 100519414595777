import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { LinearProgress } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

export default function Overview(props) {
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);
    const [tab, setTab] = useState(0);

    useEffect(() => {
        if(props.companies.length > 0) {
            setLoading(false);
        }
    }, [props.companies]);

    //Loading Bar
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 95) {
                    return 95;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 95);
            });
            }, 500);
        
            return () => {
                clearInterval(timer);
            };
    }, []);

    const tabSwitch = (value) => {
        switch(value) {
            case 0: return <>
                {
                    props.companies.sort((x, y) => Math.ceil(y.score/y.associations.length) - Math.ceil(x.score/x.associations.length)).filter((company) => !company.name.includes('Unknown')).map((company, index) => (
                        <div key={index} className='mapDash-panel-tabs-search-results-list-single' style={{padding: '0px'}}>
                            <div className='mapDash-panel-tabs-search-results-list-single-left'>
                                <div className='mapDash-panel-tabs-search-results-list-single-left-title'>
                                    <div className='mapDash-panel-tabs-search-results-list-single-left-title-name' onClick={() => {
                                        props.setSelected({type: 'company', company: company});
                                    }}>{company.name}</div>
                                    <div className='mapDash-panel-tabs-search-results-list-single-left-title-score' style={!company.score ? {backgroundColor: 'rgba(255, 255, 255, 0.3)', border: '1px solid white'} : Math.ceil(company.score/company.associations.length) > 60 ? {backgroundColor: 'rgba(186, 0, 13, 0.3)', border: '1px solid #BA000D'} : {backgroundColor: 'rgba(236, 202, 0, 0.3)', border: '1px solid #ecca00'}}>{company.score ? `${Math.ceil(company.score/company.associations.length)}%` : '?'}</div>
                                </div>
                                <div className='mapDash-panel-tabs-search-results-list-single-left-attributes'>
                                    <div>Tanker associations: {company.tankers.length}</div>
                                </div>
                            </div>
                            <div className='mapDash-panel-tabs-search-results-list-single-right' style={{justifyContent: 'flex-end', overflow: 'hidden', paddingRight: '0.6rem'}}>
                                <div className='mapDash-panel-tabs-search-results-list-single-right-view' onClick={() => {
                                    props.setSelected({type: 'company', company: company});
                                }}/>
                            </div>
                        </div>
                    ))
                }
            </>
        }
    }
 
    return (
        <div className='landing-right-report'>
            {loading ?
                <div className='map-loading-container' style={loading ? {display: 'flex'} : {display: 'none'}}>
                    <div className='map-loading-container-logo'/>
                    <Box sx={{ width: '150px' }}>
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
                </div>
                :
                <>
                    <FormControl variant="standard" sx={{ minWidth: 200 }}>
                        <InputLabel id="demo-simple-select-standard-label" style={{color: 'white'}}>OFAC Requirements</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            defaultValue={tab}
                            value={tab}
                            onChange={(e) => {
                                setTab(e.target.value);
                            }}
                            sx={{
                                color: 'white',
                                "& .MuiSelect-select": {
                                    borderBottom: '2px solid white'
                                },
                                "& .MuiSvgIcon-root": {
                                    color: '#29CB97'
                                }
                            }}
                        >
                            <MenuItem key={0} value={0}>Identify Non-Compliant Entities</MenuItem>
                            {/* <MenuItem key={1} value={1}>Companies supporting STS operations</MenuItem>
                            <MenuItem key={2} value={2}>Maintain Compliance Scoring Matrix</MenuItem> */}
                        </Select>
                    </FormControl>
                    <div className='landing-right-report-tab'>
                        {tabSwitch(tab)}
                    </div>
                </>
            }
        </div>
    );
}
 