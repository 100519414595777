import './Cases.scss';
import { useRef, useEffect, useState, useContext } from 'react';
import { loadModules } from 'esri-loader';
import ModalImage from 'react-modal-image';
import sar from '../../assets/images/newlegend.png';
import eo from '../../assets/images/newlegend2.png';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
 
export default function MapDashboard(props) {
    const MapEl = useRef();
    const sliderEl = useRef();
    const view = useRef();
    const map = useRef();

    const layer0 = useRef();
    const layer1 = useRef();
    const layer2 = useRef();
    const layer3 = useRef();
    const layer4 = useRef();
    const layer5 = useRef();
    const layer6 = useRef();

    const [image, setImage] = useState(6);

    useEffect(() => {
        if(typeof(image) === 'string') {
            switch(parseInt(image)) {
                case 0: 
                    layer0.current.visible = true;
                    layer1.current.visible = false;
                    layer2.current.visible = false;
                    layer3.current.visible = false;
                    layer4.current.visible = false;
                    layer5.current.visible = false;
                    layer6.current.visible = false;
                    break;
                case 1: 
                    layer0.current.visible = false;
                    layer1.current.visible = true;
                    layer2.current.visible = false;
                    layer3.current.visible = false;
                    layer4.current.visible = false;
                    layer5.current.visible = false;
                    layer6.current.visible = false;
                    break;
                case 2: 
                    layer0.current.visible = false;
                    layer1.current.visible = false;
                    layer2.current.visible = true;
                    layer3.current.visible = false;
                    layer4.current.visible = false;
                    layer5.current.visible = false;
                    layer6.current.visible = false;
                    break;
                case 3: 
                    layer0.current.visible = false;
                    layer1.current.visible = false;
                    layer2.current.visible = false;
                    layer3.current.visible = true;
                    layer4.current.visible = false;
                    layer5.current.visible = false;
                    layer6.current.visible = false;
                    break;
                case 4: 
                    layer0.current.visible = false;
                    layer1.current.visible = false;
                    layer2.current.visible = false;
                    layer3.current.visible = false;
                    layer4.current.visible = true;
                    layer5.current.visible = false;
                    layer6.current.visible = false;
                    break;
                case 5: 
                    layer0.current.visible = false;
                    layer1.current.visible = false;
                    layer2.current.visible = false;
                    layer3.current.visible = false;
                    layer4.current.visible = false;
                    layer5.current.visible = true;
                    layer6.current.visible = false;
                    break;
                case 6: 
                    layer0.current.visible = false;
                    layer1.current.visible = false;
                    layer2.current.visible = false;
                    layer3.current.visible = false;
                    layer4.current.visible = false;
                    layer5.current.visible = false;
                    layer6.current.visible = true;
                    break;
            }
        }
    }, [image])

    useEffect(() => {
        loadModules(["esri/Map","esri/layers/FeatureLayer","esri/views/MapView", "esri/layers/ImageryLayer", "esri/widgets/TimeSlider"],{css: true})
        .then(([Map, FeatureLayer, MapView, ImageryLayer, TimeSlider]) => {
            map.current = new Map({
                basemap: 'satellite',
            });

            view.current = new MapView({
                container: MapEl.current,
                center: [28.965, 43.965],
                zoom: 15,
                map: map.current
            });

            layer0.current = new ImageryLayer({
                id: 'Satellite Layer',
                url: "https://www.seimageserver.com:6443/arcgis/rest/services/Sensors/New_Legend_10_4_2022/ImageServer",
                visible: false
            });
            map.current.add(layer0.current);

            layer1.current = new ImageryLayer({
                id: 'Satellite Layer',
                url: "https://www.seimageserver.com:6443/arcgis/rest/services/Sensors/New_Legend_10_11_2022/ImageServer",
                visible: false
            });
            map.current.add(layer1.current);

            layer2.current = new ImageryLayer({
                id: 'Satellite Layer',
                url: "https://www.seimageserver.com:6443/arcgis/rest/services/Sensors/New_Legend_10_20_2022/ImageServer",
                visible: false
            });
            map.current.add(layer2.current);

            layer3.current = new ImageryLayer({
                id: 'Satellite Layer',
                url: "https://www.seimageserver.com:6443/arcgis/rest/services/Sensors/New_Legend_10_21_2022/ImageServer",
                visible: false
            });
            map.current.add(layer3.current);

            layer4.current = new ImageryLayer({
                id: 'Satellite Layer',
                url: "https://www.seimageserver.com:6443/arcgis/rest/services/Sensors/New_Legend_10_24_2022/ImageServer",
                visible: false
            });
            map.current.add(layer4.current);

            layer5.current = new ImageryLayer({
                id: 'Satellite Layer',
                url: "https://www.seimageserver.com:6443/arcgis/rest/services/Sensors/New_Legend_10_29_2022/ImageServer",
                visible: false
            });
            map.current.add(layer5.current);

            layer6.current = new ImageryLayer({
                id: 'Satellite Layer',
                url: "https://www.seimageserver.com:6443/arcgis/rest/services/Sensors/New_Legend_10_30_2022/ImageServer",
            });
            map.current.add(layer6.current);

            const label = {
                symbol: {
                  type: "text", 
                  color: "black"
                },
                labelPlacement: "above-center",
                labelExpressionInfo: {
                  expression: "$feature.mmsi"
                }
            };

            const mmtiTimeSliderLayer = new FeatureLayer({
                url: `${process.env.REACT_APP_DS}/Other/New_Legend_09_20_to_10_12_Points_Time/MapServer/0`,
                id: "Slider",
                name: "Slider",
                title: "Slider",
                //labelingInfo: [label]
            });
            map.current.add(mmtiTimeSliderLayer);

            const timeSlider = new TimeSlider({
                container: sliderEl.current,
                view: view.current,
                layout: 'wide',
                timeVisible: true,
                loop: true,
            });
        
            view.current.whenLayerView(mmtiTimeSliderLayer).then((lv) => {
                timeSlider.fullTimeExtent = mmtiTimeSliderLayer.timeInfo.fullTimeExtent;
                timeSlider.stops = {
                    count: 40,
                };
            });
            
        }).catch(err => console.log(err));
    }, []);

    return (
      <div className='cases'>
        <div className='cases-map' ref={MapEl}>
            <div className='cases-toggles'>
                <div className='cases-toggles-title'>Current Map Image</div>
                <RadioGroup
                    value={image}
                    onChange={(e) => setImage(e.target.value)}
                >
                    <FormControlLabel value={0} control={<Radio />} label="10/04/2022" />
                    <FormControlLabel value={1} control={<Radio />} label="10/11/2022" />
                    <FormControlLabel value={2} control={<Radio />} label="10/20/2022" />
                    <FormControlLabel value={3} control={<Radio />} label="10/21/2022" />
                    <FormControlLabel value={4} control={<Radio />} label="10/24/2022" />
                    <FormControlLabel value={5} control={<Radio />} label="10/29/2022" />
                    <FormControlLabel value={6} control={<Radio />} label="10/30/2022" />
                </RadioGroup>
            </div>
            <div className='cases-panel'>
                <div className='cases-panel-title'>NEW LEGEND</div>
                <div className='cases-panel-profile'/>
                <div className='cases-panel-attributes'>
                    <div className='cases-panel-attributes-field'>
                        MMSI
                    </div>
                    <div className='cases-panel-attributes-value'>
                        636017774
                    </div>
                    <div className='cases-panel-attributes-field'>
                        IMO
                    </div>
                    <div className='cases-panel-attributes-value'>
                        9230505
                    </div>
                </div>
                <div className='cases-panel-header'>Satellite Imagery</div>
                <div className='cases-panel-image'>
                    <ModalImage
                        small={sar}
                        large={sar}
                        showRotate={true}
                    />
                </div>
                <div className='cases-panel-image'>
                    <ModalImage
                        small={eo}
                        large={eo}
                        showRotate={true}
                    />
                </div>
                <div className='cases-panel-header'>Vessels Legend</div>
                <div className='cases-panel-legend'>
                    <div className='mapDash-panel-tabs-map-layers-single-top' style={{marginBottom: '0.75rem'}}>
                        <div className='cases-panel-legend-symbol-1'/>
                        <div className='mapDash-panel-tabs-map-layers-single-text'>NEW LEGEND</div>
                    </div>
                    <div className='mapDash-panel-tabs-map-layers-single-top' style={{marginBottom: '0.75rem'}}>
                        <div className='cases-panel-legend-symbol-4'/>
                        <div className='mapDash-panel-tabs-map-layers-single-text'>NOSTOS</div>
                    </div>
                    <div className='mapDash-panel-tabs-map-layers-single-top' style={{marginBottom: '0.75rem'}}>
                        <div className='cases-panel-legend-symbol-2'/>
                        <div className='mapDash-panel-tabs-map-layers-single-text'>VULKAN (Tug)</div>
                    </div>
                    <div className='mapDash-panel-tabs-map-layers-single-top'>
                        <div className='cases-panel-legend-symbol-3'/>
                        <div className='mapDash-panel-tabs-map-layers-single-text'>ADARA</div>
                    </div>
                </div>
            </div>
            <div className='cases-slider'>
                <div style={{width: '100%'}} ref={sliderEl}></div>
            </div>
        </div>
      </div>
    );
}
 