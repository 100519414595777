import './Map.scss';
import { useRef, useEffect, useState, useContext } from 'react';
import { loadModules } from 'esri-loader';
import axios from 'axios';
import Search from './Search';
import { Box } from '@mui/system';
import { LinearProgress } from '@mui/material';
import track from '../../assets/icons/track.png';
import caution from '../../assets/icons/caution.png';
import warning from '../../assets/icons/warning.png';
import Slider from '@mui/material/Slider';
import Tanker from './Tanker';
import Insight from './Insight';
import AI from './AI';
import InsightContent from './InsightContent';
import MapLayers from './MapLayers';
import { images } from './satellite/images';
import Company from './Company';
 
export default function MapDashboard(props) {
    const MapEl = useRef();
    const view = useRef();
    const map = useRef();
    const tankersLayer = useRef();
    const heatmap = useRef();
    const fusionLayer = useRef();
    const gapsLayer = useRef();
    const latestPoint = useRef();

    const [tankers, setTankers] = useState([]);
    const [selected, setSelected] = useState();
    const [companies, setCompanies] = useState([]);
    const [gaps, setGaps] = useState([]);

    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);

    const [tab, setTab] = useState(0);
    const [expand, setExpand] = useState(false);
    const [showTrack, setShowTrack] = useState(false);

    const panel = useRef();
    const exitTrack = useRef();
    const mapLoading = useRef();

    //Select input tanker
    useEffect(() => {
        if(tankers.length > 0 && props.match.params.id) {
            setExpand(true);
            setSelected({type: 'tanker', tanker: tankers.find((tanker) => tanker.attributes.mmsi.toString() === props.match.params.id)});
        }
    }, [tankers]);

    //Loading Bar
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 95) {
                    return 95;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 95);
            });
            }, 500);
        
            return () => {
                clearInterval(timer);
            };
    }, []);

    //Create Map and fetch tankers from GeoEvent
    useEffect(() => {
        const tankersMap = new Map();
        const companiesMap = new Map();

        loadModules(["esri/Map", "esri/views/MapView", "esri/widgets/Home", "esri/layers/GraphicsLayer",  "esri/layers/FeatureLayer", "esri/layers/ImageryLayer", "esri/Graphic"],{css: true})
        .then(([Map, MapView, Home, GraphicsLayer, FeatureLayer, ImageryLayer, Graphic]) => {
            map.current = new Map({
                basemap: 'streets-night-vector',
            });

            view.current = new MapView({
                container: MapEl.current,
                center: [34, 43.5],
                zoom: 6,
                map: map.current
            });

            images.forEach((image) => {
                const layer = new ImageryLayer({
                    id: 'Satellite Layer',
                    url: image.url
                });
                layer.visible = false;
                map.current.add(layer);
            });

            const date = new Date();
            date.setDate(date.getDate() - 90);
            const today = new Date();
            const range = [`${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()} ${date.toString().substring(16, 24)}`, 
            `${today.getUTCFullYear()}-${today.getUTCMonth() + 1}-${today.getUTCDate()} ${today.toString().substring(16, 24)}`];

            axios.post(`${process.env.REACT_APP_SS_API}/products/rendezvous`, {
                timestamp_utc_start: "2023-01-01 00:00:00",
                timestamp_utc_end: "2023-01-26 00:00:00",
                area: "POLYGON((33.521485179662704 46.25280813615305,31.333007812499996 46.83915261889874,29.601562768220898 45.81961215095754,28.2832033932209 44.656150096240026,27.237305492162704 42.679204500008495,27.1054695546627 42.15363034947012,27.984375804662704 41.47236726916651,26.252929754555225 40.15368685779404,30.177245959639553 40.32477036863642,33.8203127682209 41.88755654335017,38.724608302116394 40.520480498848656,41.976561427116394 41.33062590401016,42.064452052116394 42.83086160869709,37.441407322883606 45.21919473580297,36.717581447495476 45.492080557521234,35.380330545046334 45.27107843839329,33.521485179662704 46.25280813615305))",
                pagination: {
                    page: 1,
                    per_page: 10000
                }
            })
            .then((response) => {
                console.log(response);
                const renderer = {
                    type: "heatmap",
                    colorStops: [
                        { color: "rgba(63, 40, 102, 0)", ratio: 0 },
                        { color: "#472b77", ratio: 0.083 },
                        { color: "#4e2d87", ratio: 0.166 },
                        { color: "#563098", ratio: 0.249 },
                        { color: "#5d32a8", ratio: 0.332 },
                        { color: "#6735be", ratio: 0.415 },
                        { color: "#7139d4", ratio: 0.498 },
                        { color: "#7b3ce9", ratio: 0.581 },
                        { color: "#853fff", ratio: 0.664 },
                        { color: "#a46fbf", ratio: 0.747 },
                        { color: "#c29f80", ratio: 0.83 },
                        { color: "#e0cf40", ratio: 0.913 },
                        { color: "#e0cf40", ratio: 1 }
                    ],
                    maxDensity: 0.01,
                    minDensity: 0
                };
        
                heatmap.current = new FeatureLayer({
                    title: "Rendezvous Heat Map",
                    source: response.data.data.map((row, index) => {
                        return {
                            attributes: {...row, objectid: index},
                            geometry: {
                                type: 'point',
                                latitude: row.longitude,
                                longitude: row.latitude,
                                x: row.longitude,
                                y: row.latitude
                            }
                        }
                    }),
                    renderer: renderer,
                    fields: [
                        {name: "objectid", type: "oid"},
                        {name: "other_vessel_name", type: "string"},
                        {name: "other_mmsi", type: "string"},
                        {name: "course_over_ground", type: "single"},
                        {name: "latitude", type: "double"},
                        {name: "longitude", type: "double"},
                        {name: "timestamp_utc", type: "string"},
                        {name: "mmsi", type: "string"},
                        {name: "speed_over_ground", type: "integer"},
                        {name: "swarm", type: "integer"},
                        {name: "swarm_count", type: "integer"},
                        {name: "time_in_rendezvous", type: "double"},
                        {name: "uid", type: "string"},
                    ],
                    visible: false
                });
                map.current.add(heatmap.current);

                // gapsLayer.current = new GraphicsLayer({
                //     id: 'Gaps Layer'
                // });
                // map.current.add(gapsLayer.current);

            }).catch((err) => {
                console.log(err);
            });

            axios.post(`${process.env.REACT_APP_SS_API}/products/sensor-fusion`, {
                timestamp_utc_start: "2022-07-01 00:00:00",
                timestamp_utc_end: "2022-08-31 00:00:00",
                area_of_interest: "POLYGON((33.521485179662704 46.25280813615305,31.333007812499996 46.83915261889874,29.601562768220898 45.81961215095754,28.2832033932209 44.656150096240026,27.237305492162704 42.679204500008495,27.1054695546627 42.15363034947012,27.984375804662704 41.47236726916651,26.252929754555225 40.15368685779404,30.177245959639553 40.32477036863642,33.8203127682209 41.88755654335017,38.724608302116394 40.520480498848656,41.976561427116394 41.33062590401016,42.064452052116394 42.83086160869709,37.441407322883606 45.21919473580297,36.717581447495476 45.492080557521234,35.380330545046334 45.27107843839329,33.521485179662704 46.25280813615305))",
                sensor_type_1: ['rf']
            })
            .then((response) => {
                fusionLayer.current = new GraphicsLayer({
                    id: "Sensor Fusion Layer",
                    visible: false
                });
                map.current.add(fusionLayer.current);

                const aisPointSymbol = {
                    type: "simple-marker",
                    color: 'red'
                };

                const rfPointSymbol = {
                    type: "simple-marker",
                    color: '#5c82ed'
                };

                const rfPopup = {
                    title: "{sensor_type_1}",
                    content: [
                        {
                            type: "fields",
                            fieldInfos: [
                                {
                                    fieldName: "data_provider",
                                    label: "Data Provider"
                                },
                                {
                                    fieldName: "frequency",
                                    label: "Frequency"
                                },
                                {
                                    fieldName: "pulse_duration",
                                    label: "Pulse Duration"
                                },
                                {
                                    fieldName: "pulse_repetition_frequency",
                                    label: "Pulse Repetition Frequency"
                                },
                                {
                                    fieldName: "latitude",
                                    label: "Latitude"
                                },
                                {
                                    fieldName: "longitude",
                                    label: "Longitude"
                                },
                                {
                                    fieldName: "distance",
                                    label: "Distance from AIS (meters)"
                                },
                                {
                                    fieldName: "timestamp_utc",
                                    label: "Timestamp (UTC)"
                                }
                            ]
                        }
                    ]
                }

                const aisPopup = {
                    title: "{other_sensor_type_1}",
                    content: [
                        {
                            type: "fields",
                            fieldInfos: [
                                {
                                    fieldName: "other_data_provider",
                                    label: "Data Provider"
                                },
                                {
                                    fieldName: "mmsi",
                                    label: "MMSI"
                                },
                                {
                                    fieldName: "other_latitude",
                                    label: "Latitude"
                                },
                                {
                                    fieldName: "other_longitude",
                                    label: "Longitude"
                                },
                                {
                                    fieldName: "distance",
                                    label: "Distance from RF (meters)"
                                },
                                {
                                    fieldName: "other_timestamp_utc",
                                    label: "Timestamp (UTC)"
                                }
                            ]
                        }
                    ]
                }

                response.data.results.forEach((detection) => {
                    const line = {
                        type: "polyline",
                        paths: [
                            [detection.other_longitude, detection.other_latitude],
                            [detection.longitude, detection.latitude]
                        ]
                    };
        
                    const lineSymbol = {
                            type: "simple-line",
                            color: 'white',
                            width: 2
                    };
                          
                    const lineGraphic = new Graphic({
                        geometry: line,
                        symbol: lineSymbol,
                    });

                    const aisPoint = {
                        type: "point",
                        longitude: detection.other_longitude,
                        latitude: detection.other_latitude
                    };
                    
                    const aisGraphic = new Graphic({
                        attributes: {
                            ...detection
                        },
                        geometry: aisPoint,
                        popupTemplate: aisPopup,
                        symbol: aisPointSymbol
                    });
        
                    const rfPoint = {
                        type: "point",
                        longitude: detection.longitude,
                        latitude: detection.latitude
                    };
                    
                    const rfGraphic = new Graphic({
                        attributes: {
                            ...detection
                        },
                        geometry: rfPoint,
                        popupTemplate: rfPopup,
                        symbol: rfPointSymbol
                    });
        
                    fusionLayer.current.addMany([lineGraphic, aisGraphic, rfGraphic]);
                });
    
            }).catch((err) => {
                console.log(err);
            });

            view.current.popup.on("trigger-action", (event) => {
                if (event.action.id === "get-track-history") {
                    const date = new Date();
                    date.setDate(date.getDate() - 7);
                    //setSelected({type: 'tanker', tanker: tankers.find((tanker) => tanker.attributes.mmsi === view.current.popup.viewModel.selectedFeature.attributes.mmsi)});
                    showTrackHistory([date.getTime(), new Date().getTime()], tankers.find((tanker) => tanker.attributes.mmsi === view.current.popup.viewModel.selectedFeature.attributes.mmsi));
                }
            });

            const homeWidget = new Home({
                view: view.current
            });
            view.current.ui.add(homeWidget, "top-left");

            const getTrackHistory = {
                title: "Track History",
                id: "get-track-history",
                image: track
            };

            const vesselTemplate = {
                title: "{vessel_name}",
                content: [
                    {
                        type: "fields",
                        fieldInfos: [
                            {
                                fieldName: "mmsi",
                                label: "MMSI"
                            },
                            {
                                fieldName: "imo",
                                label: "IMO"
                            },
                            {
                                fieldName: "destination",
                                label: "Destination"
                            },
                            {
                                fieldName: "score",
                                label: "SMART score"
                            },
                            {
                                fieldName: "timestamp",
                                label: "Timestamp (UTC)"
                            }
                        ]
                    }
                ],
                actions: [getTrackHistory]
            }

            const vesselSymbol = {
                type: "simple-marker",
                path: "M20,1 25,20 16,20,0z",
                color: "white",
                outline: {
                    color: 'black',
                    width: 0.5,
                },
                angle: 0,
                size: 14,
            };
            
            const vesselIconRenderer = {
                type: "simple",
                symbol: vesselSymbol,
                visualVariables: [
                {
                    type: "rotation",
                    field: "course_over_ground",
                    rotationType: "geographic",
                },
                ],
            };

            const layer = new FeatureLayer({
                url: `${process.env.REACT_APP_DS}/Hosted/All_Vessels/MapServer/0`,
                definitionExpression: "rules_broken LIKE '%1006i%' AND vessel_type_code BETWEEN 80 AND 89",
                outFields: ["*"],
                refreshInterval: 1,
            });

            layer.queryFeatures({
                where: "rules_broken LIKE '%1006i%' AND vessel_type_code BETWEEN 80 AND 89",
                returnGeometry: true,
                outFields: ["*"],
            })
            .then((featureSet) => {
                featureSet.features.forEach((tanker) => {
                    if(tanker.attributes.imo !== null && tanker.attributes.imo.toString().length === 7) {
                        !tankersMap.has(tanker.attributes.imo) && tankersMap.set(tanker.attributes.imo, tanker);         
                    } else {
                        !tankersMap.has(tanker.attributes.mmsi) && tankersMap.set(tanker.attributes.mmsi, tanker);
                    }
                });

                axios.post(`${process.env.REACT_APP_API}/tankers/attributes`, {
                    imos: featureSet.features.filter((feature) => feature.attributes.imo !== null && feature.attributes.imo.toString().length === 7).map((feature) => feature.attributes.imo)
                }).then((response) => {
                    response.data.forEach((tanker) => {
                        if(tankersMap.has(parseInt(tanker.imo))) {
                            tankersMap.set(parseInt(tanker.imo), {
                                attributes: {
                                    ...tankersMap.get((parseInt(tanker.imo))).attributes,
                                    ...tanker,
                                    rules_broken: [...tanker.rules_broken],
                                },
                                geometry: tankersMap.get(parseInt(tanker.imo)).geometry,
                                layer: tankersMap.get(parseInt(tanker.imo)).layer,
                                popupTemplate: tankersMap.get(parseInt(tanker.imo)).popupTemplate,
                                sourceLayer: tankersMap.get(parseInt(tanker.imo)).sourceLayer,
                                symbol: tankersMap.get(parseInt(tanker.imo)).symbol,
                            })
                        } else {
                            tankersMap.set(parseInt(tanker.imo), {
                                attributes: {
                                    ...tankersMap.get((parseInt(tanker.imo))).attributes,
                                    score: 0,
                                    rules_broken: null,
                                },
                                geometry: tankersMap.get(parseInt(tanker.imo)).geometry,
                                layer: tankersMap.get(parseInt(tanker.imo)).layer,
                                popupTemplate: tankersMap.get(parseInt(tanker.imo)).popupTemplate,
                                sourceLayer: tankersMap.get(parseInt(tanker.imo)).sourceLayer,
                                symbol: tankersMap.get(parseInt(tanker.imo)).symbol,
                            })
                        }
                    });

                    const ESRIfields = layer.fields.map((field) => {
                        return {name: field.name, type: field.type}
                    }).filter((field) => field.name !== 'imo');
                    
                    const SMARTfields = Object.keys(response.data[0]).map((field) => {
                        if(field === 'score') return {name: field, type: 'integer'}

                        return {name: field, type: 'string'}
                    });
                    const fields = [...ESRIfields, ...SMARTfields];
                    setTankers(Array.from(tankersMap.values()));

                    const tankers = Array.from(tankersMap.values());

                    const unknownRisk = {
                        symbol: {
                          type: "text", 
                          color: "white"
                        },
                        labelPlacement: "above-center",
                        labelExpressionInfo: {
                          expression: "$feature.vessel_name"
                        }
                    };

                    const lowRisk = {
                        symbol: {
                            type: "text", 
                            color: "red"
                        },
                        labelExpressionInfo: {
                          expression: "$feature.vessel_name"
                        },
                        labelPlacement: "above-center",
                        where: "score >= 10"
                    };

                    const highRisk = {
                        symbol: {
                            type: "text", 
                            color: "orange"
                        },
                        labelExpressionInfo: {
                            expression: "$feature.vessel_name"
                        },
                        labelPlacement: "above-center",
                        where: "score < 10"
                    };

                    tankersLayer.current = new FeatureLayer({
                        title: 'Tankers Layer',
                        id: 'Tankers Layer',
                        source: tankers,
                        fields: fields,
                        objectIdField: 'objectid',
                        popupTemplate: vesselTemplate,
                        renderer: vesselIconRenderer,
                        labelingInfo: [unknownRisk, lowRisk, highRisk]
                    });
                    map.current.add(tankersLayer.current);

                    const owners = tankers.map((tanker) => {
                        return {
                            name: tanker.attributes.registered_owner,
                            code: tanker.attributes.registered_owner_code,
                            score: Math.ceil((tanker.attributes.score/41) * 100),
                            tanker: tanker.attributes,
                            company_association: 'Registered Owner'
                        }
                    }).filter((owner) => typeof(owner.name) !== 'undefined');
            
                    // const charterers = tankers.map((tanker) => {
                    //     return {
                    //         name: tanker.attributes.charterer,
                    //         score: Math.ceil((tanker.attributes.score/41) * 100),
                    //         tanker: tanker.attributes,
                    //         company_association: 'Charterer'
                    //     }
                    // }).filter((charterer) => typeof(charterer.name) !== 'undefined');
            
                    const groupOwners = tankers.map((tanker) => {
                        return {
                            name: tanker.attributes.group_ben_owner,
                            code: tanker.attributes.group_ben_ownercode,
                            score: Math.ceil((tanker.attributes.score/41) * 100),
                            tanker: tanker.attributes,
                            company_association: 'Group Owner'
                        }
                    }).filter((owner) => typeof(owner.name) !== 'undefined');
            
                    const operators = tankers.map((tanker) => {
                        return {
                            name: tanker.attributes.operator,
                            code: tanker.attributes.operator_comp_code,
                            score: Math.ceil((tanker.attributes.score/41) * 100),
                            tanker: tanker.attributes,
                            company_association: 'Operator'
                        }
                    }).filter((operator) => typeof(operator.name) !== 'undefined');
            
                    const pandiClubs = tankers.map((tanker) => {
                        return {
                            name: tanker.attributes.pandi_club,
                            code: tanker.attributes.pandi_clubcode,
                            score: Math.ceil((tanker.attributes.score/41) * 100),
                            tanker: tanker.attributes,
                            company_association: 'P&I Club'
                        }
                    }).filter((club) => typeof(club.name) !== 'undefined');
            
                    const shipManagers = tankers.map((tanker) => {
                        return {
                            name: tanker.attributes.ship_manager,
                            code: tanker.attributes.ship_manager_companycode,
                            score: Math.ceil((tanker.attributes.score/41) * 100),
                            tanker: tanker.attributes,
                            company_association: 'Fleet Manager'
                        }
                    }).filter((manager) => typeof(manager.name) !== 'undefined');
            
                    const technicalManagers = tankers.map((tanker) => {
                        return {
                            name: tanker.attributes.technical_mgr,
                            code: tanker.attributes.tech_mgr_code,
                            score: Math.ceil((tanker.attributes.score/41) * 100),
                            tanker: tanker.attributes,
                            company_association: 'Technical Manager'
                        }
                    }).filter((manager) => typeof(manager.name) !== 'undefined');
            
                    const companies = [...owners, ...groupOwners, ...operators, ...pandiClubs, ...shipManagers, ...technicalManagers];
                    companies.filter((company) => company.code).forEach((company) => {
                        if(!companiesMap.has(company.code)) {
                            companiesMap.set(company.code, {
                                name: company.name,
                                code: company.code,
                                score: company.score,
                                tankers: [{...company.tanker, company_association: company.company_association}],
                            });
                        } else {
                            const tankers = companiesMap.get((company.code)).tankers;
                            companiesMap.set(company.code, {
                                name: company.name,
                                code: company.code,
                                score: companiesMap.get(company.code).score + company.score,
                                tankers: [...tankers, {...company.tanker, company_association: company.company_association}]
                            })
                        }
                    });

                    setCompanies(
                        Array.from(companiesMap.values()).sort((x, y) => y.score - x.score).map((company, index) => {
                            return {
                                id: index,
                                name: company.name,
                                code: company.code,
                                score: company.score,
                                tankers: company.tankers
                            }
                        })
                    );

                    setLoading(false);
                }).catch((err) => {
                    console.log(err);
                })
            }).catch((err) => {
                console.log(err);
            });
        }).catch(err => console.log(err));
    }, []);

    const showTrackHistory = (range, current) => {
        loadModules(["esri/Graphic", "esri/geometry/Polyline", "esri/symbols/SimpleLineSymbol", "esri/geometry/Circle",  "esri/layers/FeatureLayer", "esri/layers/GraphicsLayer"],{css: true})
        .then(([Graphic, Polyline, SimpleLineSymbol, Circle, FeatureLayer, GraphicsLayer]) => {
            //gapsLayer.current.removeAll();

            gapsLayer.current = new GraphicsLayer({
                id: 'Gaps Layer'
            });
            map.current.add(gapsLayer.current);

            view.current.goTo(view.current.popup.viewModel.selectedFeature);
            view.current.popup.close();
            
            mapLoading.current.style.display = 'inline-block';

            const mmsi = current ? current.attributes.mmsi : selected ? selected.tanker.attributes.mmsi : view.current.popup.viewModel.selectedFeature.attributes.mmsi;

            let tanker;
            tankersLayer.current.source.items.forEach((item) => {
                if(item.attributes.mmsi === mmsi)
                    tanker = item;
            });

            const vesselTemplate = {
                title: "{vessel_name}",
                content: [
                    {
                        type: "fields",
                        fieldInfos: [
                            {
                                fieldName: "mmsi",
                                label: "MMSI"
                            },
                            {
                                fieldName: "imo",
                                label: "IMO"
                            },
                            {
                                fieldName: "charterer",
                                label: "Charterer"
                            },
                            {
                                fieldName: "destination",
                                label: "Destination"
                            },
                            {
                                fieldName: "score",
                                label: "SMART score"
                            },
                            {
                                fieldName: "timestamp",
                                label: "Timestamp (UTC)"
                            }
                        ]
                    }
                ]
            }

            const vesselSymbol = {
                type: "simple-marker",
                path: "M20,1 25,20 16,20,0z",
                color: "white",
                outline: {
                    color: 'black',
                    width: 0.5,
                },
                angle: 0,
                size: 14,
            };
            
            const vesselIconRenderer = {
                type: "simple",
                symbol: vesselSymbol,
                visualVariables: [
                {
                    type: "rotation",
                    field: "course_over_ground",
                    rotationType: "geographic",
                },
                ],
            };

            const unknownRisk = {
                symbol: {
                  type: "text", 
                  color: "white"
                },
                labelPlacement: "above-center",
                labelExpressionInfo: {
                  expression: "$feature.vessel_name"
                }
            };

            const lowRisk = {
                symbol: {
                    type: "text", 
                    color: "red"
                },
                labelExpressionInfo: {
                  expression: "$feature.vessel_name"
                },
                labelPlacement: "above-center",
                where: "score >= 10"
            };

            const highRisk = {
                symbol: {
                    type: "text", 
                    color: "orange"
                },
                labelExpressionInfo: {
                    expression: "$feature.vessel_name"
                },
                labelPlacement: "above-center",
                where: "score < 10"
            };

            latestPoint.current = new FeatureLayer({
                title: 'Latest Point',
                id: 'Latest Point',
                source: [tanker],
                fields: tankersLayer.current.fields,
                objectIdField: 'objectid',
                popupTemplate: vesselTemplate,
                renderer: vesselIconRenderer,
                labelingInfo: [unknownRisk, lowRisk, highRisk]
            });
            map.current.add(latestPoint.current);
            tankersLayer.current.visible = false;

            axios.get(`${process.env.REACT_APP_DS}/Hosted/AisPositionHistory/MapServer/0/query?where=mmsi+%3D+${mmsi}+AND+timestamp+BETWEEN+${range[0]}+AND+${range[1]}&outFields=*&returnGeometry=true&f=pjson`)
            .then((response) => {
                console.log(response);
                axios.get(`${process.env.REACT_APP_DS}/Hosted/UC106_Track_Gap_BDS/MapServer/0/query?where=trackId+%3D+%27${mmsi}%27+AND+lastReceived+BETWEEN+${range[0]}+AND+${range[1]}&outFields=*&f=pjson`)
                .then((res) => {
                    console.log(res)
                    exitTrack.current.style.display = 'flex';
                    const points = response.data.features.map((point) => [point.geometry.x, point.geometry.y]);

                    const path = new Polyline({
                        paths: points,
                    });

                    const lineSymbol = new SimpleLineSymbol({
                        color: [255, 255, 255],
                        width: 0.5
                    });

                    const polylineGraphic = new Graphic({
                        geometry: path,
                        symbol: lineSymbol,
                    });

                    console.log(gapsLayer.current)

                    gapsLayer.current.add(polylineGraphic);

                    const gapsMap = new Map();
                    const timestamps = response.data.features.map((point) => {return {timestamp: point.attributes.timestamp, geometry: point.geometry}}).sort((x, y) => x.timestamp - y.timestamp);
                    res.data.features.forEach((gap) => {
                        const output = timestamps.reduce((prev, curr) => Math.abs(curr.timestamp - gap.attributes.lastReceived) < Math.abs(prev.timestamp - gap.attributes.lastReceived) ? curr : prev);

                        if(timestamps[timestamps.indexOf(output) + 1]) {
                            const start = new Date(output.timestamp);
                            const end = new Date(timestamps[timestamps.indexOf(output) + 1].timestamp);

                            const gapInfo = {
                                startCoordinates: [output.geometry.x, output.geometry.y],
                                startTimestamp: new Date(output.timestamp),
                                lastReceivedCoordinates: [gap.geometry.x, gap.geometry.y],
                                lastReceived: new Date(gap.attributes.lastReceived),
                                endCoordinates: [timestamps[timestamps.indexOf(output) + 1].geometry.x, timestamps[timestamps.indexOf(output) + 1].geometry.y],
                                endTimestamp: new Date(timestamps[timestamps.indexOf(output) + 1].timestamp),
                                gapDuration: Math.abs(end.getTime() - start.getTime()) / (1000 * 60 * 60),
                            }
                            
                            if(!gapsMap.has(output.timestamp))
                                gapsMap.set(output.timestamp, gapInfo);
                            }
                    })

                    const gapsArray = Array.from(gapsMap.values()).filter((gap) => gap.gapDuration >= 8);
                    setGaps(gapsArray);
                    gapsArray.forEach((gap) => {
                        const points = [gap.startCoordinates, gap.endCoordinates];

                        const path = new Polyline({
                            paths: points,
                        });

                        const lineSymbol = new SimpleLineSymbol({
                            color: 'red',
                            width: 3
                        });

                        const polylineGraphic = new Graphic({
                            geometry: path,
                            symbol: lineSymbol,
                        });

                        gapsLayer.current.add(polylineGraphic)

                        const startTemplate = {
                            title: "AIS gap start",
                            content: [
                                {
                                    type: "fields",
                                    fieldInfos: [
                                        {
                                            fieldName: "startTimestamp",
                                            label: "Gap start"
                                        },
                                        {
                                            fieldName: "gapDuration",
                                            label: "Gap duration (hours)"
                                        }
                                    ]
                                }
                            ]
                        }
            
                        const startGraphic = new Graphic({
                            geometry: new Circle({
                                center: gap.startCoordinates,
                            }),
                            symbol: {
                                type: "picture-marker",
                                url: caution,
                                width: "32px",
                                height: "32px"
                            },
                            attributes: gap,
                            popupTemplate: startTemplate
                        });
                        gapsLayer.current.add(startGraphic)

                        const endTemplate = {
                            title: "AIS gap end",
                            content: [
                                {
                                    type: "fields",
                                    fieldInfos: [
                                        {
                                            fieldName: "endTimestamp",
                                            label: "Gap end"
                                        },
                                        {
                                            fieldName: "gapDuration",
                                            label: "Gap duration (hours)"
                                        }
                                    ]
                                }
                            ]
                        }
            
                        const endGraphic = new Graphic({
                            geometry: new Circle({
                                center: gap.endCoordinates
                            }),
                            symbol: {
                                type: "picture-marker",
                                url: warning,
                                width: "32px",
                                height: "32px"
                            },
                            attributes: gap,
                            popupTemplate: endTemplate
                        });
                        gapsLayer.current.add(endGraphic);
                    });

                    view.current.goTo(polylineGraphic);
                    mapLoading.current.style.display = 'none';
                    setShowTrack(true);
                }).catch((err) => {
                    console.log(err)
                    mapLoading.current.style.display = 'none';
                    setShowTrack(true);
                })

            }).catch((err) => console.log(err));

        }).catch((err) => console.log(err))
    }

    const goToTanker = (mmsi) => {
        setSelected({type: 'tanker', tanker: tankers.find((tanker) => tanker.attributes.mmsi === mmsi)});
        tankersLayer.current.queryFeatures({
            where: `mmsi=${mmsi}`,
            outFields: ["*"],
            returnGeometry: true
        }).then((results) => {
            view.current.popup.clear();
            view.current.goTo(results.features[0]);
            view.current.popup.open({
              features: [results.features[0]]
            })
        });
    }

    const goToGap = (value) => {
        let features = [];

        gapsLayer.current.graphics.items.forEach((gap) => {
            if(gap.attributes && (gap.attributes.startTimestamp === value.startTimestamp)) {
                features.push(gap);
            }
        })

        view.current.popup.clear();
        view.current.goTo(features[0]);
        view.current.popup.open({
              features: [features[0]]
        })
    }

    const exitTrackHistory = () => {
        view.current.popup.close();
        setGaps([]);
        setSelected();
        setShowTrack(false);
        exitTrack.current.style.display = 'none';
        tankersLayer.current.visible = true;
        map.current.remove(latestPoint.current);
        gapsLayer.current.removeAll();

        view.current.goTo({
            geometry: [34, 43.5],
            zoom: 6
        });
    }

    const [layers, setLayers] = useState([true, false, false, false]);
    
    const toggleTankersLayer = (value) => {
        tankersLayer.current.visible = value;
    }

    const toggleSatelliteLayer = (value) => {
        if(value) {
            map.current.layers.items.forEach((layer) => {
                if(layer.id === 'Satellite Layer')
                    layer.visible = true;
            })

            view.current.constraints = {
                minZoom: 6,
                maxZoom: 11
            }
        }
        else {
            map.current.layers.items.forEach((layer) => {
                if(layer.id === 'Satellite Layer')
                    layer.visible = false;
            })

            view.current.constraints = {
                minZoom: 6,
                maxZoom: 20
            }
        }
    }

    const toggleHeatMap = (value) => {
        heatmap.current.visible = value;
    }

    const toggleFusionLayer = (value) => {
        fusionLayer.current.visible = value;
    }

    const panelSwitch = (value) => {
        switch(value) {
            case 0:
                return <Search tankers={tankers} companies={companies} setExpand={setExpand} goToTanker={goToTanker} selected={selected} setSelected={setSelected} showTrack={showTrack} gaps={gaps} goToGap={goToGap}/>
            case 1:
                return <MapLayers layers={layers} setLayers={setLayers} toggleTankersLayer={toggleTankersLayer} toggleSatelliteLayer={toggleSatelliteLayer} toggleHeatMap={toggleHeatMap} toggleFusionLayer={toggleFusionLayer}/>
            case 2:
                return <AI/>
                // return <Insight tankers={tankers} companies={companies} setExpand={setExpand} goToTanker={goToTanker} selected={selected} setSelected={setSelected} showTrack={showTrack} gaps={gaps} goToGap={goToGap}/>
        }
    }

    const marks = [
        {
            value: 0,
            label: 'Last 30 days'
        },
        {
            value: 1,
            label: 'Last 14 days'
        },
        {
            value: 2,
            label: 'Last 7 days'
        },

    ];

    useEffect(() => {
        expand ? panel.current.style.width = '100%' : panel.current.style.width = '400px';
    }, [expand]);

    const selectedToggle = (value) => {
        switch(value) {
            case 'tanker': return <Tanker tanker={selected.tanker} goToTanker={goToTanker} showTrackHistory={showTrackHistory} setExpand={setExpand} setSelected={setSelected} companies={companies}/>
            case 'company': return <Company company={selected.company} goToTanker={goToTanker}/>
            case 'insight': return <InsightContent/>
            default: return <></> 
        }
    }
  
    return (
      <div className='mapDash'>
        <div className='mapDash-map-container' id='mapDash-container'>
            <div className='mapDash-panel' ref={panel}>
                <div className='mapDash-panel-minimized'>
                    {!showTrack &&
                        <div className='mapDash-panel-tabs'>
                            <div className='mapDash-panel-tabs-single' onClick={() => {
                                setTab(0);
                                setExpand(false);
                            }} style={tab === 0 ? {backgroundColor: '#5c82ed', fontFamily: 'Avenir Next Bold', color: 'white'} : {}}>Search</div>
                            <div className='mapDash-panel-tabs-single' onClick={() => {
                                setTab(1);
                                setExpand(false);
                            }} style={tab === 1 ? {backgroundColor: '#5c82ed', fontFamily: 'Avenir Next Bold', color: 'white'} : {}}>Map</div>
                            <div className='mapDash-panel-tabs-single' onClick={() => {
                                setSelected({type: 'insight'});
                                setTab(2);
                                setExpand(false);
                            }} style={tab === 2 ? {backgroundColor: '#5c82ed', fontFamily: 'Avenir Next Bold', color: 'white'} : {}}>AI Helper</div>
                        </div>
                    }
                    {(loading && tankers.length === 0 && companies.length === 0) ?
                        <div className='map-loading-container' style={loading ? {display: 'flex'} : {display: 'none'}}>
                            <div className='map-loading-container-logo'/>
                            <Box sx={{ width: '150px' }}>
                                <LinearProgress variant="determinate" value={progress} />
                            </Box>
                        </div>
                        : showTrack ?
                            <div className='mapDash-panel-tabs-trackHistory'>
                                <div className='mapDash-panel-tabs-trackHistory-title'>Signal gaps ({gaps.length})</div>
                                <div className='mapDash-panel-tabs-trackHistory-list'>
                                    {gaps.length === 0 ?
                                        <div className='mapDash-panel-tabs-trackHistory-list-empty'>
                                            No signal gaps found
                                        </div>
                                        :
                                        gaps.map((gap, index) => (
                                            <div key={index} className='mapDash-panel-tabs-trackHistory-list-single'>
                                                <div className='mapDash-panel-tabs-trackHistory-list-single-left'>
                                                    <div className='mapDash-panel-tabs-trackHistory-list-single-left-title'>
                                                        <div className='mapDash-panel-tabs-trackHistory-list-single-left-title-name'>{Math.round(gap.gapDuration * 100) / 100} hours</div>
                                                    </div>
                                                    <div className='mapDash-panel-tabs-trackHistory-list-single-left-attributes'>
                                                        <div>{gap.startTimestamp.toString().substring(4, 24)}</div>
                                                        <div>{gap.endTimestamp.toString().substring(4, 24)}</div>
                                                    </div>
                                                </div>
                                                <div className='mapDash-panel-tabs-trackHistory-list-single-right'>
                                                    <div className='mapDash-panel-tabs-trackHistory-list-single-right-zoom' onClick={() => goToGap(gap)}/>
                                                </div>
                                            </div>
                                        ))
            
                                    }
                                </div>
                            </div>
                            :
                            panelSwitch(tab)
                    }
                </div>
                {expand &&
                    <div className='mapDash-panel-maximized'>
                        {selectedToggle(selected.type)}
                    </div>
                }
            </div>
            <div className='mapDash-map' ref={MapEl}>
                    <div className='mapDash-exitTrack' ref={exitTrack} onClick={exitTrackHistory}>Exit Track History</div>
                    <div className="lds-ripple" ref={mapLoading}><div></div><div></div></div>
                    {showTrack &&
                        <div className='mapDash-trackSlider'>
                            <Slider
                                defaultValue={2}
                                step={-1}
                                valueLabelDisplay="off"
                                valueLabelFormat={(value) => value}
                                marks={marks}
                                max={2}
                                onChange={(event, value) => {
                                    const date = new Date();
                                    date.setDate(date.getDate() - (value === 0 ? 30 : value === 1 ? 14 : 7));
                                    showTrackHistory([date.getTime(), new Date().getTime()]);
                                }}
                            />
                        </div>
                    }
            </div>
        </div>
      </div>
    );
}
 