const questions = [
    {
        question: "What are the vessels that are owned by United States companies or entities that are in the Black Sea on 05/14/2022?",
        answer: "On 05/14/2022, 1 oil tanker was flagged for being owned by a United States company and entity. The oil tanker is called Amax Arrow with a MMSI of 538006202 and is owned by American entity OAKTREE CAPITAL MANAGEMENT LP. This oil tanker has a high risk score and has logged a total of 267 AIS signal gaps over 8 hours as well as 13 flagged possible ship to ship transfers locations. This was based on data gathered by our automated analyst reports."
    },
    {
        question: "What are the vessels with the highest risk scores in the Black Sea on 05/14/2022?",
        answer: "On 05/14/2022, 153 tankers were flagged and classified as high risk tankers. These risk assessments are based on a rules engine with over 500 rules and conditions being monitored by our integrated system and analyst team."
    },
    {
        question: 'How many tankers left the Black Sea on 5/14/2022?',
        answer: 'On 5/14/2022, 237 monitored oil tankers exited the Black Sea through Port Novorossiysk and Port Constanta. These two ports have the highest oil tanker traffic frequency in the Black Sea.'
    },
    {
        question: 'How many tankers entered the Black Sea?',
        answer: 'On 5/14/2022, there are about 421 monitored oil tankers in the Black Sea. 153 of those said tankers have a high risk score while the remaining 268 oil tankers have a moderate to low risk score.'
    }
]

export default questions;