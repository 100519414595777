export const westbound = [
    {
      "mmsi": 273390540,
      "imo": 9745720,
      "timestamp": "5/15/2022 20:48",
      "vessel_name": "BAKU",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 241,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 273359180,
      "imo": 9621558,
      "timestamp": "5/8/2022 13:06",
      "vessel_name": "KARAKUZ",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 242,
      "vessel_type": "81 - Tanker, Hazardous category A",
      "vessel_type_code": 81
    },
    {
      "mmsi": 273211430,
      "imo": 9105114,
      "timestamp": "5/13/2022 0:53",
      "vessel_name": "NEATIS",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 242,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 273358180,
      "imo": 9621560,
      "timestamp": "6/7/2022 20:20",
      "vessel_name": "BAVLY",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 242,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 273342890,
      "imo": 9735323,
      "timestamp": "6/15/2022 20:36",
      "vessel_name": "YAZ",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 242,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 273452590,
      "imo": 9585912,
      "timestamp": "5/15/2022 8:01",
      "vessel_name": "MARLIN",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 273393560,
      "imo": 9838199,
      "timestamp": "5/20/2022 15:46",
      "vessel_name": "BALT FLOT 18",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 243,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 273417140,
      "imo": 9838204,
      "timestamp": "5/22/2022 23:51",
      "vessel_name": "BALT FLOT 19",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 243,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 273351180,
      "imo": 9640580,
      "timestamp": "8/25/2022 1:59",
      "vessel_name": "CONSTR.ZHIVOTOVSKY",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 243,
      "vessel_type": "84 - Tanker, Hazardous category D",
      "vessel_type_code": 84
    },
    {
      "mmsi": 273351320,
      "imo": 9598359,
      "timestamp": "5/29/2022 17:31",
      "vessel_name": "MEKHANIK ANTONOV",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 244,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 273356390,
      "imo": 9645085,
      "timestamp": "6/11/2022 17:52",
      "vessel_name": "VF TANKER-19",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 244,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 273350270,
      "imo": 9645035,
      "timestamp": "6/24/2022 1:40",
      "vessel_name": "VF TANKER-14",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 245,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 273427090,
      "imo": 9766085,
      "timestamp": "5/22/2022 7:27",
      "vessel_name": "NIKOLAY VELIKIY",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 247,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 273338880,
      "imo": 9632088,
      "timestamp": "7/10/2022 10:43",
      "vessel_name": "ISTRA",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 250,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 273376900,
      "imo": 9735139,
      "timestamp": "7/17/2022 5:53",
      "vessel_name": "MIDVOLGA 2",
      "vessel_flag": "RUSSIAN FEDERATION",
      "course_over_ground": 250,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 605076070,
      "imo": 9761267,
      "timestamp": "6/13/2022 9:20",
      "vessel_name": "OUGARTA",
      "vessel_flag": "ALGERIA",
      "course_over_ground": 243,
      "vessel_type": "84 - Tanker, Hazardous category D",
      "vessel_type_code": 84
    },
    {
      "mmsi": 605076060,
      "imo": 9761243,
      "timestamp": "8/12/2022 2:08",
      "vessel_name": "TESSALA",
      "vessel_flag": "ALGERIA",
      "course_over_ground": 245,
      "vessel_type": "84 - Tanker, Hazardous category D",
      "vessel_type_code": 84
    },
    {
      "mmsi": 423457100,
      "imo": 9813565,
      "timestamp": "8/16/2022 11:11",
      "vessel_name": "GANJA",
      "vessel_flag": "AZERBAIJAN",
      "course_over_ground": 242,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 423501100,
      "imo": 9821471,
      "timestamp": "5/11/2022 16:13",
      "vessel_name": "KALBAJAR",
      "vessel_flag": "AZERBAIJAN",
      "course_over_ground": 245,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 311000134,
      "imo": 9538177,
      "timestamp": "5/21/2022 20:58",
      "vessel_name": "ATLAS",
      "vessel_flag": "BAHAMAS",
      "course_over_ground": 240,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 311000760,
      "imo": 9774135,
      "timestamp": "6/2/2022 5:22",
      "vessel_name": "LNG SAKURA",
      "vessel_flag": "BAHAMAS",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 311073800,
      "imo": 9567702,
      "timestamp": "8/16/2022 2:19",
      "vessel_name": "EURO",
      "vessel_flag": "BAHAMAS",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 309992000,
      "imo": 9385192,
      "timestamp": "5/16/2022 1:06",
      "vessel_name": "PINNACLE SPIRIT",
      "vessel_flag": "BAHAMAS",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 311053800,
      "imo": 9461855,
      "timestamp": "7/8/2022 1:51",
      "vessel_name": "SKS DONGGANG",
      "vessel_flag": "BAHAMAS",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 311775000,
      "imo": 9275347,
      "timestamp": "7/17/2022 1:48",
      "vessel_name": "LALLA FATMA N'SOUMER",
      "vessel_flag": "BAHAMAS",
      "course_over_ground": 244,
      "vessel_type": "84 - Tanker, Hazardous category D",
      "vessel_type_code": 84
    },
    {
      "mmsi": 311000863,
      "imo": 9857858,
      "timestamp": "5/22/2022 3:16",
      "vessel_name": "MEDITERRANEANVOYAGER",
      "vessel_flag": "BAHAMAS",
      "course_over_ground": 259,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 312056000,
      "imo": 9153422,
      "timestamp": "7/16/2022 2:39",
      "vessel_name": "LESLEY CT",
      "vessel_flag": "BELIZE",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 613269306,
      "imo": 9198094,
      "timestamp": "7/28/2022 2:43",
      "vessel_name": "JOLIE",
      "vessel_flag": "CAMEROON",
      "course_over_ground": 245,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 319092900,
      "imo": 9749790,
      "timestamp": "5/10/2022 3:40",
      "vessel_name": "STAR PLOEG",
      "vessel_flag": "CAYMAN ISLANDS",
      "course_over_ground": 242,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 319130700,
      "imo": 9818216,
      "timestamp": "5/14/2022 2:35",
      "vessel_name": "NORDIC AQUARIUS",
      "vessel_flag": "CAYMAN ISLANDS",
      "course_over_ground": 246,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 518865000,
      "imo": 9640463,
      "timestamp": "5/30/2022 22:17",
      "vessel_name": "GLOSTER-1",
      "vessel_flag": "COOK ISLANDS",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 238233000,
      "imo": 9318539,
      "timestamp": "7/8/2022 2:52",
      "vessel_name": "DONAT",
      "vessel_flag": "CROATIA",
      "course_over_ground": 243,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 220479000,
      "imo": 9322695,
      "timestamp": "6/1/2022 2:52",
      "vessel_name": "BRO NYBORG",
      "vessel_flag": "DENMARK",
      "course_over_ground": 239,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 240575000,
      "imo": 9304605,
      "timestamp": "8/21/2022 3:49",
      "vessel_name": "MINERVA SYMPHONY",
      "vessel_flag": "GREECE",
      "course_over_ground": 241,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 241730000,
      "imo": 9886720,
      "timestamp": "7/6/2022 2:52",
      "vessel_name": "SEA URCHIN",
      "vessel_flag": "GREECE",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 241486000,
      "imo": 9761346,
      "timestamp": "7/8/2022 3:06",
      "vessel_name": "MARAN HERMES",
      "vessel_flag": "GREECE",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 241570000,
      "imo": 9787168,
      "timestamp": "7/22/2022 3:26",
      "vessel_name": "MINERVA ELEFTHERIA",
      "vessel_flag": "GREECE",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 240235000,
      "imo": 9276597,
      "timestamp": "7/22/2022 1:45",
      "vessel_name": "MINERVA LISA",
      "vessel_flag": "GREECE",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 241251000,
      "imo": 9667928,
      "timestamp": "8/18/2022 21:17",
      "vessel_name": "EVINOS",
      "vessel_flag": "GREECE",
      "course_over_ground": 244,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 241575000,
      "imo": 9405796,
      "timestamp": "5/28/2022 5:52",
      "vessel_name": "SEAVEN VOYAGER",
      "vessel_flag": "GREECE",
      "course_over_ground": 245,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 241716000,
      "imo": 9458494,
      "timestamp": "7/3/2022 1:45",
      "vessel_name": "ACHILLEAS",
      "vessel_flag": "GREECE",
      "course_over_ground": 247,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 240728000,
      "imo": 9318008,
      "timestamp": "6/15/2022 20:58",
      "vessel_name": "MINERVA  VASO",
      "vessel_flag": "GREECE",
      "course_over_ground": 248,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 240657000,
      "imo": 9345441,
      "timestamp": "7/26/2022 19:18",
      "vessel_name": "AEGEAN NOBILITY",
      "vessel_flag": "GREECE",
      "course_over_ground": 272,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 477118700,
      "imo": 9740469,
      "timestamp": "5/23/2022 16:39",
      "vessel_name": "UST LUGA",
      "vessel_flag": "HONG KONG",
      "course_over_ground": 227,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 477642800,
      "imo": 9263693,
      "timestamp": "6/10/2022 20:54",
      "vessel_name": "SINO STAR",
      "vessel_flag": "HONG KONG",
      "course_over_ground": 244,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 247109500,
      "imo": 9288942,
      "timestamp": "6/7/2022 0:34",
      "vessel_name": "VALLE DI NERVION",
      "vessel_flag": "ITALY",
      "course_over_ground": 242,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 247346000,
      "imo": 9148582,
      "timestamp": "7/1/2022 18:14",
      "vessel_name": "LIA IEVOLI",
      "vessel_flag": "ITALY",
      "course_over_ground": 243,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 247284100,
      "imo": 9322944,
      "timestamp": "7/30/2022 23:02",
      "vessel_name": "METEORA",
      "vessel_flag": "ITALY",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 247391300,
      "imo": 9314844,
      "timestamp": "9/1/2022 14:46",
      "vessel_name": "SAN CARLOS",
      "vessel_flag": "ITALY",
      "course_over_ground": 257,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636016504,
      "imo": 9332834,
      "timestamp": "7/12/2022 23:32",
      "vessel_name": "JUPITER SUN",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 149,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 636093084,
      "imo": 9736688,
      "timestamp": "5/12/2022 14:24",
      "vessel_name": "I.B. MAMMADZADEH",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 225,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 636014505,
      "imo": 9314882,
      "timestamp": "5/19/2022 18:24",
      "vessel_name": "AMPHITRITE",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 237,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636014309,
      "imo": 9421972,
      "timestamp": "7/14/2022 6:15",
      "vessel_name": "SCF SAMOTLOR",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 240,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636018761,
      "imo": 9788540,
      "timestamp": "5/17/2022 15:24",
      "vessel_name": "PACIFIC AZUR",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 241,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 636019936,
      "imo": 9543524,
      "timestamp": "7/17/2022 5:57",
      "vessel_name": "EUROSTRENGTH",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 241,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636019418,
      "imo": 9391361,
      "timestamp": "7/31/2022 17:04",
      "vessel_name": "CAPE GAVI",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 241,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636014350,
      "imo": 9412335,
      "timestamp": "8/17/2022 12:58",
      "vessel_name": "NS BORA",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 241,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636019763,
      "imo": 9856359,
      "timestamp": "8/31/2022 7:55",
      "vessel_name": "RESILIENT WARRIOR",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 241,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636021717,
      "imo": 9292503,
      "timestamp": "6/12/2022 12:43",
      "vessel_name": "PANTHER I",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 242,
      "vessel_type": "88 - Tanker, Reserved for future use",
      "vessel_type_code": 88
    },
    {
      "mmsi": 636016710,
      "imo": 9274317,
      "timestamp": "7/14/2022 5:22",
      "vessel_name": "STOLT VIRTUE",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 242,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636015366,
      "imo": 9274305,
      "timestamp": "8/9/2022 13:28",
      "vessel_name": "STOLT VANGUARD",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 242,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636093059,
      "imo": 9315769,
      "timestamp": "8/27/2022 23:45",
      "vessel_name": "CHEMTRANS MARS",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 242,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 636012378,
      "imo": 9284025,
      "timestamp": "5/17/2022 1:29",
      "vessel_name": "RHOURD ENOUSS",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 243,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 636015106,
      "imo": 9507740,
      "timestamp": "5/17/2022 21:53",
      "vessel_name": "GAS CERBERUS",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636013117,
      "imo": 9327451,
      "timestamp": "5/19/2022 16:19",
      "vessel_name": "SEAWAYS GALLE",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636014972,
      "imo": 9452880,
      "timestamp": "5/29/2022 6:25",
      "vessel_name": "AFRODITI",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636012855,
      "imo": 9312884,
      "timestamp": "6/2/2022 7:55",
      "vessel_name": "NS COLUMBUS",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 243,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 636013116,
      "imo": 9327463,
      "timestamp": "6/21/2022 14:35",
      "vessel_name": "RIDGEBURY GEMINI",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636021731,
      "imo": 9377779,
      "timestamp": "7/6/2022 4:21",
      "vessel_name": "MONTEREY",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 243,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 636016950,
      "imo": 9724611,
      "timestamp": "7/9/2022 0:43",
      "vessel_name": "ARCHON",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636013087,
      "imo": 9344033,
      "timestamp": "7/18/2022 0:44",
      "vessel_name": "TVERSKOY BRIDGE",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636020839,
      "imo": 9792864,
      "timestamp": "7/22/2022 2:42",
      "vessel_name": "ALKINOOS",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636091621,
      "imo": 9391397,
      "timestamp": "5/10/2022 4:34",
      "vessel_name": "CONTI GREENLAND",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636011641,
      "imo": 9256066,
      "timestamp": "5/29/2022 2:16",
      "vessel_name": "LIGOVSKY PROSPECT",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 244,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 636013380,
      "imo": 9406910,
      "timestamp": "6/15/2022 9:51",
      "vessel_name": "CONSTANTINOS",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636012377,
      "imo": 9284013,
      "timestamp": "6/15/2022 13:33",
      "vessel_name": "ALRAR",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 244,
      "vessel_type": "84 - Tanker, Hazardous category D",
      "vessel_type_code": 84
    },
    {
      "mmsi": 636019889,
      "imo": 9408475,
      "timestamp": "6/18/2022 3:27",
      "vessel_name": "DELTA OCEAN",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 244,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 636012814,
      "imo": 9311622,
      "timestamp": "7/29/2022 20:29",
      "vessel_name": "VLADIMIR TIKHONOV",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636014336,
      "imo": 9283796,
      "timestamp": "8/1/2022 23:03",
      "vessel_name": "DELPHI",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636020612,
      "imo": 9313486,
      "timestamp": "5/11/2022 12:34",
      "vessel_name": "ARK",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 246,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636018752,
      "imo": 9411991,
      "timestamp": "5/18/2022 14:03",
      "vessel_name": "INVIKEN",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 246,
      "vessel_type": "84 - Tanker, Hazardous category D",
      "vessel_type_code": 84
    },
    {
      "mmsi": 636018754,
      "imo": 9412000,
      "timestamp": "7/30/2022 19:29",
      "vessel_name": "NORVIKEN",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 247,
      "vessel_type": "84 - Tanker, Hazardous category D",
      "vessel_type_code": 84
    },
    {
      "mmsi": 636016275,
      "imo": 9343340,
      "timestamp": "8/4/2022 2:19",
      "vessel_name": "GEMINI SUN",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 247,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636021413,
      "imo": 9231183,
      "timestamp": "6/19/2022 0:35",
      "vessel_name": "YASH",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 250,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636021606,
      "imo": 9402823,
      "timestamp": "8/20/2022 7:19",
      "vessel_name": "CHEM HERO",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 250,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636022017,
      "imo": 9259915,
      "timestamp": "8/2/2022 11:32",
      "vessel_name": "JANAKI",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 264,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636020118,
      "imo": 9872688,
      "timestamp": "6/2/2022 9:57",
      "vessel_name": "BELLA CIAO",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 282,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 636021929,
      "imo": 9288708,
      "timestamp": "8/24/2022 1:15",
      "vessel_name": "DELTA VICTORY",
      "vessel_flag": "LIBERIA",
      "course_over_ground": 284,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 642122020,
      "imo": 9331153,
      "timestamp": "8/24/2022 9:06",
      "vessel_name": "ALHANI",
      "vessel_flag": "LIBYAN ARAB JAMAHIRIYA",
      "course_over_ground": 242,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 642122013,
      "imo": 9386861,
      "timestamp": "6/5/2022 20:42",
      "vessel_name": "MAETIGA",
      "vessel_flag": "LIBYAN ARAB JAMAHIRIYA",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 215919000,
      "imo": 9874507,
      "timestamp": "5/30/2022 15:33",
      "vessel_name": "EBN HAWKEL",
      "vessel_flag": "MALTA",
      "course_over_ground": 183,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 215070000,
      "imo": 9387580,
      "timestamp": "8/24/2022 19:48",
      "vessel_name": "VALLE BIANCA",
      "vessel_flag": "MALTA",
      "course_over_ground": 232,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 215311000,
      "imo": 9248796,
      "timestamp": "6/10/2022 21:08",
      "vessel_name": "SEAEXPLORER",
      "vessel_flag": "MALTA",
      "course_over_ground": 239,
      "vessel_type": "81 - Tanker, Hazardous category A",
      "vessel_type_code": 81
    },
    {
      "mmsi": 229847000,
      "imo": 9259915,
      "timestamp": "5/16/2022 8:14",
      "vessel_name": "AZAHAR",
      "vessel_flag": "MALTA",
      "course_over_ground": 241,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 229145000,
      "imo": 9343986,
      "timestamp": "7/21/2022 22:31",
      "vessel_name": "SEABREEZE",
      "vessel_flag": "MALTA",
      "course_over_ground": 241,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 215474000,
      "imo": 9378321,
      "timestamp": "8/21/2022 6:15",
      "vessel_name": "SONGA CRYSTAL",
      "vessel_flag": "MALTA",
      "course_over_ground": 241,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 249862000,
      "imo": 9776743,
      "timestamp": "8/21/2022 8:59",
      "vessel_name": "MATALA",
      "vessel_flag": "MALTA",
      "course_over_ground": 241,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 248363000,
      "imo": 9401312,
      "timestamp": "5/7/2022 14:15",
      "vessel_name": "SAMUS SWAN",
      "vessel_flag": "MALTA",
      "course_over_ground": 242,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 229319000,
      "imo": 9629550,
      "timestamp": "5/14/2022 20:19",
      "vessel_name": "SEASALVIA",
      "vessel_flag": "MALTA",
      "course_over_ground": 242,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 248167000,
      "imo": 9390862,
      "timestamp": "5/28/2022 20:24",
      "vessel_name": "ATLANTIS AUGUSTA",
      "vessel_flag": "MALTA",
      "course_over_ground": 242,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 215003000,
      "imo": 9326512,
      "timestamp": "7/7/2022 17:49",
      "vessel_name": "AKTEA",
      "vessel_flag": "MALTA",
      "course_over_ground": 242,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 249831000,
      "imo": 9803429,
      "timestamp": "7/14/2022 18:57",
      "vessel_name": "TANAB",
      "vessel_flag": "MALTA",
      "course_over_ground": 242,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 248480000,
      "imo": 9464364,
      "timestamp": "5/14/2022 0:52",
      "vessel_name": "AGIOS NIKOLAOS IV",
      "vessel_flag": "MALTA",
      "course_over_ground": 243,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 249857000,
      "imo": 9776755,
      "timestamp": "5/22/2022 2:48",
      "vessel_name": "WAIKIKI",
      "vessel_flag": "MALTA",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 215461000,
      "imo": 9411941,
      "timestamp": "5/29/2022 4:05",
      "vessel_name": "MINERVA VERA",
      "vessel_flag": "MALTA",
      "course_over_ground": 243,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 248585000,
      "imo": 9297888,
      "timestamp": "6/5/2022 3:05",
      "vessel_name": "SEADANCER",
      "vessel_flag": "MALTA",
      "course_over_ground": 243,
      "vessel_type": "84 - Tanker, Hazardous category D",
      "vessel_type_code": 84
    },
    {
      "mmsi": 215993000,
      "imo": 9344344,
      "timestamp": "6/8/2022 8:13",
      "vessel_name": "USICHEM",
      "vessel_flag": "MALTA",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 256286000,
      "imo": 9356244,
      "timestamp": "6/8/2022 20:45",
      "vessel_name": "HISTRIA IVORY",
      "vessel_flag": "MALTA",
      "course_over_ground": 243,
      "vessel_type": "81 - Tanker, Hazardous category A",
      "vessel_type_code": 81
    },
    {
      "mmsi": 215924000,
      "imo": 9292591,
      "timestamp": "6/10/2022 23:13",
      "vessel_name": "GEA",
      "vessel_flag": "MALTA",
      "course_over_ground": 243,
      "vessel_type": "85 - Tanker, Reserved for future use",
      "vessel_type_code": 85
    },
    {
      "mmsi": 256742000,
      "imo": 9732503,
      "timestamp": "6/30/2022 14:17",
      "vessel_name": "HELLAS GLADIATOR",
      "vessel_flag": "MALTA",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 215234000,
      "imo": 9551806,
      "timestamp": "7/24/2022 23:53",
      "vessel_name": "YM MARS",
      "vessel_flag": "MALTA",
      "course_over_ground": 243,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 256123000,
      "imo": 9236640,
      "timestamp": "7/29/2022 0:55",
      "vessel_name": "CROWN II",
      "vessel_flag": "MALTA",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 215345000,
      "imo": 9536935,
      "timestamp": "8/16/2022 0:50",
      "vessel_name": "MRC HATICE ANA",
      "vessel_flag": "MALTA",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 229866000,
      "imo": 9489560,
      "timestamp": "8/18/2022 5:31",
      "vessel_name": "BOMAR VESTA",
      "vessel_flag": "MALTA",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 215068000,
      "imo": 9417309,
      "timestamp": "8/20/2022 4:50",
      "vessel_name": "VALFOGLIA",
      "vessel_flag": "MALTA",
      "course_over_ground": 243,
      "vessel_type": "81 - Tanker, Hazardous category A",
      "vessel_type_code": 81
    },
    {
      "mmsi": 229631000,
      "imo": 9669665,
      "timestamp": "8/20/2022 23:00",
      "vessel_name": "CIELO DI NEW YORK",
      "vessel_flag": "MALTA",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 229922000,
      "imo": 9297204,
      "timestamp": "8/21/2022 19:53",
      "vessel_name": "DENSA DEFNE",
      "vessel_flag": "MALTA",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 249550000,
      "imo": 9395331,
      "timestamp": "8/28/2022 3:16",
      "vessel_name": "COROSSOL",
      "vessel_flag": "MALTA",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 248011000,
      "imo": 9539858,
      "timestamp": "8/28/2022 16:13",
      "vessel_name": "ATHLOS",
      "vessel_flag": "MALTA",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 256337000,
      "imo": 9248801,
      "timestamp": "6/12/2022 13:36",
      "vessel_name": "SEACROWN 1",
      "vessel_flag": "MALTA",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 248298000,
      "imo": 9315771,
      "timestamp": "6/24/2022 17:05",
      "vessel_name": "SEAVALOUR",
      "vessel_flag": "MALTA",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 249853000,
      "imo": 9777943,
      "timestamp": "6/28/2022 2:24",
      "vessel_name": "RIVERA",
      "vessel_flag": "MALTA",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 229915000,
      "imo": 9314856,
      "timestamp": "7/5/2022 19:27",
      "vessel_name": "SAN SEBASTIAN",
      "vessel_flag": "MALTA",
      "course_over_ground": 244,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 215307000,
      "imo": 9382750,
      "timestamp": "7/7/2022 2:58",
      "vessel_name": "MINERVA GLORIA",
      "vessel_flag": "MALTA",
      "course_over_ground": 244,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 249981000,
      "imo": 9759800,
      "timestamp": "7/16/2022 1:29",
      "vessel_name": "SEARANGER",
      "vessel_flag": "MALTA",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 215705000,
      "imo": 9282821,
      "timestamp": "7/17/2022 22:01",
      "vessel_name": "HOPE A",
      "vessel_flag": "MALTA",
      "course_over_ground": 244,
      "vessel_type": "84 - Tanker, Hazardous category D",
      "vessel_type_code": 84
    },
    {
      "mmsi": 256996000,
      "imo": 9383651,
      "timestamp": "8/6/2022 17:06",
      "vessel_name": "BOMAR SEDNA",
      "vessel_flag": "MALTA",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 248347000,
      "imo": 9772357,
      "timestamp": "6/5/2022 5:21",
      "vessel_name": "MARATHI",
      "vessel_flag": "MALTA",
      "course_over_ground": 245,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 215936000,
      "imo": 9895915,
      "timestamp": "6/5/2022 20:11",
      "vessel_name": "EUROSAILOR",
      "vessel_flag": "MALTA",
      "course_over_ground": 245,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 229407000,
      "imo": 9629574,
      "timestamp": "8/1/2022 16:32",
      "vessel_name": "SEAFRIEND",
      "vessel_flag": "MALTA",
      "course_over_ground": 245,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 215034000,
      "imo": 9293997,
      "timestamp": "8/8/2022 19:20",
      "vessel_name": "ASTREA",
      "vessel_flag": "MALTA",
      "course_over_ground": 245,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 256550000,
      "imo": 9693056,
      "timestamp": "5/11/2022 9:34",
      "vessel_name": "AGIOS GERASIMOS",
      "vessel_flag": "MALTA",
      "course_over_ground": 246,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 248821000,
      "imo": 9420617,
      "timestamp": "6/14/2022 12:45",
      "vessel_name": "SILVER",
      "vessel_flag": "MALTA",
      "course_over_ground": 246,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 248299000,
      "imo": 9315783,
      "timestamp": "7/7/2022 17:13",
      "vessel_name": "SEAVICTORY",
      "vessel_flag": "MALTA",
      "course_over_ground": 247,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 249957000,
      "imo": 9464118,
      "timestamp": "7/22/2022 1:57",
      "vessel_name": "YM PLUTO",
      "vessel_flag": "MALTA",
      "course_over_ground": 247,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 229599000,
      "imo": 9253246,
      "timestamp": "5/9/2022 22:41",
      "vessel_name": "ANGI",
      "vessel_flag": "MALTA",
      "course_over_ground": 248,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 249878000,
      "imo": 9728239,
      "timestamp": "5/29/2022 0:52",
      "vessel_name": "MINERVA CORALIA",
      "vessel_flag": "MALTA",
      "course_over_ground": 248,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 215513000,
      "imo": 9259927,
      "timestamp": "5/7/2022 14:48",
      "vessel_name": "FRESIA",
      "vessel_flag": "MALTA",
      "course_over_ground": 259,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538006203,
      "imo": 9419450,
      "timestamp": "6/24/2022 21:29",
      "vessel_name": "AMAX AVENUE",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 159,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538007044,
      "imo": 9794446,
      "timestamp": "6/17/2022 16:02",
      "vessel_name": "STI JARDINS",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 178,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538009935,
      "imo": 9290830,
      "timestamp": "5/19/2022 6:07",
      "vessel_name": "BLUE TRADER",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 235,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538004119,
      "imo": 9451991,
      "timestamp": "6/26/2022 1:09",
      "vessel_name": "GAS COBIA",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 239,
      "vessel_type": "84 - Tanker, Hazardous category D",
      "vessel_type_code": 84
    },
    {
      "mmsi": 538009800,
      "imo": 9388792,
      "timestamp": "5/9/2022 8:00",
      "vessel_name": "BEKS SWAN",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 240,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538006934,
      "imo": 9733375,
      "timestamp": "8/23/2022 20:47",
      "vessel_name": "TRF KRISTIANSAND",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 240,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538006775,
      "imo": 9509449,
      "timestamp": "5/14/2022 22:38",
      "vessel_name": "ELANDRA OAK",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 241,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538008322,
      "imo": 9353125,
      "timestamp": "5/19/2022 21:07",
      "vessel_name": "DONALD",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 241,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 538004215,
      "imo": 9334569,
      "timestamp": "5/24/2022 9:56",
      "vessel_name": "POLAR UNICORN",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 241,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538006204,
      "imo": 9470131,
      "timestamp": "5/31/2022 3:21",
      "vessel_name": "ADVANTAGE AWARD",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 241,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538009598,
      "imo": 9392781,
      "timestamp": "6/8/2022 17:39",
      "vessel_name": "ZEZE START",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 241,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538005777,
      "imo": 9711573,
      "timestamp": "6/14/2022 19:18",
      "vessel_name": "HAFNIA AQUAMARINE",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 241,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538005788,
      "imo": 9719757,
      "timestamp": "5/30/2022 22:03",
      "vessel_name": "HAFNIA AZOTIC",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 242,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538007108,
      "imo": 9748007,
      "timestamp": "5/31/2022 12:45",
      "vessel_name": "PALANCA CADIZ",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 242,
      "vessel_type": "84 - Tanker, Hazardous category D",
      "vessel_type_code": 84
    },
    {
      "mmsi": 538006181,
      "imo": 9746231,
      "timestamp": "6/15/2022 19:51",
      "vessel_name": "OBSIDIAN",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 242,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538005991,
      "imo": 9690626,
      "timestamp": "6/24/2022 17:55",
      "vessel_name": "HAFNIA VIOLETTE",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 242,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538003431,
      "imo": 9436666,
      "timestamp": "7/7/2022 20:27",
      "vessel_name": "HISTRIA PRINCE",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 242,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 538008919,
      "imo": 9895226,
      "timestamp": "8/4/2022 3:38",
      "vessel_name": "NISSOS ANTIMILOS",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 242,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538005244,
      "imo": 9314179,
      "timestamp": "8/20/2022 6:04",
      "vessel_name": "LORELEI",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 242,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538006612,
      "imo": 9287297,
      "timestamp": "8/26/2022 23:01",
      "vessel_name": "CHEM TIGER",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 242,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 538006271,
      "imo": 9311256,
      "timestamp": "6/3/2022 17:54",
      "vessel_name": "MONAX",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 243,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 538009151,
      "imo": 9878723,
      "timestamp": "6/24/2022 13:56",
      "vessel_name": "CELSIUS CAROLINA",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538008914,
      "imo": 9886770,
      "timestamp": "7/5/2022 1:50",
      "vessel_name": "NISSOS IOS",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538008324,
      "imo": 9347308,
      "timestamp": "7/9/2022 20:27",
      "vessel_name": "HUEY",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 243,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 538008361,
      "imo": 9635767,
      "timestamp": "7/27/2022 19:06",
      "vessel_name": "ELANDRA STAR",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538010126,
      "imo": 9259927,
      "timestamp": "8/7/2022 14:51",
      "vessel_name": "GREEN TRADER",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538009869,
      "imo": 9384992,
      "timestamp": "8/8/2022 12:05",
      "vessel_name": "SOUSTA",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538004508,
      "imo": 9446427,
      "timestamp": "5/7/2022 2:06",
      "vessel_name": "ALTAI",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538008331,
      "imo": 9411226,
      "timestamp": "5/31/2022 0:55",
      "vessel_name": "CE-BERMUDA",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538009821,
      "imo": 9779953,
      "timestamp": "6/6/2022 4:00",
      "vessel_name": "ADVANTAGE ANGEL",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538003460,
      "imo": 9420253,
      "timestamp": "7/24/2022 14:36",
      "vessel_name": "MAGIC STAR",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538006696,
      "imo": 9779795,
      "timestamp": "7/27/2022 3:44",
      "vessel_name": "IONIC ANASSA",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538009968,
      "imo": 9787924,
      "timestamp": "8/22/2022 2:06",
      "vessel_name": "NOLDE",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538003868,
      "imo": 9436020,
      "timestamp": "8/29/2022 2:11",
      "vessel_name": "CRUDE CENTURION",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538009792,
      "imo": 9334789,
      "timestamp": "5/16/2022 21:19",
      "vessel_name": "MAVROUDA",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 245,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538009200,
      "imo": 9893058,
      "timestamp": "6/5/2022 5:59",
      "vessel_name": "PACIFIC SAPPHIRE",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 245,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538002071,
      "imo": 9253234,
      "timestamp": "6/7/2022 21:36",
      "vessel_name": "BERENIKE",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 245,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 538009203,
      "imo": 9893084,
      "timestamp": "5/9/2022 6:59",
      "vessel_name": "PACIFIC GARNET",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 246,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538007337,
      "imo": 9767340,
      "timestamp": "6/27/2022 5:43",
      "vessel_name": "FRONT SIRIUS",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 246,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538001893,
      "imo": 9252943,
      "timestamp": "7/4/2022 11:10",
      "vessel_name": "ELAN VITAL",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 247,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538009991,
      "imo": 9290373,
      "timestamp": "5/21/2022 2:55",
      "vessel_name": "BESIKTAS BOSPHORUS",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 254,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 538009491,
      "imo": 9250531,
      "timestamp": "6/16/2022 10:55",
      "vessel_name": "WONDER MUSICA",
      "vessel_flag": "MARSHALL ISLANDS",
      "course_over_ground": 280,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 257910000,
      "imo": 9294288,
      "timestamp": "7/27/2022 23:17",
      "vessel_name": "RUNDEMANEN",
      "vessel_flag": "NORWAY",
      "course_over_ground": 243,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 259266000,
      "imo": 9283784,
      "timestamp": "8/31/2022 16:46",
      "vessel_name": "LEONORA VICTORY",
      "vessel_flag": "NORWAY",
      "course_over_ground": 244,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 511100708,
      "imo": 9896828,
      "timestamp": "7/21/2022 2:52",
      "vessel_name": "FORTUNA II",
      "vessel_flag": "PALAU",
      "course_over_ground": 245,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 352001467,
      "imo": 9416795,
      "timestamp": "7/13/2022 23:29",
      "vessel_name": "PVT ELENA",
      "vessel_flag": "PANAMA",
      "course_over_ground": 130,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 374912000,
      "imo": 9743760,
      "timestamp": "6/16/2022 3:37",
      "vessel_name": "ORIENTAL JASMINE",
      "vessel_flag": "PANAMA",
      "course_over_ground": 239,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 355850000,
      "imo": 9262924,
      "timestamp": "8/18/2022 6:35",
      "vessel_name": "LINDA I",
      "vessel_flag": "PANAMA",
      "course_over_ground": 240,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 374989000,
      "imo": 9171474,
      "timestamp": "5/9/2022 4:15",
      "vessel_name": "HICRI KAAN",
      "vessel_flag": "PANAMA",
      "course_over_ground": 241,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 372040000,
      "imo": 9331256,
      "timestamp": "6/24/2022 0:42",
      "vessel_name": "ELM GALAXY",
      "vessel_flag": "PANAMA",
      "course_over_ground": 241,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 357044000,
      "imo": 9271377,
      "timestamp": "7/28/2022 3:30",
      "vessel_name": "TRIDENT HOPE",
      "vessel_flag": "PANAMA",
      "course_over_ground": 241,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 374496000,
      "imo": 9296119,
      "timestamp": "5/22/2022 23:05",
      "vessel_name": "ROLLS I",
      "vessel_flag": "PANAMA",
      "course_over_ground": 242,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 370307000,
      "imo": 9308223,
      "timestamp": "6/21/2022 20:17",
      "vessel_name": "HELEN M",
      "vessel_flag": "PANAMA",
      "course_over_ground": 242,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 355461000,
      "imo": 9278698,
      "timestamp": "7/7/2022 21:41",
      "vessel_name": "CARIBBEAN STAR",
      "vessel_flag": "PANAMA",
      "course_over_ground": 243,
      "vessel_type": "83 - Tanker, Hazardous category C",
      "vessel_type_code": 83
    },
    {
      "mmsi": 373026000,
      "imo": 9800049,
      "timestamp": "7/30/2022 23:02",
      "vessel_name": "ORIENTAL COSMOS",
      "vessel_flag": "PANAMA",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 357263000,
      "imo": 9236975,
      "timestamp": "5/10/2022 0:04",
      "vessel_name": "MARINER III",
      "vessel_flag": "PANAMA",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 372530000,
      "imo": 9400382,
      "timestamp": "5/17/2022 16:46",
      "vessel_name": "SAKHARA LOTUS",
      "vessel_flag": "PANAMA",
      "course_over_ground": 244,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 370045000,
      "imo": 9743758,
      "timestamp": "5/21/2022 3:41",
      "vessel_name": "CHEMICAL CHALLENGER",
      "vessel_flag": "PANAMA",
      "course_over_ground": 244,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 371412000,
      "imo": 9529645,
      "timestamp": "5/23/2022 6:20",
      "vessel_name": "SUN 9",
      "vessel_flag": "PANAMA",
      "course_over_ground": 246,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 352525000,
      "imo": 9267974,
      "timestamp": "6/24/2022 5:37",
      "vessel_name": "GAZ FIDELITY",
      "vessel_flag": "PANAMA",
      "course_over_ground": 247,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 548911000,
      "imo": 9724051,
      "timestamp": "7/8/2022 2:02",
      "vessel_name": "HORIN TRADER",
      "vessel_flag": "PHILIPPINES",
      "course_over_ground": 241,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 255806024,
      "imo": 9803273,
      "timestamp": "6/19/2022 21:40",
      "vessel_name": "MONTE URQUIOLA",
      "vessel_flag": "PORTUGAL",
      "course_over_ground": 139,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 255806213,
      "imo": 9403475,
      "timestamp": "5/14/2022 1:39",
      "vessel_name": "NORDSYMPHONY",
      "vessel_flag": "PORTUGAL",
      "course_over_ground": 242,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 255801280,
      "imo": 9504217,
      "timestamp": "6/6/2022 10:18",
      "vessel_name": "ROLAND ESSBERGER",
      "vessel_flag": "PORTUGAL",
      "course_over_ground": 242,
      "vessel_type": "82 - Tanker, Hazardous category B",
      "vessel_type_code": 82
    },
    {
      "mmsi": 255806512,
      "imo": 9420710,
      "timestamp": "5/13/2022 22:35",
      "vessel_name": "BOCHEM OSLO",
      "vessel_flag": "PORTUGAL",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 255804750,
      "imo": 9585871,
      "timestamp": "5/18/2022 9:49",
      "vessel_name": "MONTESPERANZA",
      "vessel_flag": "PORTUGAL",
      "course_over_ground": 245,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 566813000,
      "imo": 9652753,
      "timestamp": "6/1/2022 16:17",
      "vessel_name": "KIRKBY",
      "vessel_flag": "SINGAPORE",
      "course_over_ground": 240,
      "vessel_type": "81 - Tanker, Hazardous category A",
      "vessel_type_code": 81
    },
    {
      "mmsi": 563269000,
      "imo": 9636644,
      "timestamp": "5/21/2022 22:01",
      "vessel_name": "MAERSK AEGEAN",
      "vessel_flag": "SINGAPORE",
      "course_over_ground": 242,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 565507000,
      "imo": 9743241,
      "timestamp": "5/14/2022 0:46",
      "vessel_name": "EPIC BORINQUEN",
      "vessel_flag": "SINGAPORE",
      "course_over_ground": 243,
      "vessel_type": "84 - Tanker, Hazardous category D",
      "vessel_type_code": 84
    },
    {
      "mmsi": 566255000,
      "imo": 9725603,
      "timestamp": "7/4/2022 23:19",
      "vessel_name": "HAFNIA BERING",
      "vessel_flag": "SINGAPORE",
      "course_over_ground": 243,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 563054200,
      "imo": 9776456,
      "timestamp": "7/26/2022 23:49",
      "vessel_name": "MTM KOBE",
      "vessel_flag": "SINGAPORE",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 563094100,
      "imo": 9389497,
      "timestamp": "5/21/2022 20:15",
      "vessel_name": "HANS MAERSK",
      "vessel_flag": "SINGAPORE",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 563068100,
      "imo": 9838230,
      "timestamp": "8/7/2022 11:26",
      "vessel_name": "STI LOTUS",
      "vessel_flag": "SINGAPORE",
      "course_over_ground": 245,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 565242000,
      "imo": 9341897,
      "timestamp": "5/24/2022 2:36",
      "vessel_name": "BELGRAVIA",
      "vessel_flag": "SINGAPORE",
      "course_over_ground": 246,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 563481000,
      "imo": 9340582,
      "timestamp": "5/13/2022 21:37",
      "vessel_name": "MAERSK BRIGIT",
      "vessel_flag": "SINGAPORE",
      "course_over_ground": 250,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 271048485,
      "imo": 9531765,
      "timestamp": "8/19/2022 22:08",
      "vessel_name": "VALOUR ONE",
      "vessel_flag": "TURKEY",
      "course_over_ground": 206,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271044690,
      "imo": 9788710,
      "timestamp": "7/24/2022 6:03",
      "vessel_name": "OTTOMAN SINCERITY",
      "vessel_flag": "TURKEY",
      "course_over_ground": 207,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271044408,
      "imo": 9458119,
      "timestamp": "5/8/2022 13:09",
      "vessel_name": "T.NEVBAHAR",
      "vessel_flag": "TURKEY",
      "course_over_ground": 238,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 271040038,
      "imo": 9489572,
      "timestamp": "6/9/2022 5:48",
      "vessel_name": "KEREM KA",
      "vessel_flag": "TURKEY",
      "course_over_ground": 239,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271046791,
      "imo": 9689134,
      "timestamp": "8/7/2022 13:31",
      "vessel_name": "T.FATMA",
      "vessel_flag": "TURKEY",
      "course_over_ground": 241,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271042693,
      "imo": 9499553,
      "timestamp": "5/12/2022 13:47",
      "vessel_name": "T.LEYLA",
      "vessel_flag": "TURKEY",
      "course_over_ground": 242,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271044068,
      "imo": 9679880,
      "timestamp": "5/14/2022 23:43",
      "vessel_name": "T.AYLIN",
      "vessel_flag": "TURKEY",
      "course_over_ground": 242,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271047323,
      "imo": 9286475,
      "timestamp": "6/25/2022 8:28",
      "vessel_name": "MUSTAFA KEMAL 3",
      "vessel_flag": "TURKEY",
      "course_over_ground": 242,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 271045353,
      "imo": 9804148,
      "timestamp": "7/25/2022 2:39",
      "vessel_name": "T.SEMAHAT",
      "vessel_flag": "TURKEY",
      "course_over_ground": 242,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271000716,
      "imo": 9268289,
      "timestamp": "5/7/2022 5:35",
      "vessel_name": "ALEV KAMAN",
      "vessel_flag": "TURKEY",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271044409,
      "imo": 9418810,
      "timestamp": "5/8/2022 2:29",
      "vessel_name": "T.CAROLINE",
      "vessel_flag": "TURKEY",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271043403,
      "imo": 9351165,
      "timestamp": "5/17/2022 19:47",
      "vessel_name": "ELIF TUBA",
      "vessel_flag": "TURKEY",
      "course_over_ground": 243,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 271049402,
      "imo": 9297149,
      "timestamp": "5/19/2022 22:08",
      "vessel_name": "YUSUF UZUN",
      "vessel_flag": "TURKEY",
      "course_over_ground": 243,
      "vessel_type": "81 - Tanker, Hazardous category A",
      "vessel_type_code": 81
    },
    {
      "mmsi": 271043916,
      "imo": 9679878,
      "timestamp": "5/27/2022 18:47",
      "vessel_name": "T.ESRA",
      "vessel_flag": "TURKEY",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271043022,
      "imo": 9581007,
      "timestamp": "6/28/2022 0:38",
      "vessel_name": "DUZGIT ENDEAVOUR",
      "vessel_flag": "TURKEY",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271049605,
      "imo": 9315757,
      "timestamp": "7/5/2022 21:21",
      "vessel_name": "HACI KEMAL KA",
      "vessel_flag": "TURKEY",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271044691,
      "imo": 9788708,
      "timestamp": "8/1/2022 10:58",
      "vessel_name": "OTTOMAN COURTESY",
      "vessel_flag": "TURKEY",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271002456,
      "imo": 8317992,
      "timestamp": "8/29/2022 7:58",
      "vessel_name": "NERMIN TELLI",
      "vessel_flag": "TURKEY",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271049765,
      "imo": 9187538,
      "timestamp": "7/27/2022 22:30",
      "vessel_name": "WINTER SUN",
      "vessel_flag": "TURKEY",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271049453,
      "imo": 9808742,
      "timestamp": "7/31/2022 8:07",
      "vessel_name": "ALSU",
      "vessel_flag": "TURKEY",
      "course_over_ground": 244,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271002688,
      "imo": 9499826,
      "timestamp": "5/14/2022 0:23",
      "vessel_name": "T.SEVGI",
      "vessel_flag": "TURKEY",
      "course_over_ground": 245,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 271049700,
      "imo": 9510589,
      "timestamp": "7/5/2022 4:16",
      "vessel_name": "LETA",
      "vessel_flag": "TURKEY",
      "course_over_ground": 245,
      "vessel_type": "89 - Tanker, No additional information",
      "vessel_type_code": 89
    },
    {
      "mmsi": 271047206,
      "imo": 7615749,
      "timestamp": "5/18/2022 12:12",
      "vessel_name": "HALIC",
      "vessel_flag": "TURKEY",
      "course_over_ground": 246,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271050642,
      "imo": 9803429,
      "timestamp": "8/30/2022 10:48",
      "vessel_name": "TEO ATK",
      "vessel_flag": "TURKEY",
      "course_over_ground": 249,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 271000882,
      "imo": 9235842,
      "timestamp": "5/30/2022 18:41",
      "vessel_name": "KUZGUNCUK",
      "vessel_flag": "TURKEY",
      "course_over_ground": 257,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    },
    {
      "mmsi": 577463000,
      "imo": 9558763,
      "timestamp": "6/17/2022 17:52",
      "vessel_name": "AZIZ TORLAK",
      "vessel_flag": "VANUATU",
      "course_over_ground": 243,
      "vessel_type": "80 - Tanker, all ships of this type",
      "vessel_type_code": 80
    }
  ]