import './Sidebar.scss';
import { useHistory } from 'react-router-dom';
import { AccountContext } from '../../pages/auth/Accounts';
import { useContext, useEffect, useRef, useState } from 'react';
 
export default function Sidebar(props) {
    const history = useHistory();
    const { logout, getSession } = useContext(AccountContext);
    const sidebar = useRef();
    const [expand, setExpand] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        // getSession().then((res) => console.log(res))

        switch(window.location.pathname) {
            case '/' :
                setActiveTab(0);
                break;
            case '/home':
                setActiveTab(0);
                break;
            case '/map':
                setActiveTab(1);
                break;
            case '/insight':
                setActiveTab(2);
                break;
            default: setActiveTab(-1);
        }
    }, []);

    useEffect(() => {
        if(!expand) {
            document.getElementById('expand').style.transform = 'rotate(180deg)';

            sidebar.current.style.minWidth = '70px';
            sidebar.current.style.maxWidth = '70px';

            const links = document.getElementsByClassName('sidebar-links-button');
            for(let i = 0; i < links.length; i++) {
                links[i].style.paddingLeft = '1.5rem';
            }

            const headings = document.getElementsByClassName('sidebar-links-heading');
            for(let i = 0; i < headings.length; i++) {
                headings[i].style.display = 'none';
            }

            const titles = document.getElementsByClassName('sidebar-links-title');
            for(let i = 0; i < titles.length; i++) {
                titles[i].style.display = 'none';
            }
        } else {
            document.getElementById('expand').style.transform = 'rotate(0deg)';

            sidebar.current.style.minWidth = '270px';
            sidebar.current.style.maxWidth = '270px';

            const headings = document.getElementsByClassName('sidebar-links-heading');
            for(let i = 0; i < headings.length; i++) {
                headings[i].style.display = 'flex';
            }

            setTimeout(() => {

                const links = document.getElementsByClassName('sidebar-links-button');
                for(let i = 0; i < links.length; i++) {
                    links[i].style.paddingLeft = '2rem';
                }

                const titles = document.getElementsByClassName('sidebar-links-title');
                for(let i = 0; i < titles.length; i++) {
                    titles[i].style.display = 'flex';
                }
            }, [200]);
        }
    }, [expand]);

    const active = {
        backgroundColor: '#26BC70',
        width: expand ? '102%' : '110%',
        padding: '0.5rem 0rem',
        paddingLeft: '1.5rem',
        borderTopRightRadius: '.25rem',
        borderBottomRightRadius: '.25rem',
    }
 
    return (
        <div className='sidebar' ref={sidebar}>
            <div className='sidebar-header' style={expand ? {marginBottom: 'auto'} : {marginBottom: '1.25rem', marginTop: '1rem'}}>
                <div className={expand ? 'sidebar-header-logo' : 'sidebar-header-logo-cropped'} onClick={() => history.push('/home')}/>
            </div>
            <div className='sidebar-links'>
                <div className='sidebar-links-heading'>YOUR SERVICES</div>
                <button className='sidebar-links-button' title='Dashboard' role="button" style={activeTab === 0 ? active : {}} onClick={() => {
                    setActiveTab(0);
                    history.push('/home');
                }}>
                    <div className='sidebar-links-button-icon-home' style={activeTab === 0 ? {filter: 'invert(100%)'} : {}}/>
                    <span className='sidebar-links-title' style={activeTab === 0 ? {color: 'white'} : {color: 'silver'}}>Dashboard</span>
                </button>
                <button className='sidebar-links-button' title='Situational Awareness' role="button" style={activeTab === 1 ? active : {}} onClick={() => {
                    setActiveTab(1);
                    history.push('/map');
                }}>
                    <div className='sidebar-links-button-icon-gears' style={activeTab === 1 ? {filter: 'invert(100%)'} : {}}/>
                    <span className='sidebar-links-title' style={activeTab === 1 ? {color: 'white'} : {color: 'silver'}}>Situational Awareness</span>
                </button>
                {/* <button className='sidebar-links-button' title='Case Studies' role="button" style={activeTab === 2 ? active : {}} onClick={() => {
                    setActiveTab(2);
                    history.push('/insight');
                }}>
                    <div className='sidebar-links-button-icon-insight' style={activeTab === 2 ? {filter: 'invert(100%)'} : {}}/>
                    <span className='sidebar-links-title' style={activeTab === 2 ? {color: 'white'} : {color: 'silver'}}>Case Studies</span>
                </button> */}
                {expand &&
                    <>
                        <div className='sidebar-links-additional' id='additional'>
                            <div className='sidebar-links-additional-container'>
                                <div className='sidebar-links-heading'>ADDITIONAL SERVICES</div>
                                <div className='sidebar-links-additional-container-lock'/>
                                <button className='sidebar-links-button' title='Traffic Density' role="button" style={activeTab === 4 ? active : {}} onClick={() => {

                                }}>
                                    <div className='sidebar-links-button-icon-density' style={activeTab === 4 ? {filter: 'invert(100%)'} : {}}/>
                                    <span className='sidebar-links-title' style={activeTab === 4 ? {color: 'white'} : {color: 'silver'}}>Traffic Density</span>
                                </button>
                            </div>
                        </div>
                        <div className='sidebar-request'>Request features</div>
                    </>

                }
            </div>
            <div className='sidebar-signout' title='Sign out' onClick={() => {
                    logout();
                    props.auth.setAuthState(false);
                    history.push('/');
                }}>
                <div className='sidebar-signout-icon'/>
                <span className='sidebar-links-title'>Sign out</span>
            </div>
            <div id='expand' className='sidebar-expand' title='Expand' onClick={() => setExpand(!expand)}/>    
        </div>
    );
}
 