import { useEffect, useState } from 'react';
 
export default function Search(props) {

    const [companySearch, setCompanySearch] = useState();
    const [tankerSearch, setTankerSearch] = useState();
    const [tankers, setTankers] = useState([]);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        if(typeof(tankerSearch) === 'undefined') {
            setTankers(props.tankers.filter((tanker) => tanker.attributes.vessel_name));
        } else {
            setTankers(props.tankers.filter((tanker) => tanker.attributes.vessel_name).filter((row) => 
                row.attributes.mmsi.toString().includes(tankerSearch.toLowerCase()) ||
                row.attributes.vessel_name.toString().toLowerCase().includes(tankerSearch.toLowerCase()) ||
                (row.attributes.imo ? row.attributes.imo.toString().includes(tankerSearch.toLowerCase()) : false)
            ));
        }
    }, [tankerSearch]);

    useEffect(() => {
        if(typeof(companySearch) === 'undefined') {
            setCompanies(props.companies);
        } else {
            setCompanies(props.companies.filter((row) => row.name.toLowerCase().includes(companySearch.toLowerCase())));
        }
    }, [companySearch]);
  
    return (
        <div className='mapDash-panel-tabs-search'>
            <div className='mapDash-panel-tabs-search-field-container'>
                <div className='mapDash-panel-tabs-search-field-tag'><div className='mapDash-panel-tabs-search-field-tag-icon'/> Tanker</div>
                <input className='mapDash-panel-tabs-search-field' placeholder='Enter tanker MMSI, IMO, or name' onChange={(e) => setTankerSearch(e.target.value)}/>
            </div>
            <div className='mapDash-panel-tabs-search-results'>
                <div className='mapDash-panel-tabs-search-results-title'>Tankers ({tankers ? tankers.length : props.tankers.length})</div>
                <div className='mapDash-panel-tabs-search-results-list'>
                    <div className='mapDash-panel-tabs-search-results-list'>
                        {
                            tankers.sort((x, y) => y.attributes.score - x.attributes.score).map((tanker, index) => (
                                <div key={index} className='mapDash-panel-tabs-search-results-list-single' style={props.selectedTanker && props.selectedTanker.attributes.mmsi === tanker.attributes.mmsi ? {backgroundColor: '#5c82ed'} : {}}>
                                    <div className='mapDash-panel-tabs-search-results-list-single-left'>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left-title'>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-title-name' onClick={() => {
                                                props.setExpand(true);
                                                props.setSelected({type: 'tanker', tanker: tanker});
                                            }}>{tanker.attributes.vessel_name && tanker.attributes.vessel_name.length > 15 ? `${tanker.attributes.vessel_name.substring(0, 14)}...` : tanker.attributes.vessel_name}</div>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-title-score' style={!tanker.attributes.score ? {backgroundColor: 'rgba(255, 255, 255, 0.3)', border: '1px solid white'} : tanker.attributes.score > 10 ? {backgroundColor: 'rgba(186, 0, 13, 0.3)', border: '1px solid #BA000D'} : {backgroundColor: 'rgba(236, 202, 0, 0.3)', border: '1px solid #ecca00'}}>{tanker.attributes.score ? `${Math.ceil((tanker.attributes.score/41) * 100)}%` : '?'}</div>
                                        </div>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left-attributes'>
                                            <div>MMSI: {tanker.attributes.mmsi}</div>
                                            <div>IMO: {tanker.attributes.imo ? tanker.attributes.imo : 'Unknown'}</div>
                                        </div>
                                    </div>
                                    <div className='mapDash-panel-tabs-search-results-list-single-right'>
                                        <div className='mapDash-panel-tabs-search-results-list-single-right-zoom' onClick={() => {
                                            props.goToTanker(tanker.attributes.mmsi);
                                            props.setSelected({type: 'tanker', tanker: tanker});
                                        }}/>
                                        <div className='mapDash-panel-tabs-search-results-list-single-right-view' onClick={() => {
                                            props.setExpand(true);
                                            props.setSelected({type: 'tanker', tanker: tanker});
                                        }}/>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className='mapDash-panel-tabs-search-field-container'>
                <div className='mapDash-panel-tabs-search-field-tag'><div className='mapDash-panel-tabs-search-field-tag-icon'/> Company</div>
                <input className='mapDash-panel-tabs-search-field' placeholder='Enter company name' onChange={(e) => setCompanySearch(e.target.value)}/>
            </div>
            <div className='mapDash-panel-tabs-search-results'>
                <div className='mapDash-panel-tabs-search-results-title'>Companies ({companySearch ? companies.filter((company) => !company.name.includes('Unknown')).length :  props.companies.filter((company) => !company.name.includes('Unknown')).length})</div>
                <div className='mapDash-panel-tabs-search-results-list'>
                    <div className='mapDash-panel-tabs-search-results-list'>
                        { companySearch ?
                            companies.filter((company) => !company.name.includes('Unknown')).map((company, index) => (
                                <div key={index} className='mapDash-panel-tabs-search-results-list-single' style={{padding: '0px'}}>
                                    <div className='mapDash-panel-tabs-search-results-list-single-left'>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left-title'>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-title-name' onClick={() => {
                                                props.setExpand(true);
                                                props.setSelected({type: 'company', company: company});
                                            }}>{company.name.length > 21 ? `${company.name.substring(0, 20)}...` : company.name}</div>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-title-score' style={!company.score ? {backgroundColor: 'rgba(255, 255, 255, 0.3)', border: '1px solid white'} : company.score > 100 ? {backgroundColor: 'rgba(186, 0, 13, 0.3)', border: '1px solid #BA000D'} : {backgroundColor: 'rgba(236, 202, 0, 0.3)', border: '1px solid #ecca00'}}>{company.score ? `${Math.ceil(company.score/company.tankers.length)}%` : '?'}</div>
                                        </div>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left-attributes'>
                                            <div>Tanker associations: {company.tankers.length}</div>
                                        </div>
                                    </div>
                                    <div className='mapDash-panel-tabs-search-results-list-single-right' style={{justifyContent: 'flex-end', overflow: 'hidden', paddingRight: '0.6rem'}}>
                                        <div className='mapDash-panel-tabs-search-results-list-single-right-view' onClick={() => {
                                            props.setExpand(true);
                                            props.setSelected({type: 'company', company: company});
                                        }}/>
                                    </div>
                                </div>
                            ))
                            :
                            props.companies.filter((company) => !company.name.includes('Unknown')).map((company, index) => (
                                <div key={index} className='mapDash-panel-tabs-search-results-list-single' style={{padding: '0px'}}>
                                    <div className='mapDash-panel-tabs-search-results-list-single-left'>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left-title'>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-title-name' onClick={() => {
                                                props.setExpand(true);
                                                props.setSelected({type: 'company', company: company});
                                            }}>{company.name.length > 21 ? `${company.name.substring(0, 20)}...` : company.name}</div>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-title-score' style={!company.score ? {backgroundColor: 'rgba(255, 255, 255, 0.3)', border: '1px solid white'} : company.score > 100 ? {backgroundColor: 'rgba(186, 0, 13, 0.3)', border: '1px solid #BA000D'} : {backgroundColor: 'rgba(236, 202, 0, 0.3)', border: '1px solid #ecca00'}}>{company.score ? `${Math.ceil(company.score/company.tankers.length)}%` : '?'}</div>
                                        </div>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left-attributes'>
                                            <div>Tanker associations: {company.tankers.length}</div>
                                        </div>
                                    </div>
                                    <div className='mapDash-panel-tabs-search-results-list-single-right' style={{justifyContent: 'flex-end', overflow: 'hidden', paddingRight: '0.6rem'}}>
                                        <div className='mapDash-panel-tabs-search-results-list-single-right-view' onClick={() => {
                                            props.setExpand(true);
                                            props.setSelected({type: 'company', company: company});
                                        }}/>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
 