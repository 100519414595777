import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { useEffect } from "react";
import axios from "axios";
import { Box } from "@mui/system";
import { LinearProgress } from "@mui/material";

export default function Reports({ tanker }) {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}/tankers/reports?mmsi=${tanker.attributes.mmsi}`)
        .then((response) => {
            if(typeof(response.data) !== 'string') {
                setRows(response.data.filter((report) => report.asset_name.includes('Space-Eyes')).map((report, index) => {
                    let words = report.asset_name.split(' ');
                    words.shift();
    
                    let name;
                    for(let i = 0; i < words.length; i++) {
                        if(words[i + 1] && words[i + 1].length === 9 && parseInt(words[i + 1])) {
                            name = words.splice(0, i + 1).toString().replaceAll(',', ' ');
                        }
                    }
    
                    return {
                        id: index,
                        name: name ? name : report.asset_name,
                        date: report.created_date.substring(0, 10),
                        url: report.s3_url
                    }
                }));
            } else {
                setRows([]);
            }
            setLoading(false);
        }).catch((err) => console.log(err));
    }, []);

    //Loading Bar
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 95) {
                    return 95;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 95);
            });
            }, 500);
        
            return () => {
                clearInterval(timer);
            };
    }, []);

    return (
        <div className='tanker-reports'>
            <div className='tanker-reports-header'>Reports</div>
            <div className='tanker-reports-table'>
                {loading ?
                    <div className='map-loading-container' style={loading ? {display: 'flex'} : {display: 'none'}}>
                        <div className='map-loading-container-logo'/>
                        <Box sx={{ width: '150px' }}>
                            <LinearProgress variant="determinate" value={progress} />
                        </Box>
                    </div>
                    :
                    <DataGrid
                        rows={rows}
                        rowSelection={'single'}
                        columns={[
                            { field: 'name', headerName: 'File name', flex: 1, renderCell: (params) => (
                                <div >
                                    {params.value}
                                </div>
                            )},
                            { field: 'date', headerName:  'Date Created', flex: 1, renderCell: (params) => (
                                <div >
                                    {params.value}
                                </div>
                            )},
                            {field: 'view', headerName: '', flex: 1, align: 'center', renderCell: (params) => (
                                <div className='tanker-reports-table-view' style={{backgroundColor: 'white', color: 'black', padding: '0.25rem 0.5rem', fontWeight: 'bolder'}} onClick={() => {
                                    window.open(params.row.url, '_blank');
                                }}>
                                    View
                                </div>
                            )}
                        ]}
                        rowsPerPageOptions={[10, 50, 100]}
                        sx={{
                            boxShadow: 'none',
                            fontFamily: "Avenir Next Regular",
                            border: 'none',
                            color: 'white',
                            backgroundColor: '#111723',
                            borderColor: 'white',
                            '& .MuiDataGrid-columnHeaderCheckbox' : {
                                display: 'none'
                            },
                            '& .MuiCheckbox-root' : {
                                color: 'white',
                                filter: 'invert(100%)'
                            },
                            '& .MuiDataGrid-overlay' : {
                                backgroundColor: '#111723'
                            },
                            '& .MuiDataGrid-columnHeaders' : {
                                backgroundColor: '#1f2a3f',
                                borderRadius: '0rem'
                            },
                            '& .MuiDataGrid-footerContainer' : {
                                backgroundColor: '#1f2a3f'
                            },
                            '& .MuiTablePagination-displayedRows' : {
                                color: 'white'
                            },
                            '& .MuiTablePagination-root' : {
                                color: 'white'
                            },
                            '& .MuiSvgIcon-root' : {
                                filter: 'invert(100%)'
                            },
                            '& .Mui-disabled' : {
                                filter: 'invert(100%)'
                            },
                            '& .MuiTablePagination-actions' : {
                                filter: 'invert(100%)'
                            },
                            '& .MuiDataGrid-columnSeparator': {
                                filter: 'invert(100%)'
                            },
                            '& .MuiDataGrid-row': {
                                backgroundColor: '#111723'
                            },
                            '& .MuiDataGrid-row:hover': {
                                color: 'white',
                                backgroundColor: '#27272f'
                            },
                            '& .MuiDataGrid-selectedRowCount': {
                                color: '#283040',
                                width: '50px'
                            },
                            '& .MuiTablePagination-selectLabel': {
                                paddingTop: '15px',
                            },
                            '& .MuiTablePagination-displayedRows': {
                                paddingTop: '15px',
                            }
                        }}
                    />
                }
            </div>
        </div>
    );
}
 