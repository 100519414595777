import { useEffect, useState } from 'react';
import { Checkbox } from '@mui/material';
import axios from 'axios';
 
export default function MapLayers(props) {
    const [rf, setRf] = useState([]);

    return (
        <div className='mapDash-panel-tabs-map'>
            <div className='mapDash-panel-tabs-map-title'>Map Layers</div>
            <div className='mapDash-panel-tabs-map-layers'>
                <div className='mapDash-panel-tabs-map-layers-single'>
                    <div className='mapDash-panel-tabs-map-layers-single-top'>
                        <Checkbox
                            defaultValue={true}
                            checked={props.layers[0]}
                            onChange={(event) => {
                                props.setLayers([event.target.checked, props.layers[1], props.layers[2], props.layers[3]]);
                                props.toggleTankersLayer(event.target.checked);
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                            sx={[
                                {'.MuiSvgIcon-root': {
                                    color: 'white',
                                }},
                            ]}
                        />
                        <div className='mapDash-panel-tabs-map-layers-single-symbol'/>
                        <div className='mapDash-panel-tabs-map-layers-single-text'>Tankers</div>
                    </div>
                    <div className='mapDash-panel-tabs-map-layers-single-bottom'>
                        This layer displays the most up-to-date AIS positions. Red tanker names denote a SMART score risk index higher than 25% and while orange denotes anything below. Unknown risk indexes are labeled in white.
                    </div>
                </div>
                <div className='mapDash-panel-tabs-map-layers-single'>
                    <div className='mapDash-panel-tabs-map-layers-single-top'>
                        <Checkbox
                            defaultValue={true}
                            checked={props.layers[1]}
                            onChange={(event) => {
                                props.setLayers([props.layers[0], event.target.checked, props.layers[2], props.layers[3]]);
                                props.toggleSatelliteLayer(event.target.checked);
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                            sx={[
                                {'.MuiSvgIcon-root': {
                                    color: 'white',
                                }},
                            ]}
                        />
                        <div className='mapDash-panel-tabs-map-layers-single-satellite'/>
                        <div className='mapDash-panel-tabs-map-layers-single-text'>Satellite Imagery</div>
                    </div>
                    <div className='mapDash-panel-tabs-map-layers-single-bottom'>
                        This layer overlays SAR imagery onto the map. These images are run through detection algorithms that cross analyze multiple sensor types in order to successfully identify violators. 
                    </div>
                </div>
                <div className='mapDash-panel-tabs-map-layers-single'>
                    <div className='mapDash-panel-tabs-map-layers-single-top'>
                        <Checkbox
                            defaultValue={true}
                            checked={props.layers[2]}
                            onChange={(event) => {
                                props.setLayers([props.layers[0], props.layers[1], event.target.checked, props.layers[3]]);
                                props.toggleHeatMap(event.target.checked);
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                            sx={[
                                {'.MuiSvgIcon-root': {
                                    color: 'white',
                                }},
                            ]}
                        />
                        <div className='mapDash-panel-tabs-map-layers-single-heatmap'>
                            <div className='mapDash-panel-tabs-map-layers-single-heatmap-inner'/>
                        </div>
                        <div className='mapDash-panel-tabs-map-layers-single-text'>STS Heatmap</div>
                    </div>
                    <div className='mapDash-panel-tabs-map-layers-single-bottom'>
                        This layer aggregates results from our rendezvous detection algorithms and displays them in the form of a heatmap. Purple denotes low density while yellow denotes high density.
                    </div>
                </div>
                <div className='mapDash-panel-tabs-map-layers-single'>
                    <div className='mapDash-panel-tabs-map-layers-single-top'>
                        <Checkbox
                            defaultValue={true}
                            checked={props.layers[3]}
                            onChange={(event) => {
                                props.setLayers([props.layers[0], props.layers[1], props.layers[2], event.target.checked]);
                                props.toggleFusionLayer(event.target.checked);
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                            sx={[
                                {'.MuiSvgIcon-root': {
                                    color: 'white',
                                }},
                            ]}
                        />
                        <div className='mapDash-panel-tabs-map-layers-single-rf'/>
                        <div className='mapDash-panel-tabs-map-layers-single-text'>Sensor Fusion</div>
                        <div className='mapDash-panel-tabs-map-layers-single-premium'>Premium</div>
                    </div>
                    <div className='mapDash-panel-tabs-map-layers-single-bottom'>
                        This layer displays results from our sensor fusion algorithms. Blue denotes the RF signal analyzed while red denotes the associated AIS signal.
                    </div>
                </div>
            </div>
            
        </div>
    );
}
 