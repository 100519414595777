import '../Map/Tanker.scss';
import notFound from '../../assets/vesselnotfound.png';
import { useEffect, useState } from 'react';
import General from './TankerInfo/General';
import STS from './TankerInfo/STS';
import Reports from './TankerInfo/Reports';
import Ownership from './TankerInfo/Ownership';
import axios from 'axios';
import { Box } from "@mui/system";
import { LinearProgress } from "@mui/material";
import vf20 from '../../assets/images/vf20.png';
 
export default function Tanker({ tanker, companies, setSelected }) {
    const [tab, setTab] = useState(0);
    const [image, setImage] = useState();
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);

    const tabSwitch = (value) => {
        switch(value) {
            case 0: return <General tanker={tanker}/>;
            case 1: return <Ownership tanker={tanker} companies={companies} setSelected={setSelected}/>;
            case 2: return <STS tanker={tanker}/>;
            case 3: return <Reports tanker={tanker}/>;
            default: return <></>;
        }
    }

    //Loading Bar
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 95) {
                    return 95;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 95);
            });
            }, 500);
        
            return () => {
                clearInterval(timer);
            };
    }, []);

    useEffect(() => {
        setTab(0);
        setImage();
        setProgress(0);
        setLoading(true);
        axios.get(`https://ezdpyv5mo6.execute-api.us-east-1.amazonaws.com/test/vesselImages?imo=&mmsi=${tanker.mmsi}`, {
            headers: {
                'x-api-key': "EE114mx9Hn7fIRqTAvLQtamlfI3ivsC67cJIUy4x"
            }
        }).then((response) => {
            if(response.data.features.length > 0) {
                setImage(response.data.features[response.data.features.length - 1].properties.mediumImgURL);
            }
            
            if(tanker.mmsi === 273358390) setImage(vf20);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }, [tanker]);

    return (
        <div className='tanker' style={{border: 'none', width: 'fit-content', overflow: 'none'}}>
            <div className='tanker-title'>
                {tanker.vessel_name}
                <div className='tanker-title-score' style={!tanker.score ? {backgroundColor: 'rgba(255, 255, 255, 0.3)', border: '1px solid white'} : tanker.score > 10 ? {backgroundColor: 'rgba(186, 0, 13, 0.3)', border: '1px solid #BA000D'} : {backgroundColor: 'rgba(236, 202, 0, 0.3)', border: '1px solid #ecca00'}}>{!tanker.score ? '?' : `${Math.ceil((tanker.score/41) * 100)}%`}</div>
                {tanker.asset_value &&
                    <>
                        <div className='company-title-text'>Estimated value:</div>
                        <div className='company-title-value'>{`$${tanker.asset_value} M`}</div>
                    </>
                }
            </div>
            <div className='tanker-top' style={{ backgroundColor: '#111723' }}>
                {loading ?
                    <div className='tanker-top-image-loading'>
                        <div className='map-loading-container' style={loading ? {display: 'flex'} : {display: 'none'}}>
                            <div className='map-loading-container-logo'/>
                            <Box sx={{ width: '150px' }}>
                                <LinearProgress variant="determinate" value={progress} />
                            </Box>
                        </div>
                    </div>
                    :
                    <img className='tanker-top-image' src={image ? image : notFound}/>
                }
                <div className='tanker-top-section'>
                    <div className='tanker-top-section-title'>Tanker Details</div>
                    <div className='tanker-top-section-list'>
                        <div className='tanker-top-section-list-row'>
                            <div className='tanker-top-section-list-row-field'>Name</div>
                            <div className='tanker-top-section-list-row-value'>{tanker.vessel_name}</div>
                        </div>
                    </div>
                    <div className='tanker-top-section-list'>
                        <div className='tanker-top-section-list-row'>
                            <div className='tanker-top-section-list-row-field'>MMSI</div>
                            <div className='tanker-top-section-list-row-value'>{tanker.mmsi}</div>
                        </div>
                    </div>
                    <div className='tanker-top-section-list'>
                        <div className='tanker-top-section-list-row'>
                            <div className='tanker-top-section-list-row-field'>IMO</div>
                            <div className='tanker-top-section-list-row-value'>{tanker.imo ? tanker.imo : 'Unknown'}</div>
                        </div>
                    </div>
                    <div className='tanker-top-section-list'>
                        <div className='tanker-top-section-list-row'>
                            <div className='tanker-top-section-list-row-field'>Flag</div>
                            <div className='tanker-top-section-list-row-value'>{tanker.vessel_flag}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tanker-tabs' style={{ backgroundColor: '#111723' }}>
                <div className='tanker-tabs-single' style={tab === 0 ? {backgroundColor: '#5c82ed', fontFamily: 'Avenir Next Bold', color: 'white'} : {}} onClick={() => setTab(0)}>General</div>
                <div className='tanker-tabs-single' style={tab === 1 ? {backgroundColor: '#5c82ed', fontFamily: 'Avenir Next Bold', color: 'white'} : {}} onClick={() => setTab(1)}>Ownership</div>
                <div className='tanker-tabs-single' style={tab === 2 ? {backgroundColor: '#5c82ed', fontFamily: 'Avenir Next Bold', color: 'white'} : {}} onClick={() => setTab(2)}>STS flags</div>
                <div className='tanker-tabs-single' style={tab === 3 ? {backgroundColor: '#5c82ed', fontFamily: 'Avenir Next Bold', color: 'white'} : {}} onClick={() => setTab(3)}>Reports</div>
            </div>
            {tabSwitch(tab)}
        </div>
    );
}
 