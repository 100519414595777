import { useState, useEffect } from 'react';
import './Company.scss';
import axios from 'axios';
import { Box } from "@mui/system";
import { LinearProgress } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
 
export default function Company({ company, goToTanker }) {
    const [loading, setLoading] = useState(true);
    const [companyData, setCompanyData] = useState();
    const [rows, setRows] = useState([]);
    const [progress, setProgress] = useState(0);
    const [tab, setTab] = useState(0);

    const tabSwitch = (value) => {
        switch(value) {
            case 0:
            return <>
                <div className='company-top'>
                    <div className='company-top-title'>Company Details</div>
                    <div className='company-top-list'>
                        <div className='company-top-list-row'>
                            <div className='company-top-list-row-field'>Company Name</div>
                            <div className='company-top-list-row-value'>{company.name}</div>
                        </div>
                        <div className='company-top-list-row'>
                            <div className='company-top-list-row-field'>Nationality of Control</div>
                            <div className='company-top-list-row-value'>{companyData ? companyData.nationalityofcontrol : 'Unknown'}</div>
                        </div>
                        <div className='company-top-list-row'>
                            <div className='company-top-list-row-field'>Nationality of Registration</div>
                            <div className='company-top-list-row-value'>{companyData ? companyData.nationalityofregistration : 'Unknown'}</div>
                        </div>
                        <div className='company-top-list-row'>
                            <div className='company-top-list-row-field'>Address</div>
                            <div className='company-top-list-row-value'>{companyData ? companyData.fulladdress : 'Unknown'}</div>
                        </div>
                        <div className='company-top-list-row'>
                            <div className='company-top-list-row-field'>Phone Number</div>
                            <div className='company-top-list-row-value'>{companyData ? companyData.telephone : 'Unknown'}</div>
                        </div>
                        <div className='company-top-list-row'>
                            <div className='company-top-list-row-field'>Year Founded</div>
                            <div className='company-top-list-row-value'>{companyData ? companyData.foundeddate : 'Unknown'}</div>
                        </div>
                    </div>
                </div>
                <div className='company-tankers'>
                    <div className='company-tankers-title'>Tankers associated with company</div>
                    <div className='company-tankers-table'>
                        <DataGrid
                            rows={rows}
                            rowSelection={'single'}
                            columns={[
                                {field: 'name', headerName: 'Tanker Name', flex: 1, renderCell: (params) => (
                                    <div>
                                        {params.value}
                                    </div>
                                )},
                                {field: 'mmsi', headerName: 'Tanker MMSI', align: 'center', headerAlign: 'center', flex: 1, renderCell: (params) => (
                                    <div>
                                        {params.value}
                                    </div>
                                )},
                                {field: 'relation', headerName: "Company's relation to tanker", align: 'center', headerAlign: 'center', width: 300, renderCell: (params) => {
                                    <div>
                                        {params.value}
                                    </div>                         
                                }},
                                {field: 'score', headerName: "SMART Score", align: 'center', headerAlign: 'center', flex: 1, renderCell: (params) => {
                                    if(params.value) {
                                        return <div style={params.value > 10 ? {backgroundColor: 'rgba(186, 0, 13, 0.3)', border: '1px solid #BA000D', width: '50px', textAlign: 'center', borderRadius: '1rem', fontWeight: 'bolder', padding: '0.25rem'} : {backgroundColor: 'rgba(236, 202, 0, 0.3)', width: '50px', textAlign: 'center', borderRadius: '1rem', fontWeight: 'bolder', border: '1px solid #ecca00', padding: '0.25rem'}}>
                                            {`${Math.ceil((params.value/41) * 100)}%`}
                                        </div>
                                    } else {
                                        return <div style={{width: '50px', height: '3px', backgroundColor: 'silver'}}/>
                                    }                                    
                                }},
                                {field: 'records', headerName: '', flex: 1, align: 'center', renderCell: (params) => (
                                    <div className='map-vessels-table-data' style={{backgroundColor: 'white', color: 'black'}} onClick={() => {
                                        goToTanker(params.row.mmsi);
                                    }}>
                                        View
                                    </div>
                                )}
                            ]}
                            rowsPerPageOptions={[10, 50, 100]}
                            sx={{
                                boxShadow: 'none',
                                fontFamily: "Avenir Next Regular",
                                border: 'none',
                                color: 'white',
                                backgroundColor: '#111723',
                                borderColor: 'white',
                                '& .MuiDataGrid-columnHeaderCheckbox' : {
                                    display: 'none'
                                },
                                '& .MuiCheckbox-root' : {
                                    color: 'white',
                                    filter: 'invert(100%)'
                                },
                                '& .MuiDataGrid-overlay' : {
                                    backgroundColor: '#111723'
                                },
                                '& .MuiDataGrid-columnHeaders' : {
                                    backgroundColor: '#1f2a3f',
                                    borderRadius: '0rem'
                                },
                                '& .MuiDataGrid-footerContainer' : {
                                    backgroundColor: '#1f2a3f'
                                },
                                '& .MuiTablePagination-displayedRows' : {
                                    color: 'white'
                                },
                                '& .MuiTablePagination-root' : {
                                    color: 'white'
                                },
                                '& .MuiSvgIcon-root' : {
                                    filter: 'invert(100%)'
                                },
                                '& .Mui-disabled' : {
                                    filter: 'invert(100%)'
                                },
                                '& .MuiTablePagination-actions' : {
                                    filter: 'invert(100%)'
                                },
                                '& .MuiDataGrid-columnSeparator': {
                                    filter: 'invert(100%)'
                                },
                                '& .MuiDataGrid-row': {
                                    backgroundColor: '#111723'
                                },
                                '& .MuiDataGrid-row:hover': {
                                    color: 'white',
                                    backgroundColor: '#27272f'
                                },
                                '& .MuiDataGrid-selectedRowCount': {
                                    color: '#283040',
                                    width: '50px'
                                },
                                '& .MuiTablePagination-selectLabel': {
                                    paddingTop: '15px',
                                },
                                '& .MuiTablePagination-displayedRows': {
                                    paddingTop: '15px',
                                }
                            }}
                        />
                    </div>
                </div>         
            </>
            default: return <></>;
        }
    }

    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API}/companies/attributes?company=${company.code}`)
        .then((response) => {
            if(response.data.length > 0) {
                setCompanyData(response.data[0]);
            }
            setLoading(false);

            setRows(
                company.tankers.map((tanker, index) => {
                    return {
                        id: index,
                        name: tanker.vessel_name,
                        mmsi: tanker.mmsi,
                        relation: tanker.company_association,
                        score: tanker.score,
                        other: {attributes: tanker}
                    }
                }).sort((x, y) => x.mmsi - y.mmsi)
            );
        }).catch((err) => {
            console.log(err);
        })
        setTab(0);
    }, [company]);

    //Loading Bar
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 95) {
                    return 95;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 95);
            });
            }, 500);
        
            return () => {
                clearInterval(timer);
            };
    }, []);

    return (
        <div className='company'>
            <div className='company-title'>
                {company.name}
                <div className='company-title-score' style={!company.score ? {backgroundColor: 'rgba(255, 255, 255, 0.3)', border: '1px solid white'} : company.score > 70 ? {backgroundColor: 'rgba(186, 0, 13, 0.3)', border: '1px solid #BA000D'} : {backgroundColor: 'rgba(236, 202, 0, 0.3)', border: '1px solid #ecca00'}}>{!company.score ? '?' : `${Math.ceil(company.score/company.tankers.length)}%` }</div>
            </div>
            {loading ?
                <div className='map-loading-container' style={loading ? {display: 'flex'} : {display: 'none'}}>
                    <div className='map-loading-container-logo'/>
                    <Box sx={{ width: '150px' }}>
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
                </div>
                :
                tabSwitch(tab)
            }
        </div>
    );
}
 