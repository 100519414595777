import { useState, useEffect } from "react";
import axios from "axios";
import sdn from './sdnvessels.json';
import { Box } from "@mui/system";
import { LinearProgress } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function Snapshot({ tankers, setSelected }) {
    let history = useHistory();
    const [tab, setTab] = useState(4);
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);

    const [reports, setReports] = useState([]);
    const [images, setImages] = useState([]);
    const [SDN, setSDN] = useState([]);
    const [repeatOffenders, setRepeatOffenders] = useState([]);
    const [jurisdictions, setJurisdictions] = useState([]);

    useEffect(() => {
        const currentTankers = Array.from(tankers.values());
        if(currentTankers.length > 0) {
            //Get flags involved
            const jurisdictions = new Map();
            currentTankers.map((tanker) => tanker.vessel_flag).filter((flag) => flag !== null).forEach((flag) => {
                if(!jurisdictions.has(flag))
                    jurisdictions.set(flag, {flag: flag, tankers: 1});
                else 
                    jurisdictions.set(flag, {flag: flag, tankers: jurisdictions.get(flag).tankers + 1});
            });
            setJurisdictions(Array.from(jurisdictions.values()));

            //Check SDN list
            const SDN = [];
            currentTankers.forEach((tanker) => {
                if(sdn.find((vessel) => vessel.imo === tanker.imo))
                    SDN.push(tanker);
            });
            setSDN(SDN);
        }
        
    }, [tankers]);

    //Get reports
    useEffect(() => {
        if(Array.from(tankers.values()).length > 0) {
            axios.get(`${process.env.REACT_APP_API}/tankers/reports?mmsi=analyst`)
            .then((response) => {
                setReports(response.data.map((report) => {
                    let words = report.asset_name.split('_')
                    words.shift();

                    let name;
                    for(let i = 0; i < words.length; i++) {
                        if(words[i + 1] && words[i + 1].length === 9 && parseInt(words[i + 1])) {
                            name = words.splice(0, i + 1).toString().replaceAll(',', ' ');
                        }
                    }
                    return {
                        ...report,
                        name: name,
                        active: Array.from(tankers.values()).find((tanker) => tanker.mmsi === report.mmsi) ? true : false
                    }
                }));
            }).catch((err) => console.log(err));
        }
    }, [tankers]);

    //Get Satellite Images
    useEffect(() => {
        if(Array.from(tankers.values()).length > 0) {
            axios.post(`${process.env.REACT_APP_API}/tankers/satellite-images`, {
                mmsis: Array.from(tankers.values()).map((tanker) => tanker.mmsi)
            }).then((response) => {
                if(typeof(response.data) !== 'string') {
                    const images = response.data.map((image, index) => {
                        const tanker = Array.from(tankers.values()).find((tanker) => tanker.mmsi === image.mmsi);
                        if(tanker) {
                            return {
                                key: index,
                                name: tanker.vessel_name,
                                mmsi: tanker.mmsi,
                                imo: tanker.imo,
                                score: tanker.score,
                                image: {
                                    date_created: image.created_date,
                                    url: image.s3_url
                                }
                            }
                        }
                    })
                    setImages(images.filter((image) => image !== undefined));
                }
            }).catch((err) => console.log(err));
        }
    }, [tankers])

    //Get repeat offenders
    useEffect(() => {
        if(Array.from(tankers.values()).length > 0) {
            const date = new Date();
            date.setDate(date.getDate() - 30);
            const today = new Date();
            
            axios.get(`${process.env.REACT_APP_DS}/Hosted/UC106_Track_Gap_BDS/MapServer/0/query?where=lastReceived+BETWEEN+${date.getTime()}+AND+${today.getTime()}&outFields=trackId&returnDistinctValues=true&f=pjson`)
            .then((response) => {
                console.log(response);
                const offenders = [];

                response.data.features.forEach((tanker) => {
                    if(tankers.has(parseInt(tanker.attributes.trackId)) && tanker.attributes.Count > 2)
                        offenders.push({...tankers.get(parseInt(tanker.attributes.trackId)), count: tanker.attributes.Count})
                });
                setRepeatOffenders(offenders);
                setLoading(false);
            }).catch((err) => console.log(err));
        }
    }, [tankers]);

    //Tabs
    const toggleTab = (value) => {
        switch (value) {
            case 0:
                return <div className="landing-left-snap-tab">
                    <div className="landing-left-snap-tab-title">Analyst Reports on TOI</div>
                    <div className='landing-left-snap-tankers'>{reports.length} reports</div>
                    <div className="landing-left-snap-tab-table">
                        {reports.length > 0 &&
                            reports.map((report, index) => (
                                <div key={index} className='mapDash-panel-tabs-search-results-list-single'>
                                    <div className='mapDash-panel-tabs-search-results-list-single-left'>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left-title'>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-title-name'>
                                                {report.name}
                                                <div className='mapDash-panel-tabs-search-results-list-single-left-title-name-alert'/>
                                            </div>
                                            {report.active && <div className='mapDash-panel-tabs-search-results-list-single-left-title-active'>IN BLACK SEA</div>}
                                        </div>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left-attributes'>
                                            <div>MMSI: {report.mmsi}</div>
                                        </div>
                                    </div>
                                    <div className='mapDash-panel-tabs-search-results-list-single-right'>
                                        <div className="landing-left-snap-tab-table-evidence" onClick={() => window.open(report.s3_url, '_blank')}>View</div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            case 1:
                return <div className="landing-left-snap-tab">
                    <div className="landing-left-snap-tab-title">Satellite Imagery</div>
                    <div className='landing-left-snap-tankers'>{images.length} images</div>
                    <div className="landing-left-snap-tab-table">
                        {images.length > 0 &&
                            images.map((image, index) => (
                                <>
                                    <div key={index} className='mapDash-panel-tabs-search-results-list-single'>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left'>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-title'>
                                                <div className='mapDash-panel-tabs-search-results-list-single-left-title-name' onClick={() => window.open(image.image.url, '_blank')}>
                                                    {image.name}
                                                </div>
                                            </div>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-attributes'>
                                                <div>MMSI: {image.mmsi}</div>
                                                <div>IMO: {image.imo ? image.imo : 'Unknown'}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <img className="landing-left-snap-tab-table-image" src={image.image.url} onClick={() => window.open(image.image.url, '_blank')}/>
                                </>
                            ))
                        }
                    </div>
                </div>
            case 2:
                return <div className="landing-left-snap-tab">
                    <div className="landing-left-snap-tab-title">Recommended TOI</div>
                    <div className='landing-left-snap-tankers'>{Array.from(tankers.values()).filter((tanker) => tanker.score > 24).length === 1 ? `${Array.from(tankers.values()).filter((tanker) => tanker.score > 24).length} tanker` : `${Array.from(tankers.values()).filter((tanker) => tanker.score > 24).length} tankers`}</div>
                    <div className="landing-left-snap-tab-table">
                        {Array.from(tankers.values()).length > 0 &&
                            Array.from(tankers.values()).filter((tanker) => tanker.score > 24).sort((x, y) => y.score - x.score).map((tanker, index) => (
                                <div key={index} className='mapDash-panel-tabs-search-results-list-single'>
                                    <div className='mapDash-panel-tabs-search-results-list-single-left'>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left-title'>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-title-name' onClick={() => {

                                            }}>{tanker.vessel_name.length > 15 ? `${tanker.vessel_name.substring(0, 14)}...` : tanker.vessel_name}</div>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-title-score' style={!tanker.score ? {backgroundColor: 'rgba(255, 255, 255, 0.3)', border: '1px solid white'} : tanker.score > 10 ? {backgroundColor: 'rgba(186, 0, 13, 0.3)', border: '1px solid #BA000D'} : {backgroundColor: 'rgba(236, 202, 0, 0.3)', border: '1px solid #ecca00'}}>{tanker.score ? `${Math.ceil((tanker.score/41) * 100)}%` : '?'}</div>
                                        </div>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left-attributes'>
                                            <div>MMSI: {tanker.mmsi}</div>
                                            <div>IMO: {tanker.imo ? tanker.imo : 'Unknown'}</div>
                                        </div>
                                    </div>
                                    <div className='mapDash-panel-tabs-search-results-list-single-right'>
                                        <div className="landing-left-snap-tab-table-evidence" onClick={() => setSelected({type: 'tanker', tanker: tanker})}>Evidence</div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            case 3:
                return <div className="landing-left-snap-tab">
                    <div className="landing-left-snap-tab-title">TOI on SDN List</div>
                    <div className='landing-left-snap-tankers'>{SDN.length === 1 ? `${SDN.length} tanker` : `${SDN.length} tankers`}</div>
                    <div className="landing-left-snap-tab-table">
                        {SDN.length > 0 &&
                            SDN.sort((x, y) => y.score - x.score).map((tanker, index) => (
                                <div key={index} className='mapDash-panel-tabs-search-results-list-single'>
                                    <div className='mapDash-panel-tabs-search-results-list-single-left'>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left-title'>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-title-name' onClick={() => {

                                            }}>{tanker.vessel_name.length > 15 ? `${tanker.vessel_name.substring(0, 14)}...` : tanker.vessel_name}</div>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-title-score' style={!tanker.score ? {backgroundColor: 'rgba(255, 255, 255, 0.3)', border: '1px solid white'} : tanker.score > 10 ? {backgroundColor: 'rgba(186, 0, 13, 0.3)', border: '1px solid #BA000D'} : {backgroundColor: 'rgba(236, 202, 0, 0.3)', border: '1px solid #ecca00'}}>{tanker.score ? `${Math.ceil((tanker.score/41) * 100)}%` : '?'}</div>
                                        </div>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left-attributes'>
                                            <div>MMSI: {tanker.mmsi}</div>
                                            <div>IMO: {tanker.imo ? tanker.imo : 'Unknown'}</div>
                                        </div>
                                    </div>
                                    <div className='mapDash-panel-tabs-search-results-list-single-right'>
                                        <div className="landing-left-snap-tab-table-evidence" onClick={() => setSelected({type: 'tanker', tanker: tanker})}>Evidence</div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            case 4:
                return <div className="landing-left-snap-tab">
                    <div className="landing-left-snap-tab-title">Repeat Offenders on TOI</div>
                    <div className='landing-left-snap-tankers'>{repeatOffenders.length === 1 ? `${repeatOffenders.length} tanker` : `${repeatOffenders.length} tankers`}</div>
                    <div className="landing-left-snap-tab-table">
                        {repeatOffenders.length > 0 &&
                            repeatOffenders.sort((x, y) => y.count - x.count).map((tanker, index) => (
                                <div key={index} className='mapDash-panel-tabs-search-results-list-single'>
                                    <div className='mapDash-panel-tabs-search-results-list-single-left'>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left-title'>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-title-name'>{tanker.vessel_name.length > 15 ? `${tanker.vessel_name.substring(0, 14)}...` : tanker.vessel_name}</div>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-title-count' style={!tanker.count ? {backgroundColor: 'rgba(255, 255, 255, 0.3)', border: '1px solid white'} : tanker.count > 10 ? {backgroundColor: 'rgba(186, 0, 13, 0.3)', border: '1px solid #BA000D'} : {backgroundColor: 'rgba(236, 202, 0, 0.3)', border: '1px solid #ecca00'}}>{tanker.count} gaps</div>
                                        </div>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left-attributes'>
                                            <div>MMSI: {tanker.mmsi}</div>
                                            <div>IMO: {tanker.imo ? tanker.imo : 'Unknown'}</div>
                                        </div>
                                    </div>
                                    <div className='mapDash-panel-tabs-search-results-list-single-right'>
                                        <div className="landing-left-snap-tab-table-evidence" onClick={() => setSelected({type: 'tanker', tanker: tanker})}>Evidence</div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            case 5:
                return <div className="landing-left-snap-tab">
                    <div className="landing-left-snap-tab-title">TOI Rendezvous Detected</div>
                    <div className='landing-left-snap-tankers'>0 rendezvous</div>
                    <div className="landing-left-snap-tab-table">
                        
                    </div>
                </div>
            case 6:
                return <div className="landing-left-snap-tab">
                    <div className="landing-left-snap-tab-title">Jurisdictions Involved with TOI</div>
                    <div className='landing-left-snap-tankers'>{jurisdictions.length === 1 ? `${jurisdictions.length} flags` : `${jurisdictions.length} flags`}</div>
                    <div className="landing-left-snap-tab-table">
                        {jurisdictions.length > 0 &&
                            jurisdictions.sort((x, y) => y.tankers - x.tankers).map((flag, index) => (
                                <div key={index} className='mapDash-panel-tabs-search-results-list-single'>
                                    <div className='mapDash-panel-tabs-search-results-list-single-left'>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left-title'>
                                            <div className='mapDash-panel-tabs-search-results-list-single-left-title-name'>{flag.flag}</div>
                                        </div>
                                        <div className='mapDash-panel-tabs-search-results-list-single-left-attributes'>
                                            <div>Tankers: {flag.tankers}</div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
        }
    }

    //Loading Bar
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 95) {
                    return 95;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 95);
            });
            }, 500);
        
            return () => {
                clearInterval(timer);
            };
    }, []);

    return (
        <div className='landing-left-snap'>
            {loading ?
                <div className='map-loading-container' style={loading ? {display: 'flex'} : {display: 'none'}}>
                    <div className='map-loading-container-logo'/>
                    <Box sx={{ width: '150px' }}>
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
                </div>
                :
                <div className="landing-left-body">
                    <div className='landing-left-snap-list'>
                        <div className='landing-left-snap-top'>
                            <div className='landing-left-snap-top-title'>Targets of Interest (TOI)</div>
                            <div className='landing-left-snap-tankers'>{Array.from(tankers.values()).length} tankers currently in the Black Sea</div>
                        </div>
                        <div className='landing-left-snap-list-row' style={tab === 0 ? {backgroundColor: '#0a192f', fontWeight: 'bolder', transform: 'scale(1.05)', borderWidth: '4px', borderColor: 'white'} : {}} onClick={() => setTab(0)}>
                            <div className='landing-left-snap-list-row-title'>Analyst Reports on TOI</div>
                            <div className='landing-left-snap-list-row-count'>{reports.length}</div>
                        </div>
                        <div className='landing-left-snap-list-row' style={tab === 1 ? {backgroundColor: '#0a192f', fontWeight: 'bolder', transform: 'scale(1.05)', borderWidth: '4px', borderColor: 'white'} : {}} onClick={() => setTab(1)}>
                            <div className='landing-left-snap-list-row-title'>Satellite Imagery on TOI</div>
                            <div className='landing-left-snap-list-row-count'>{images.length}</div>
                        </div>
                        <div className='landing-left-snap-list-row' style={tab === 2 ? {backgroundColor: '#0a192f', fontWeight: 'bolder', transform: 'scale(1.05)', borderWidth: '4px', borderColor: 'white'} : {}} onClick={() => setTab(2)}>
                            <div className='landing-left-snap-list-row-title'>Recommended TOI</div>
                            <div className='landing-left-snap-list-row-count'>{Array.from(tankers.values()).filter((tanker) => tanker.score > 24).length}</div>
                        </div>
                        <div className='landing-left-snap-list-row' style={tab === 3 ? {backgroundColor: '#0a192f', fontWeight: 'bolder', transform: 'scale(1.05)', borderWidth: '4px', borderColor: 'white'} : {}} onClick={() => setTab(3)}>
                            <div className='landing-left-snap-list-row-title'>TOI on SDN List</div>
                            <div className='landing-left-snap-list-row-count'>{SDN.length}</div>
                        </div>
                        <div className='landing-left-snap-list-row' style={tab === 4 ? {backgroundColor: '#0a192f', fontWeight: 'bolder', transform: 'scale(1.05)', borderWidth: '4px', borderColor: 'white'} : {}} onClick={() => setTab(4)}>
                            <div className='landing-left-snap-list-row-title'>Repeat Offenders on TOI</div>
                            <div className='landing-left-snap-list-row-count'>{repeatOffenders.length}</div>
                        </div>
                        <div className='landing-left-snap-list-row' style={tab === 5 ? {backgroundColor: '#0a192f', fontWeight: 'bolder', transform: 'scale(1.05)', borderWidth: '4px', borderColor: 'white'} : {}} onClick={() => setTab(5)}>
                            <div className='landing-left-snap-list-row-title'>TOI Rendezvous Detected</div>
                            <div className='landing-left-snap-list-row-count'>0</div>
                        </div>
                        <div className='landing-left-snap-list-row' style={tab === 6 ? {backgroundColor: '#0a192f', fontWeight: 'bolder', transform: 'scale(1.05)', borderWidth: '4px', borderColor: 'white'} : {}} onClick={() => setTab(6)}>
                            <div className='landing-left-snap-list-row-title'>Jurisdictions Involved with TOI</div>
                            <div className='landing-left-snap-list-row-count'>{jurisdictions.length}</div>
                        </div>
                    </div>
                    <div className='landing-left-tab'>
                        {toggleTab(tab)}
                    </div>
                </div>
            }
        </div>             
    );
}
 